import * as _ from 'lodash'
import { getToken } from '@project/auth'

export const authorizedFetch = async (resource, { xResourceRedirect = 'follow', ...init } = {}) => {
  const authOptions = {
    headers: { Authorization: await getToken() },
    cache: 'no-store',
  }
  const bareResponse = await fetch(resource, _.defaultsDeep(init, authOptions))

  const location = bareResponse.headers.get('X-Resource-Location')
  const followRedirect = xResourceRedirect === 'follow' && location

  return followRedirect ? fetch(location, { signal: init.signal }) : bareResponse
}
