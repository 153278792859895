import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import classnames from 'classnames'
import { LanguageSwitch } from '@project/components'
import { logout } from '@project/auth'
import { Settings } from './partials/Settings'
import styles from './PageHeader.module.scss'
import logo from './logo.png'

const BRANDING_ENABLED = process.env.REACT_APP_FEATURE_PREFOR_BRANDING === 'enabled'

export const PageHeader = ({ className, defaultPage = '/' }) => {
  const { t } = useTranslation()
  return (
    <div className={classnames(styles['page-header'], className)}>
      <div className={styles['page-header__left-section']}>
        {BRANDING_ENABLED && defaultPage && (
          <NavLink to={defaultPage} className={styles['page-header__logo']}>
            <img src={logo} alt="PreFor logo" />
          </NavLink>
        )}
      </div>
      <div className={styles['page-header__right-section']}>
        <Settings />
        <div className={styles['page-header__language-switch']}>
          <LanguageSwitch />
        </div>
        <div className={styles['page-header__logout']} onClick={() => logout()}>
          {t('page-header:logout')}
        </div>
      </div>
    </div>
  )
}
