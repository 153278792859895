import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import * as THREE from 'three'
import { usePaneUtils, useTile } from '../Pointcloud3D'
import { ControlToggle } from '../ControlToggle'

const SIZES = Object.freeze({
  SMALL: 1.0,
  MEDIUM: 1.3,
  LARGE: 1.6,
})

const MODES = Object.freeze({
  NATURAL: 'natural',
  ALTITUDE: 'altitude',
})

const getSizeName = value => {
  if (value === SIZES.SMALL) return 'pointSizeSmall'
  if (value === SIZES.MEDIUM) return 'pointSizeMedium'
  return 'pointSizeLarge'
}

export const Points = ({ pointSize = 0.0065, gpsTimeVisualization = false }) => {
  const { t } = useTranslation()
  const paneUtils = usePaneUtils()
  const tile = useTile()
  const [size, setSize] = useState(SIZES.MEDIUM)
  const [mode, setMode] = useState(MODES.ALTITUDE)

  const [points, setPoints] = useState(null)

  useEffect(() => {
    const particles = new THREE.BufferGeometry()
    const material = new THREE.PointsMaterial({ size: 0, vertexColors: true, transparent: false })
    const p = new THREE.Points(particles, material)
    p.frustumCulled = false
    setPoints(p)
  }, [])

  useEffect(() => {
    if (points && paneUtils) {
      paneUtils.add(points)
      paneUtils.render()
    }

    return () => {
      paneUtils.remove(points)
      paneUtils.render()
    }
  }, [points, paneUtils])

  useEffect(() => {
    const { normalizedPositions: positions, colors: naturalColors, altitudeColors, gpsTimeColors } = tile || {}
    // const colors = mode === MODES.NATURAL ? naturalColors : altitudeColors
    let colors
    if (gpsTimeVisualization) {
      colors = gpsTimeColors
    } else {
      colors = mode === MODES.NATURAL ? naturalColors : altitudeColors
    }

    if (points && positions && colors) {
      points.geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
      points.geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))
      paneUtils.render()
    }
  }, [points, tile, mode, paneUtils, gpsTimeVisualization])

  useEffect(() => {
    if (points) {
      points.material.size = pointSize * size
      paneUtils.render()
    }
  }, [points, pointSize, size, paneUtils])

  return ReactDOM.createPortal(
    <>
      <ControlToggle
        iconClassName="fa fa-exchange-alt"
        label={t(`pointcloud3D:controls.${getSizeName(size)}`)}
        onClick={() => {
          if (size === SIZES.SMALL) setSize(SIZES.MEDIUM)
          if (size === SIZES.MEDIUM) setSize(SIZES.LARGE)
          if (size === SIZES.LARGE) setSize(SIZES.SMALL)
        }}
      />
      {!gpsTimeVisualization && (
        <ControlToggle
          iconClassName="fa fa-exchange-alt"
          label={t(`pointcloud3D:controls.${mode === MODES.NATURAL ? 'naturalColorScheme' : 'altitudeColorScheme'}`)}
          onClick={() => {
            if (mode === MODES.NATURAL) setMode(MODES.ALTITUDE)
            if (mode === MODES.ALTITUDE) setMode(MODES.NATURAL)
          }}
        />
      )}
    </>,
    paneUtils.getBottomControlsContainer(),
  )
}
