import React from 'react'
import style from './ScanDashboard.module.scss'
import { PipelineWidget } from './partials/PipelineWidget'

export const ScanDashboard = ({ scan }) => {
  if (!scan?.pipelines) return null

  if (scan.pipelines.length === 0) return 'Nothing to display'

  const { scanId, label, pipelines } = scan

  return (
    <div className={style['container']}>
      {label && <div className={style['title']}>{label}</div>}
      {!label && scanId && <div className={style['title']}>Scan {scanId}</div>}
      <div className={style['content']}>
        {pipelines.map((pipeline, index) => (
          <PipelineWidget key={index} pipeline={pipeline} />
        ))}
      </div>
    </div>
  )
}
