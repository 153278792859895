import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from '@project/routing'
import { Checkbox, TextInput } from '@project/components'
import style from './Filters.module.scss'

export const Filters = ({
  propertyFilterValue,
  setPropertyFilterValue,
  customerFilterValue,
  setCustomerFilterValue,
  fitBounds,
  setFitBounds,
}) => {
  const { t } = useTranslation()
  const [queryParams, setQueryParams] = useQueryParams()

  useEffect(() => {
    const qp = {
      ...(propertyFilterValue ? { property: propertyFilterValue } : {}),
      ...(customerFilterValue ? { customer: customerFilterValue } : {}),
    }
    setQueryParams(qp, { replace: true })
  }, [propertyFilterValue, customerFilterValue, setQueryParams])

  useEffect(() => {
    const { property, customer } = queryParams
    setPropertyFilterValue?.(property || '')
    setCustomerFilterValue?.(customer || '')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={style['container']}>
      <div className={style['row']}>
        <TextInput
          className={style['text-input']}
          label={t('common.property')}
          value={propertyFilterValue}
          onChange={e => setPropertyFilterValue(e.target.value)}
        />
        {setCustomerFilterValue && (
          <TextInput
            className={style['text-input']}
            label={t('common.customer')}
            value={customerFilterValue}
            onChange={e => setCustomerFilterValue(e.target.value)}
          />
        )}
      </div>
      <Checkbox label={t('filter.checkbox.fitBounds')} checked={fitBounds} onChange={() => setFitBounds(!fitBounds)} />
    </div>
  )
}
