import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import './AuthError.scss'

const getErrorMessage = (message, t) => {
  if (!message) return ''

  switch (message) {
    case 'Incorrect username or password.':
      return t('auth:error.incorrectUsernameOrPassword')
    case 'User does not exist.':
    case 'Username/client id combination not found.':
      return t('auth:error.userDoesNotExist')
    case 'An account with the given email already exists.':
      return t('auth:error.userAlreadyExists')
    case 'Invalid verification code provided, please try again.':
      return t('auth:error.invalidVerificationCode')
    case 'Temporary password has expired and must be reset by an administrator.':
      return t('auth:error.temporaryPasswordHasExpired')
    case 'User password cannot be reset in the current state.':
      return t('auth:error.passwordCannotBeReset')
    default:
      return message
  }
}

export const AuthError = () => {
  const { t } = useTranslation()
  const error = useSelector(state => state.auth.error)
  const { message } = error || {}
  return (
    <div className={classnames('auth-error', { 'auth-error--active': message })}>{getErrorMessage(message, t)}</div>
  )
}
