import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAsyncFn } from 'react-use'
import { Form, Field } from 'react-final-form'
import moment from 'moment'
import {
  Dialog,
  DialogClose,
  DialogHeader,
  DialogContent,
  DialogButtons,
  Button,
  Select,
  FormControl,
  FormControlMessage,
  TextInput,
  DatePicker,
} from '@project/components'
import { requiredValidator } from '@project/validation'
import { createProject } from 'api/requests'
import { useParcels, useOrders, useProjects } from 'api'

export const CreateProjectPopup = ({ onClose }) => {
  const { t } = useTranslation()
  const refreshProjects = useProjects()[4]
  const [parcels] = useParcels()
  const [orders] = useOrders()

  const [activePropertyId, setActivePropertyId] = useState(null)

  const propertyIds = useMemo(() => {
    if (!parcels) return []
    return [...new Set(parcels?.map(p => p.propertyId))]
  }, [parcels])

  const parcelNumbers = useMemo(() => {
    if (!parcels) return []
    return parcels.filter(p => p.propertyId === activePropertyId).map(p => p.parcelNumber)
  }, [parcels, activePropertyId])

  const [{ loading }, onSubmit] = useAsyncFn(
    async fields => {
      const { propertyId, parcelNumber, orderNumber, firstScanDate, crs } = fields
      try {
        await createProject(propertyId, parcelNumber, orderNumber, firstScanDate, crs)
        await new Promise(resolve => setTimeout(resolve, 2000))
        await refreshProjects(true, true)
        onClose()
      } catch (error) {
        // do nothing
      }
    },
    [refreshProjects],
  )

  return (
    <Dialog loading={!parcels || !orders}>
      <DialogClose onClose={onClose} />
      <DialogHeader>{t('dialog.title.createProject')}</DialogHeader>
      <DialogContent>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormControl>
                <Field
                  name="propertyId"
                  validate={requiredValidator}
                  render={({ input: { onChange, value } }) => (
                    <Select
                      label={t('common.propertyId')}
                      value={value}
                      options={propertyIds.map(v => ({ label: v, value: v }))}
                      onChange={optionValue => {
                        setActivePropertyId(optionValue)
                        onChange(optionValue)
                      }}
                      filterable
                    />
                  )}
                />
              </FormControl>
              <FormControl>
                <Field
                  key={activePropertyId}
                  name="parcelNumber"
                  initialValue={null}
                  validate={requiredValidator}
                  render={({ input: { onChange, value } }) => (
                    <Select
                      label={t('common.parcelNumber')}
                      value={value}
                      options={parcelNumbers.map(v => ({ label: v, value: v }))}
                      onChange={onChange}
                      disabled={!activePropertyId}
                    />
                  )}
                />
              </FormControl>
              <FormControl>
                <Field
                  name="orderNumber"
                  validate={requiredValidator}
                  render={({ input: { onChange, value } }) => (
                    <Select
                      label={t('common.orderNumber')}
                      value={value}
                      options={
                        orders
                          ? orders.map(o => ({ label: `${o.orderNumber} - ${o.description}`, value: o.orderNumber }))
                          : []
                      }
                      onChange={onChange}
                    />
                  )}
                />
              </FormControl>
              <FormControl>
                <Field
                  name="firstScanDate"
                  validate={requiredValidator}
                  render={({ input, meta }) => (
                    <>
                      <DatePicker
                        selected={input.value ? moment(input.value, 'YYYY-MM-DD').toDate() : null}
                        onChange={d => input.onChange(d ? moment(d).format('YYYY-MM-DD') : '')}
                        dateFormat="yyyy-MM-dd"
                        customInput={<TextInput label={t('common.firstScanDate')} {...input} />}
                        dropdownMode="select"
                        showMonthDropdown
                        showYearDropdown
                      />
                      {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                    </>
                  )}
                />
              </FormControl>
              <FormControl>
                <Field
                  name="crs"
                  validate={requiredValidator}
                  render={({ input, meta }) => (
                    <>
                      <TextInput label={t('common.crs')} {...input} />
                      {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                    </>
                  )}
                />
              </FormControl>
              <DialogButtons>
                <Button onClick={onClose} disabled={loading} transparent>
                  {t('common.cancel')}
                </Button>
                <Button type="submit" disabled={loading}>
                  {t('common.ok')}
                </Button>
              </DialogButtons>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  )
}
