import React, { createContext, useContext, useState, useCallback } from 'react'
import { LayersControl } from './partials/LayersControl'

const NodeContext = createContext(null)

export const useLayersControlNode = () => useContext(NodeContext)

export const LayersControlNodeProvider = ({ children }) => {
  const [node, setNode] = useState(null)

  const refCallback = useCallback(n => {
    if (n) setNode(n)
  }, [])

  return (
    <>
      <LayersControl ref={refCallback} />
      <NodeContext.Provider value={node}>{children}</NodeContext.Provider>
    </>
  )
}
