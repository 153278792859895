import React from 'react'
import ReactDOM from 'react-dom/client'
import ReactModal from 'react-modal'
import i18next from 'i18next'
import App from './App'
import './i18n'

document.title = i18next.t('page.title')
i18next.on('languageChanged', () => (document.title = i18next.t('page.title')))

ReactModal.setAppElement(document.getElementById('root'))
ReactDOM.createRoot(document.getElementById('root')).render(<App />)
