import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import classnames from 'classnames'
import { useMap } from '../../../MapInstanceProvider'
import style from './LayersControl.module.scss'

export const LayersControl = React.forwardRef((props, ref) => {
  const map = useMap()
  const [active, setActive] = useState(false)

  if (!map) return null

  return ReactDOM.createPortal(
    <div className={style['container']}>
      <div ref={ref} className={classnames(style['content'], { [style['content--active']]: active })} />
      <div className={style['trigger']} onClick={() => setActive(!active)}>
        <i className={`fa ${active ? 'fa-times' : 'fa-layer-group'}`} />
      </div>
    </div>,
    map.getContainer(),
  )
})
