export const DRAINAGE_STATE = Object.freeze({
  NOT_DRAINED_MINERAL_LAND: 7001,
  PALUDIFIED_MINERAL_LAND: 7002,
  DRAINED_MINERAL_LAND: 7003,
  PEATLAND: 7006,
  DITCHED_PEATLAND: 7007,
  CHANGED_PEATLAND: 7008,
  DRAINED_PEATLAND: 7009,
})

export const drainageStateColorSchemeIds = {
  [DRAINAGE_STATE.NOT_DRAINED_MINERAL_LAND]: 'legend-ont-1',
  [DRAINAGE_STATE.PALUDIFIED_MINERAL_LAND]: 'legend-ont-2',
  [DRAINAGE_STATE.DRAINED_MINERAL_LAND]: 'legend-ont-3',
  [DRAINAGE_STATE.PEATLAND]: 'legend-ont-4',
  [DRAINAGE_STATE.DITCHED_PEATLAND]: 'legend-ont-5',
  [DRAINAGE_STATE.CHANGED_PEATLAND]: 'legend-ont-6',
  [DRAINAGE_STATE.DRAINED_PEATLAND]: 'legend-ont-7',
}
