import React, { useState, useCallback } from 'react'
import { useUnmount } from 'react-use'
import mapboxgl from 'mapbox-gl'
import { tokenHelper } from '@project/auth'
import { MapInstanceProvider } from './partials/MapInstanceProvider'
import { LayerOrderRegulatorProvider } from './partials/LayerOrderRegulatorProvider'
import { LayersControlNodeProvider } from './partials/LayersControlNodeProvider'
import { SpinnerOverlayNodeProvider } from './partials/SpinnerOverlayNodeProvider'
import 'mapbox-gl/dist/mapbox-gl.css'
import style from './MapGL.module.scss'

const API = process.env.REACT_APP_FORESTRY_API

export const MapGL = ({ bounds, dragRotate = true, children }) => {
  const [map, setMap] = useState(null)

  const containerRef = useCallback(
    node => {
      if (node && !map) {
        const instance = new mapboxgl.Map({
          container: node,
          style: {
            version: 8,
            sources: {},
            layers: [],
          },
          transformRequest: (url, resourceType) => {
            if (resourceType === 'Tile' && url.startsWith(API)) {
              return { url, headers: { Authorization: tokenHelper.getToken() } }
            }
            return { url }
          },
          bounds: bounds || [24.0, 60.0, 26.0, 61.0],
          fitBoundsOptions: { padding: 15 },
          preserveDrawingBuffer: true,
          maxPitch: 0,
          dragRotate,
        })

        instance.on('load', () => setMap(instance))
      }
    },
    [map, bounds, dragRotate],
  )

  useUnmount(() => {
    // Clean up and release all internal resources associated with this map
    setTimeout(() => map?.remove(), 2000)
  })

  return (
    <div ref={containerRef} className={style['map-gl-container']}>
      {map && (
        <MapInstanceProvider map={map}>
          <LayerOrderRegulatorProvider map={map}>
            <LayersControlNodeProvider>
              <SpinnerOverlayNodeProvider>{children}</SpinnerOverlayNodeProvider>
            </LayersControlNodeProvider>
          </LayerOrderRegulatorProvider>
        </MapInstanceProvider>
      )}
    </div>
  )
}
