import React from 'react'
import { Tooltip } from '@project/components'
import style from './UserProperties.module.scss'

export const UserProperties = ({ propertyIds }) => {
  if (!propertyIds?.length) return '—'
  if (propertyIds.length < 3) return propertyIds.map(id => <div key={id}>{id}</div>)
  return (
    <Tooltip
      trigger={<div className={style['trigger']}>{`${propertyIds.length} properties`}</div>}
      content={propertyIds.map(id => (
        <div key={id}>{id}</div>
      ))}
    />
  )
}
