import React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { MobileHorizontalScroll } from '@project/components'
import { UsersTable } from './partials/UsersTable'
import { EditPopup } from './partials/EditPopup'

export const UsersPage = () => {
  const navigate = useNavigate()
  return (
    <>
      <MobileHorizontalScroll minWidth={900} maxHeight="80vh">
        <UsersTable />
      </MobileHorizontalScroll>
      <Routes>
        <Route path="edit/:email/*" element={<EditPopup onClose={() => navigate('.')} />} />
      </Routes>
    </>
  )
}
