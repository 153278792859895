import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMap } from '@project/mapbox'
import { setAutoHeight } from '../../../../../../redux/ducks/tree'

export const HeightAutoComplete = () => {
  const map = useMap()
  const dispatch = useDispatch()
  const treePointActive = useSelector(state => state.editor.treePointActive)
  const treeIsAdded = useSelector(state => !!state.tree.coordinate)

  useEffect(() => {
    const onMouseUp = event => {
      const canvas = map.getCanvas()
      const gl = canvas.getContext('webgl')
      if (gl) {
        const data = new Uint8Array(4)
        const canvasWidth = canvas.offsetWidth
        const canvasHeight = canvas.offsetHeight
        const bufferWidth = gl.drawingBufferWidth
        const bufferHeight = gl.drawingBufferHeight

        const canvasX = event.point.x
        const canvasY = event.point.y

        const bufferX = (canvasX * bufferWidth) / canvasWidth
        const bufferY = bufferHeight - (canvasY * bufferHeight) / canvasHeight

        gl.readPixels(bufferX, bufferY, 1, 1, gl.RGBA, gl.UNSIGNED_BYTE, data)
        const [r, , b, a] = data
        const height = (40 * (a / 255 - 0.31)) / 0.6
        if (r === 0 && b === 23 && height > 0) dispatch(setAutoHeight(height))
        else dispatch(setAutoHeight(0))
      }
    }

    if (!treeIsAdded || treePointActive) map.on('mouseup', onMouseUp)

    return () => {
      map.off('mouseup', onMouseUp)
    }
  }, [treeIsAdded, treePointActive, map, dispatch])

  return null
}
