import React, { useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { Content } from './partials/Content'

export const Dropdown = ({
  renderTrigger, // opened => {...}
  renderContent, // (triggerContainer, onClose) => {...}
  xAlignment = 'auto', // 'left', 'right', 'center' or 'auto'
  yAlignment = 'auto', // 'top', 'bottom' or 'auto'
  openOnClick = true,
  closeOnClick = true,
  openOnMouseEnter = false,
  closeOnMouseLeave = false,
}) => {
  const triggerContainerRef = useRef()
  const [opened, setOpened] = useState(false)

  return (
    <>
      <span
        ref={triggerContainerRef}
        onClick={() => openOnClick && setOpened(true)}
        onMouseEnter={() => openOnMouseEnter && setOpened(true)}
      >
        {renderTrigger(opened, setOpened)}
      </span>
      {opened &&
        ReactDOM.createPortal(
          <Content
            renderContent={renderContent}
            triggerContainer={triggerContainerRef.current}
            xAlignment={xAlignment}
            yAlignment={yAlignment}
            closeOnClick={closeOnClick}
            closeOnMouseLeave={closeOnMouseLeave}
            onClose={() => setOpened(false)}
          />,
          document.body,
        )}
    </>
  )
}
