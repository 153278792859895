import React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { MobileHorizontalScroll } from '@project/components'
import { ButtonBar } from 'components/common/ButtonBar'
import { CreateButton } from 'components/common/CreateButton'
import { OrdersTable } from './partials/OrdersTable'
import { CreateOrderPopup } from './partials/CreateOrderPopup'
import { EditOrderPopup } from './partials/EditOrderPopup'
import { EditProjectPopup } from '../../common/EditProjectPopup'

export const OrdersPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <>
      <ButtonBar>
        <CreateButton label={t('dialog.trigger.createOrder')} onClick={() => navigate('create-order')} />
      </ButtonBar>
      <MobileHorizontalScroll minWidth={900} maxHeight="80vh">
        <OrdersTable />
      </MobileHorizontalScroll>
      <Routes>
        <Route path="create-order" element={<CreateOrderPopup onClose={() => navigate('.')} />} />
        <Route path="edit-order/:orderId" element={<EditOrderPopup onClose={() => navigate('.')} />} />
        <Route path="edit-project/:projectId" element={<EditProjectPopup onClose={() => navigate('.')} />} />
      </Routes>
    </>
  )
}
