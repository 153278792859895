import React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { MobileHorizontalScroll } from '@project/components'
import { ButtonBar } from 'components/common/ButtonBar'
import { CreateButton } from 'components/common/CreateButton'
import { CustomersTable } from './partials/CustomersTable'
import { CreateCustomerPopup } from './partials/CreateCustomerPopup'
import { EditCustomerPopup } from './partials/EditCustomerPopup'

export const CustomersPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <>
      <ButtonBar>
        <CreateButton label={t('dialog.trigger.createCustomer')} onClick={() => navigate('create-customer')} />
      </ButtonBar>
      <MobileHorizontalScroll minWidth={600} maxHeight="80vh">
        <CustomersTable />
      </MobileHorizontalScroll>
      <Routes>
        <Route path="create-customer" element={<CreateCustomerPopup onClose={() => navigate('.')} />} />
        <Route path="edit-customer/:customerId" element={<EditCustomerPopup onClose={() => navigate('.')} />} />
      </Routes>
    </>
  )
}
