import React, { useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import {
  Button,
  DialogContent,
  Spinner,
  TextInput,
  HtmlTable,
  Tooltip,
  Checkbox,
  DialogButtons,
  CopyToClipboard,
} from '@project/components'
import { usePlannerUsers } from 'api'
import { grantPlanAccess, revokePlanAccess } from 'api/requests'
import style from './EditPlans.module.scss'

const getColumns = (t, checkedPlanIds, onTogglePlanId) => [
  {
    Header: '',
    Cell: ({ original }) => (
      <Checkbox checked={checkedPlanIds.includes(original.planId)} onChange={() => onTogglePlanId(original.planId)} />
    ),
  },
  {
    Header: t('common.name'),
    Cell: ({ original }) => (
      <Tooltip trigger={<div className={style['name']}>{original.name}</div>} content={original.name} />
    ),
  },
  {
    Header: t('common.properties'),
    Cell: ({ original }) => original.propertyIds.map(id => <div key={id}>{id}</div>),
  },
  {
    Header: t('common.planId'),
    Cell: ({ original }) => <CopyToClipboard textToCopy={original.planId}>{original.planId}</CopyToClipboard>,
  },
]

export const EditPlans = () => {
  const { t } = useTranslation()
  const { email } = useParams()
  const [users, loadingUsers, , , refresh] = usePlannerUsers()
  const [planIdToAdd, setPlanIdToAdd] = useState('')
  const [checkedPlanIds, setCheckedPlanIds] = useState([])

  const onTogglePlanId = useCallback(
    planId => {
      const filtered = checkedPlanIds.filter(id => id !== planId)
      if (filtered.length === checkedPlanIds.length) setCheckedPlanIds([...filtered, planId])
      else setCheckedPlanIds(filtered)
    },
    [checkedPlanIds],
  )

  const user = users?.find(u => u.email === email)

  const [{ loading: adding }, onAdd] = useAsyncFn(
    async planId => {
      if (!planId) return
      try {
        await grantPlanAccess(email, [planId])
        await new Promise(resolve => setTimeout(resolve, 2000))
        await refresh(true)
        setPlanIdToAdd('')
      } catch (error) {
        // do nothing
      }
    },
    [email],
  )

  const [{ loading: removing }, onRemove] = useAsyncFn(async () => {
    try {
      await revokePlanAccess(email, checkedPlanIds)
      await new Promise(resolve => setTimeout(resolve, 2000))
      await refresh(true)
      setCheckedPlanIds([])
    } catch (error) {
      // do nothing
    }
  }, [email, checkedPlanIds])

  const data = useMemo(() => {
    if (!user) return null

    return user.plans.map(({ planId, name, compartmentVersions }) => ({
      planId,
      name,
      propertyIds: [...new Set(compartmentVersions.map(cv => cv.propertyId))],
    }))
  }, [user])

  return (
    <div>
      <DialogContent>
        <div className={style['add-section']}>
          <TextInput label={t('common.planId')} value={planIdToAdd} onChange={e => setPlanIdToAdd(e.target.value)} />
          <Button onClick={() => onAdd(planIdToAdd)}>Add</Button>
        </div>
        <div className={style['table']}>
          <HtmlTable data={data} columns={getColumns(t, checkedPlanIds, onTogglePlanId)} />
        </div>
      </DialogContent>
      {checkedPlanIds.length > 0 && (
        <DialogButtons>
          <Button className={style['remove']} onClick={onRemove}>
            {t('common.remove')}
          </Button>
        </DialogButtons>
      )}
      {(adding || removing || loadingUsers) && <Spinner />}
    </div>
  )
}
