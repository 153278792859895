import React from 'react'
import classnames from 'classnames'
import style from './TextInput.module.scss'

export const TextInput = React.forwardRef(({ className, label, icon, placeholder = '', ...rest }, ref) => (
  <div className={classnames(style['container'], className)}>
    {icon && !label && <div className={style['icon']}>{icon}</div>}
    <input
      ref={ref}
      className={classnames({
        [style['input']]: true,
        [style['with-label']]: label,
      })}
      placeholder={placeholder}
      {...rest}
    />
    {label && <div className={style['label']}>{label}</div>}
  </div>
))
