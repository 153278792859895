/* eslint-disable no-underscore-dangle */

const vertexSource = `
    attribute vec2 aPos;
    uniform mat4 uMatrix;
    varying vec2 vTexCoord;
    float Extent = 8192.0;
    void main() {
        vec4 a = uMatrix * vec4(aPos * Extent, 0, 1);
        gl_Position = vec4(a.rgba);
        vTexCoord = aPos;
    }
`

const fragmentSource = `
    precision mediump float;
    varying vec2 vTexCoord;
    uniform sampler2D uTexture;
    void main() {
        vec4 color = texture2D(uTexture, vTexCoord);
        float level = color.r;
        float alpha = 0.0;
        if (level > 0.08) { alpha = 0.25 + 0.60 * (level + 0.1); }
        gl_FragColor = vec4(0, 0.27 - level * level * 0.27, 0.09, alpha);
    }
`

export class TextureLayer {
  constructor(id, sourceId) {
    this.map = null
    this.gl = null
    this.id = id
    this.sourceId = sourceId
    this.type = 'custom'
    this.program = null
  }

  onAdd(map, gl) {
    this.map = map
    this.gl = gl
    map.on('move', this.move.bind(this))

    const rasterSource = this.map.getSource(this.sourceId)
    rasterSource.on('data', this.onData.bind(this))
    this.sourceCache = this.map.style.sourceCaches[this.sourceId]

    // !IMPORTANT! hack to make mapbox mark the sourceCache as 'used' so it will initialise tiles.
    this.map.style._layers[this.id].source = this.sourceId

    const vertexShader = gl.createShader(gl.VERTEX_SHADER)
    gl.shaderSource(vertexShader, vertexSource)
    gl.compileShader(vertexShader)

    const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER)
    gl.shaderSource(fragmentShader, fragmentSource)
    gl.compileShader(fragmentShader)

    const program = gl.createProgram()
    this.program = program
    gl.attachShader(program, vertexShader)
    gl.attachShader(program, fragmentShader)
    gl.linkProgram(program)
    gl.validateProgram(program)

    program.aPos = gl.getAttribLocation(program, 'aPos')
    program.uMatrix = gl.getUniformLocation(program, 'uMatrix')
    program.uTexture = gl.getUniformLocation(program, 'uTexture')

    const vertexArray = new Float32Array([0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1])

    program.vertexBuffer = gl.createBuffer()
    gl.bindBuffer(gl.ARRAY_BUFFER, program.vertexBuffer)
    gl.bufferData(gl.ARRAY_BUFFER, vertexArray, gl.STATIC_DRAW)
  }

  move() {
    this.updateTiles()
  }

  onData(e) {
    if (e.sourceDataType === 'content') this.updateTiles()
  }

  updateTiles() {
    this.sourceCache.update(this.map.painter.transform)
  }

  render(gl) {
    const { sourceCache, program } = this

    const tiles = sourceCache
      .getVisibleCoordinates()
      .map(tileId => sourceCache.getTile(tileId))
      .filter(tile => !sourceCache.hasRenderableParent(tile.tileID))

    gl.useProgram(program)

    tiles.forEach(tile => {
      if (!tile.texture) return
      gl.activeTexture(gl.TEXTURE0)
      gl.bindTexture(gl.TEXTURE_2D, tile.texture.texture)

      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR)
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR)

      gl.bindBuffer(gl.ARRAY_BUFFER, program.vertexBuffer)
      gl.enableVertexAttribArray(program.a_pos)
      gl.vertexAttribPointer(program.aPos, 2, gl.FLOAT, false, 0, 0)

      gl.uniformMatrix4fv(program.uMatrix, false, tile.tileID.posMatrix)
      gl.uniform1i(program.uTexture, 0)
      gl.depthFunc(gl.LESS)
      gl.drawArrays(gl.TRIANGLES, 0, 6)
    })
  }
}
