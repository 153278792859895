import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEmail } from '@project/auth'
import { LasFile, authorizedFetch } from '@project/lib'
import { setItem, setLoading, setError } from '@project/api'

const API = process.env.REACT_APP_FORESTRY_API

const fetchTile = (itemId, projectId, scanId, key, controller) => async (dispatch, getState) => {
  const state = getState()
  const loading = state.api.loading[itemId]
  const completelyLoaded = state.api.items[itemId]?.progress === 1
  if (loading || completelyLoaded) return

  dispatch(setLoading(itemId, true))
  dispatch(setError(itemId, null))
  try {
    const { signal } = controller
    const url = `${API}/projects/${projectId}/scan-slice-pointcloud/${scanId}/${key}`
    const response = await authorizedFetch(url, { signal })
    const lasFile = new LasFile()

    if (response.body) {
      const reader = response.body.getReader()
      let progress = 0
      while (true) {
        const { done, value } = await reader.read()
        if (done) break
        lasFile.addBufferData(value)
        const data = lasFile.getData()
        const p = data.progress
        if (p - progress > 0.15 || p === 1) {
          progress = p
          dispatch(setItem(itemId, data))
        }
      }
    } else {
      const arrayBuffer = await response.arrayBuffer()
      const data = new Uint8Array(arrayBuffer)
      lasFile.addBufferData(data)
      dispatch(setItem(itemId, lasFile.getData()))
    }
  } catch (e) {
    // do nothing
  } finally {
    dispatch(setLoading(itemId, false))
  }
}

export const useScanSlicePointcloud = (projectId, scanId, key) => {
  const dispatch = useDispatch()
  const itemId = `@@${getEmail()}/scan-slice-pointcloud/${projectId}/${scanId}/${key}`

  useEffect(() => {
    const controller = new AbortController()

    if (projectId && scanId && key) {
      dispatch(fetchTile(itemId, projectId, scanId, key, controller))
    }

    return () => {
      controller.abort()
    }
  }, [itemId, projectId, scanId, key, dispatch])

  const item = useSelector(state => itemId && state.api.items[itemId])
  const loading = useSelector(state => itemId && state.api.loading[itemId])
  const error = useSelector(state => itemId && state.api.error[itemId])
  const progress = useSelector(state => itemId && state.api.progress[itemId])

  return [item, loading, error, progress]
}
