import React, { useRef } from 'react'
import { LineLayer } from '../../../base/geoJSON/LineLayer'
import { FillLayer } from '../../../base/geoJSON/FillLayer'

export const GeohashGridLayer = ({
  onClick,
  selectedGeohash,
  selectedGeohashColor = '#358c5e',
  hoveredGeohashColor = '#358c5e',
  zIndex,
}) => {
  const hoverRef = useRef()

  return (
    <>
      <LineLayer
        lineColor="#a0a0a0"
        lineWidth={['interpolate', ['linear'], ['zoom'], 14, 1, 17, 2, 20, 3, 24, 4]}
        lineOpacity={['interpolate', ['linear'], ['zoom'], 14, 0.2, 16, 0.4, 18, 0.7, 20, 1.0]}
        zIndex={zIndex}
      />
      <LineLayer
        lineColor={hoveredGeohashColor}
        lineWidth={['interpolate', ['linear'], ['zoom'], 14, 2, 24, 6]}
        lineOpacity={['case', ['boolean', ['feature-state', 'hover'], false], 1, 0]}
        zIndex={zIndex + 0.1}
      />
      <FillLayer
        key={selectedGeohash}
        fillColor="transparent"
        onMouseMove={(event, sourceId) => {
          const { target: map, features } = event
          if (features.length > 0) {
            if (hoverRef.current) {
              map.setFeatureState({ source: sourceId, id: hoverRef.current }, { hover: false })
            }
            hoverRef.current = features[0].id
            map.setFeatureState({ source: sourceId, id: hoverRef.current }, { hover: true })
            if (features[0].properties.geohash !== selectedGeohash) {
              map.getCanvas().style.cursor = 'pointer'
            } else {
              map.getCanvas().style.cursor = ''
            }
          }
        }}
        onMouseLeave={(event, sourceId) => {
          const { target: map } = event
          if (hoverRef.current) {
            map.setFeatureState({ source: sourceId, id: hoverRef.current }, { hover: false })
          }
          hoverRef.current = null
          map.getCanvas().style.cursor = ''
        }}
        onClick={onClick}
        zIndex={zIndex + 0.2}
      />
      <LineLayer
        lineColor={selectedGeohashColor}
        lineWidth={['interpolate', ['linear'], ['zoom'], 14, 2, 24, 6]}
        lineOpacity={['case', ['==', ['get', 'geohash'], selectedGeohash || ''], 1, 0]}
        dynamicPaint
        zIndex={zIndex + 0.3}
      />
    </>
  )
}
