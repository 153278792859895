const SET_GEOHASHES_TO_BE_SKIPPED = '@@tree-editor/data/SET_GEOHASHES_TO_BE_SKIPPED'

const initialState = {
  geohashesToBeSkipped: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_GEOHASHES_TO_BE_SKIPPED: {
      return { ...state, geohashesToBeSkipped: action.geohashesToBeSkipped }
    }
    default:
      return state
  }
}

export const setGeohashesToBeSkipped = geohashesToBeSkipped => ({
  type: SET_GEOHASHES_TO_BE_SKIPPED,
  geohashesToBeSkipped,
})

export const skipGeohash = geohash => (dispatch, getState) => {
  const { geohashesToBeSkipped } = getState().data
  const filtered = geohashesToBeSkipped.filter(item => item !== geohash)
  if (filtered.length !== geohashesToBeSkipped.length) dispatch(setGeohashesToBeSkipped(filtered))
  else dispatch(setGeohashesToBeSkipped([...filtered, geohash]))
}
