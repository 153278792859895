const SET_IS_STICKY_MODE = '@@modes/SET_IS_STICKY_MODE'
const SET_IS_MAGNETIC_MODE = '@@modes/SET_IS_MAGNETIC_MODE'
const SET_IS_CONSTRAINT_POLYGON_SNAP_MODE = '@@modes/SET_IS_CONSTRAINT_POLYGON_SNAP_MODE'
const SET_IS_VERTEX_REMOVE_MODE = '@@modes/SET_IS_VERTEX_REMOVE_MODE'
const SET_IS_POLYGONS_MERGE_MODE = '@@modes/SET_IS_POLYGONS_MERGE_MODE'
const SET_IS_POLYGON_BUILDER_MODE = '@@modes/SET_IS_POLYGON_BUILDER_MODE'
const SET_IS_POLYGON_REMOVE_MODE = '@@modes/SET_IS_POLYGON_REMOVE_MODE'
const SET_IS_POLYGON_SPLITTER_MODE = '@@modes/SET_IS_POLYGON_SPLITTER_MODE'
const SET_IS_POLYGON_EXPANDER_MODE = '@@modes/SET_IS_POLYGON_EXPANDER_MODE'
const SET_IS_GEOMETRY_VALIDATION_MODE = '@@modes/SET_IS_GEOMETRY_VALIDATION_MODE'

const initialState = {
  isStickyMode: true,
  isMagneticMode: false,
  isConstraintPolygonSnapMode: false,
  isVertexRemoveMode: false,
  isPolygonMergeMode: false,
  isPolygonBuilderMode: false,
  isPolygonRemoveMode: false,
  isPolygonSplitterMode: false,
  isPolygonExpanderMode: false,
  isGeometryValidationMode: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_STICKY_MODE: {
      return {
        ...state,
        isStickyMode: action.isStickyMode,
      }
    }
    case SET_IS_MAGNETIC_MODE: {
      return {
        ...state,
        isMagneticMode: action.isMagneticMode,
      }
    }
    case SET_IS_CONSTRAINT_POLYGON_SNAP_MODE: {
      return {
        ...state,
        isConstraintPolygonSnapMode: action.isConstraintPolygonSnapMode,
      }
    }
    case SET_IS_VERTEX_REMOVE_MODE: {
      return {
        ...state,
        isVertexRemoveMode: action.isVertexRemoveMode,
      }
    }
    case SET_IS_POLYGONS_MERGE_MODE: {
      return {
        ...state,
        isPolygonMergeMode: action.isPolygonMergeMode,
      }
    }
    case SET_IS_POLYGON_BUILDER_MODE: {
      return {
        ...state,
        isPolygonBuilderMode: action.isPolygonBuilderMode,
      }
    }
    case SET_IS_POLYGON_REMOVE_MODE: {
      return {
        ...state,
        isPolygonRemoveMode: action.isPolygonRemoveMode,
      }
    }
    case SET_IS_POLYGON_SPLITTER_MODE: {
      return {
        ...state,
        isPolygonSplitterMode: action.isPolygonSplitterMode,
      }
    }
    case SET_IS_POLYGON_EXPANDER_MODE: {
      return {
        ...state,
        isPolygonExpanderMode: action.isPolygonExpanderMode,
      }
    }
    case SET_IS_GEOMETRY_VALIDATION_MODE: {
      return {
        ...state,
        isGeometryValidationMode: action.isGeometryValidationMode,
      }
    }
    default:
      return state
  }
}

export const setIsStickyMode = isStickyMode => ({ type: SET_IS_STICKY_MODE, isStickyMode })

export const setIsMagneticMode = isMagneticMode => ({ type: SET_IS_MAGNETIC_MODE, isMagneticMode })

export const setIsConstraintPolygonSnapMode = isConstraintPolygonSnapMode => ({
  type: SET_IS_CONSTRAINT_POLYGON_SNAP_MODE,
  isConstraintPolygonSnapMode,
})

export const setIsVertexRemoveMode = isVertexRemoveMode => ({ type: SET_IS_VERTEX_REMOVE_MODE, isVertexRemoveMode })

export const setIsPolygonMergeMode = isPolygonMergeMode => ({
  type: SET_IS_POLYGONS_MERGE_MODE,
  isPolygonMergeMode,
})

export const setIsPolygonBuilderMode = isPolygonBuilderMode => ({
  type: SET_IS_POLYGON_BUILDER_MODE,
  isPolygonBuilderMode,
})

export const setIsPolygonRemoveMode = isPolygonRemoveMode => ({
  type: SET_IS_POLYGON_REMOVE_MODE,
  isPolygonRemoveMode,
})

export const setIsPolygonSplitterMode = isPolygonSplitterMode => ({
  type: SET_IS_POLYGON_SPLITTER_MODE,
  isPolygonSplitterMode,
})

export const setIsPolygonExpanderMode = isPolygonExpanderMode => ({
  type: SET_IS_POLYGON_EXPANDER_MODE,
  isPolygonExpanderMode,
})

export const setIsGeometryValidationMode = isGeometryValidationMode => ({
  type: SET_IS_GEOMETRY_VALIDATION_MODE,
  isGeometryValidationMode,
})
