import React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { PlansTable } from './partials/PlansTable'
import { EditPlanPopup } from './partials/EditPlanPopup'

export const Plans = () => {
  const navigate = useNavigate()
  return (
    <>
      <PlansTable />
      <Routes>
        <Route path="edit-plan/:planId" element={<EditPlanPopup onClose={() => navigate('.')} />} />
      </Routes>
    </>
  )
}
