import React from 'react'
import { EditorHints } from './partials/EditorHints'
import { TreeConfiguration } from './partials/TreeConfiguration'
import { EditorMapView } from './partials/EditorMapView'
import { Shortcuts } from './partials/Shortcuts'
import { ShortcutMessages } from './partials/ShortcutMessages'
import style from './TreeEditor.module.scss'

export const TreeEditor = () => (
  <div className={style['container']}>
    <EditorMapView fullscreenClassName={style['fullscreen']} />
    <div className={style['top-row']}>
      <div className={style['hints']}>
        <EditorHints />
      </div>
      <div className={style['configuration']}>
        <TreeConfiguration />
      </div>
      <div className={style['messages']}>
        <ShortcutMessages />
      </div>
    </div>
    <Shortcuts />
  </div>
)
