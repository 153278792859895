import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Select } from '@project/components'
import { getEntityLabel, speciesIds } from '@project/ontology'
import { setSpecies } from '../../redux/ducks/tree'
import style from './TreeConfiguration.module.scss'

export const TreeConfiguration = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const treeIsAdded = useSelector(state => !!state.tree.coordinate)
  const species = useSelector(state => state.tree.species)
  const crownRadius = useSelector(state => state.tree.crownRadius)
  const autoHeight = useSelector(state => state.tree.autoHeight)
  const bottomLevel = useSelector(state => state.tree.bottomLevel)
  const topLevel = useSelector(state => state.tree.topLevel)
  const height = Math.abs(topLevel - bottomLevel)

  if (!treeIsAdded) return null

  return (
    <div className={style['container']}>
      <div className={style['header']}>{t('editor.treeConfiguration.title')}</div>
      <div className={style['row']}>
        <div className={style['left-column']}>
          <table className={style['table']}>
            <tbody>
              <tr>
                <td>{t('common.crownRadius')}:</td>
                <td>{crownRadius.toFixed(2)} m</td>
              </tr>
              <tr>
                <td>{t('common.treeHeight')}:</td>
                <td>{(height || autoHeight).toFixed(2)} m</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={style['right-column']}>
          <Select
            label={t('common.species')}
            options={speciesIds.map(id => ({ value: id, label: getEntityLabel(id, t) }))}
            value={species}
            onChange={value => dispatch(setSpecies(value))}
          />
        </div>
      </div>
    </div>
  )
}
