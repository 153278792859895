import { useEffect, useState } from 'react'
import * as THREE from 'three'
import { usePaneUtils, useTile } from '@project/pointcloud3D'

export const PlaneIndicator = ({ value, color = '#000000' }) => {
  const paneUtils = usePaneUtils()
  const tile = useTile()
  const { normalizedBBox: bbox } = tile || {}

  const [indicator, setIndicator] = useState(null)

  useEffect(() => {
    const geometry = new THREE.BoxGeometry(1, 1, 0.005)
    const material = new THREE.MeshBasicMaterial({ color, opacity: 0.5, transparent: true })
    const i = new THREE.Mesh(geometry, material)
    setIndicator(i)
  }, [color])

  useEffect(() => {
    if (indicator) {
      paneUtils.add(indicator)
      paneUtils.render()
    }
    return () => {
      paneUtils.remove(indicator)
      paneUtils.render()
    }
  }, [indicator, paneUtils])

  useEffect(() => {
    if (indicator && bbox) {
      const { minX, minY, minZ, maxX, maxY, maxZ } = bbox
      indicator.position.z = minZ + (maxZ - minZ) * value
      indicator.scale.x = maxX - minX
      indicator.scale.y = maxY - minY
      paneUtils.render()
    }
  }, [indicator, value, bbox, paneUtils])

  return null
}
