import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { MobileHorizontalScroll, Spacer, Spinner } from '@project/components'
import { useParcels } from 'api'
import { test } from 'utils/wildcard'
import { ButtonBar } from 'components/common/ButtonBar'
import { CreateButton } from 'components/common/CreateButton'
import { Filters } from 'components/common/Filters'
import { ParcelsMapView } from 'components/common/ParcelsMapView'
import { ParcelsTable } from './partials/ParcelsTable'
import { CreateParcelPopup } from './partials/CreateParcelPopup'
import { EditParcelPopup } from './partials/EditParcelPopup'
import style from './ParcelsPage.module.scss'

export const ParcelsPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [parcels] = useParcels()

  const [propertyFilterValue, setPropertyFilterValue] = useState('')

  const [fitBounds, setFitBounds] = useState(true)

  const data = useMemo(
    () => parcels?.filter(item => test(propertyFilterValue, item.propertyId)),
    [parcels, propertyFilterValue],
  )

  const parcelIds = useMemo(() => data?.map(p => `${p.propertyId}:${p.parcelNumber}`) || [], [data])

  if (!data) return <Spinner />

  return (
    <>
      <div className={style['header']}>
        <ButtonBar>
          <CreateButton label={t('dialog.trigger.createParcel')} onClick={() => navigate('create-parcel')} />
        </ButtonBar>
        <Filters
          propertyFilterValue={propertyFilterValue}
          setPropertyFilterValue={setPropertyFilterValue}
          fitBounds={fitBounds}
          setFitBounds={setFitBounds}
        />
      </div>
      <ParcelsMapView parcelIds={parcelIds} setPropertyFilterValue={setPropertyFilterValue} />
      <Spacer height={25} />
      <MobileHorizontalScroll minWidth={700}>
        <ParcelsTable data={data} />
      </MobileHorizontalScroll>
      <Routes>
        <Route path="create-parcel" element={<CreateParcelPopup onClose={() => navigate('.')} />} />
        <Route
          path="edit-parcel/:propertyId/:parcelNumber"
          element={<EditParcelPopup onClose={() => navigate('.')} />}
        />
      </Routes>
    </>
  )
}
