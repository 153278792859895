export default {
  title: {
    login: 'We know Your trees',
    register: 'Register',
    forgotPassword: 'Password recovery',
    verify: 'Verify user',
    changePassword: 'Change password',
    resetPassword: 'Reset password',
  },

  navigation: {
    login: 'Login',
    register: 'Register',
    forgotPassword: 'Forgot password?',
  },

  submit: {
    login: 'Login',
    register: 'Submit',
    forgotPassword: 'Reset password',
    verify: 'Verify',
    continue: 'Continue',
    changePassword: 'Change password',
    resetPassword: 'Submit',
  },

  label: {
    email: 'E-mail',
    password: 'Password',
    newPassword: 'New password',
    oldPassword: 'Old password',
    repeatPassword: 'Re-type password',
    code: 'Verification code',
  },

  placeholder: {
    email: 'firstname.lastname@example.com',
  },

  validation: {
    passwordsShouldMatch: 'Passwords should match',
  },

  description: {
    default:
      'Your forest and its trees. They grow and care - but how exactly do you know what you own? ' +
      'PreFor knows with great accuracy.',
    verify:
      'We sent you a verification code to your email. Please confirm your registration by submitting ' +
      'the verification code in this form.',
    forgotPassword:
      'To reset your password, enter your username. We will email your verification code for setting new password.',
    resetPassword: 'You signed in with a temporary password. To continue, please provide a new permanent password.',
  },

  error: {
    incorrectUsernameOrPassword: 'Please check username or password',
    userDoesNotExist: 'User is not found',
    userAlreadyExists: 'An account with the given email already exists',
    invalidVerificationCode: 'Invalid verification code provided',
    userIsNotConfirmed: 'User is not confirmed',
    temporaryPasswordHasExpired: 'Temporary password has expired and must be reset by an administrator',
    passwordCannotBeReset: 'User password cannot be reset in the current state',
  },
}
