import { useEffect } from 'react'
import { useMap } from '@project/mapbox'

export const BoundsController = ({ bounds }) => {
  const map = useMap()

  useEffect(() => {
    if (map && bounds) map.fitBounds(bounds, { padding: 30 })
  }, [map, bounds])

  return null
}
