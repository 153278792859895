import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Checkbox, ControlLine, Spacer } from '@project/components'
import { Progress } from './partials/Progress'
import { MODES } from '../../utils/modes'
import { setDisplayRemovedTrees } from '../../redux/ducks/editor'
import style from './EditorHints.module.scss'

export const EditorHints = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const mode = useSelector(state => state.editor.mode)
  const displayRemovedTrees = useSelector(state => state.editor.displayRemovedTrees)
  return (
    <div className={style['container']}>
      <div className={style['header']}>{t('editor.hints.title')}</div>
      <div className={style['progress']}>
        <Progress />
      </div>
      <div className={style['content']}>
        <ControlLine combination="a" description={t('editor.hints.addTree')} active={mode === MODES.ADD} />
        <ControlLine combination="s" description={t('editor.hints.saveTree')} />
        <ControlLine combination="r" description={t('editor.hints.removeTree')} active={mode === MODES.REMOVE} />
        <ControlLine combination="g" description={t('editor.hints.markGeohash')} active={mode === MODES.GEOHASH} />
        <ControlLine combination="n" description={t('editor.hints.navigateNext')} />
        <Spacer height={10} />
        <Checkbox
          label={t('editor.displayRemovedTrees')}
          checked={displayRemovedTrees}
          onChange={() => dispatch(setDisplayRemovedTrees(!displayRemovedTrees))}
        />
      </div>
    </div>
  )
}
