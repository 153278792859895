export const SPECIES = Object.freeze({
  BETULA_SPP: 34,
  PICEA_ABIES: 2,
  PINUS_SYLVESTRIS: 1,
  POPULUS_TREMULA: 5,
  BETULA_PENDULA: 3,
  BETULA_PUBESCENS: 4,
  ALNUS_INCANA: 6,
  ALNUS_GLUTINOSA: 7,
  OTHER_CONIFEROUS: 8,
  OTHER_DECIDUOUS: 9,
  PSEUDOTSUGA_MENZIESII: 10,
  JUNIPERUS_COMMUNIS: 11,
  PINUS_CONTORTA: 12,
  ULMUS_LAEVIS: 13,
  LARIX_SPP: 14,
  TILIA_CORDATA: 15,
  PICEA_MARIANA: 16,
  SALIX_SPP: 17,
  SORBUS_AUCUPARIA: 18,
  ABIES_SPP: 19,
  SALIX_CAPREA: 20,
  FRAXINUS_EXCELSIOR: 21,
  PINUS_CEMBRA: 22,
  PICEA_OMORIKA: 23,
  QUERCUS_ROBUR: 24,
  PRUNUS_PADUS: 25,
  ACER_PLATANOIDES: 26,
  BETULA_PENDULA_VAR_CARELICA: 27,
  ULMUS_GLABRA: 28,
  DECIDUOUS: 29,
  CONIFEROUS: 30,
  PINUS: 201,
  PICEA: 202,
  BETULA: 203,
  PSEUDOTSUGA: 204,
  JUNIPERUS: 205,
  ULMUS: 206,
  LARIX: 207,
  TILIA: 208,
  SALIX: 209,
  SORBUS: 210,
  FRAXINUS: 211,
  QUERCUS: 212,
  PRUNUS: 213,
  ACER: 214,
  CARPINUS: 215,
  CORNUS: 216,
  CORYLUS: 217,
  CRATAEGUS: 218,
  MALUS: 219,
  SAMBUCUS: 220,
  FAGUS: 221,
  POPULUS: 222,
  ABIES: 223,
  ALNUS: 224,
  UNKNOWN: 0,
})

export const speciesColorSchemeIds = {
  [SPECIES.BETULA_SPP]: 'legend-ont-1',
  [SPECIES.PICEA_ABIES]: 'legend-ont-7',
  [SPECIES.PINUS_SYLVESTRIS]: 'legend-ont-3',
  [SPECIES.POPULUS_TREMULA]: 'legend-ont-4',
  [SPECIES.BETULA_PENDULA]: 'legend-ont-5',
  [SPECIES.BETULA_PUBESCENS]: 'legend-ont-6',
  [SPECIES.ALNUS_INCANA]: 'legend-ont-2',
  [SPECIES.ALNUS_GLUTINOSA]: 'legend-ont-8',
  [SPECIES.OTHER_CONIFEROUS]: 'legend-ont-9',
  [SPECIES.OTHER_DECIDUOUS]: 'legend-ont-10',
  [SPECIES.PSEUDOTSUGA_MENZIESII]: 'legend-ont-11',
  [SPECIES.JUNIPERUS_COMMUNIS]: 'legend-ont-12',
  [SPECIES.PINUS_CONTORTA]: 'legend-ont-13',
  [SPECIES.ULMUS_LAEVIS]: 'legend-ont-14',
  [SPECIES.LARIX_SPP]: 'legend-ont-15',
  [SPECIES.TILIA_CORDATA]: 'legend-ont-16',
  [SPECIES.PICEA_MARIANA]: 'legend-ont-17',
  [SPECIES.SALIX_SPP]: 'legend-ont-18',
  [SPECIES.SORBUS_AUCUPARIA]: 'legend-ont-19',
  [SPECIES.ABIES_SPP]: 'legend-ont-20',
  [SPECIES.SALIX_CAPREA]: 'legend-ont-21',
  [SPECIES.FRAXINUS_EXCELSIOR]: 'legend-ont-22',
  [SPECIES.PINUS_CEMBRA]: 'legend-ont-23',
  [SPECIES.PICEA_OMORIKA]: 'legend-ont-24',
  [SPECIES.QUERCUS_ROBUR]: 'legend-ont-25',
  [SPECIES.PRUNUS_PADUS]: 'legend-ont-26',
  [SPECIES.ACER_PLATANOIDES]: 'legend-ont-27',
  [SPECIES.BETULA_PENDULA_VAR_CARELICA]: 'legend-ont-28',
  [SPECIES.ULMUS_GLABRA]: 'legend-ont-29',
  [SPECIES.DECIDUOUS]: 'legend-ont-30',
  [SPECIES.CONIFEROUS]: 'legend-ont-31',
  [SPECIES.PINUS]: 'legend-ont-3',
  [SPECIES.PICEA]: 'legend-ont-7',
  [SPECIES.BETULA]: 'legend-ont-1',
  [SPECIES.PSEUDOTSUGA]: 'legend-ont-11',
  [SPECIES.JUNIPERUS]: 'legend-ont-12',
  [SPECIES.ULMUS]: 'legend-ont-29',
  [SPECIES.LARIX]: 'legend-ont-15',
  [SPECIES.TILIA]: 'legend-ont-16',
  [SPECIES.SALIX]: 'legend-ont-18',
  [SPECIES.SORBUS]: 'legend-ont-19',
  [SPECIES.FRAXINUS]: 'legend-ont-22',
  [SPECIES.QUERCUS]: 'legend-ont-25',
  [SPECIES.PRUNUS]: 'legend-ont-26',
  [SPECIES.ACER]: 'legend-ont-27',
  [SPECIES.CARPINUS]: 'legend-ont-21',
  [SPECIES.CORNUS]: 'legend-ont-14',
  [SPECIES.CORYLUS]: 'legend-ont-28',
  [SPECIES.CRATAEGUS]: 'legend-ont-13',
  [SPECIES.MALUS]: 'legend-ont-8',
  [SPECIES.SAMBUCUS]: 'legend-ont-24',
  [SPECIES.FAGUS]: 'legend-ont-10',
  [SPECIES.POPULUS]: 'legend-ont-4',
  [SPECIES.ABIES]: 'legend-ont-20',
  [SPECIES.ALNUS]: 'legend-ont-2',
  [SPECIES.UNKNOWN]: 'legend-default-1',
}
