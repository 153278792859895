import { useState, useEffect, useRef } from 'react'
import polylabel from 'polylabel'
import { useMap } from '../../../base/map/MapGL'
import style from './GeoJSONLabelOverlay.module.scss'

export const GeoJSONLabelOverlay = ({
  geoJSON,
  getText = feature => feature.properties.name,
  getColor, // feature => ...
  onClick,
  minZoom = 14,
}) => {
  const map = useMap()
  const onClickRef = useRef(onClick)
  const [labels, setLabels] = useState(null)

  useEffect(() => {
    if (!labels && geoJSON) {
      const ls = geoJSON.features.map(feature => {
        const text = getText(feature)
        const color = getColor && getColor(feature)
        const [lng, lat] = polylabel(feature.geometry.coordinates[0])
        return { id: feature.properties.compartmentId, lng, lat, text, color }
      })
      setLabels(ls)
    }
  }, [geoJSON, getText, getColor, labels])

  useEffect(() => {
    if (!map || !labels) return () => {}

    const container = document.createElement('div')

    const labelElements = labels.map(label => {
      const labelElement = document.createElement('div')
      labelElement.className = style['label']
      labelElement.innerText = label.text
      if (label.color) labelElement.style.background = label.color
      container.appendChild(labelElement)

      if (onClickRef.current) {
        labelElement.addEventListener('click', e => {
          e.stopPropagation()
          onClickRef.current(label)
        })
        labelElement.classList.add(style['label--interactive'])
      }

      return labelElement
    })

    let hidden = false

    const setPositions = () => {
      labels.forEach((label, index) => {
        const { lng, lat } = label
        const { x, y } = map.project([lng, lat])
        labelElements[index].style.transform = `translate(-50%, -50%) translate(${x}px, ${y}px)`
      })
    }

    const manageElements = () => {
      const zoom = map.getZoom()
      if (zoom < minZoom) {
        container.classList.add(style['container--hidden'])
        hidden = true
      } else {
        setPositions()
        if (hidden) container.classList.remove(style['container--hidden'])
        hidden = true
      }
    }

    manageElements()

    map.getCanvasContainer().appendChild(container)
    map.on('move', manageElements)

    return () => {
      if (map) {
        map.getCanvasContainer().removeChild(container)
        map.off('move', manageElements)
      }
    }
  }, [map, labels, onClickRef, minZoom])

  useEffect(() => {
    onClickRef.current = onClick
  }, [onClick, onClickRef])

  return null
}
