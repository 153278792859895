import React, { useState, useEffect, useRef, createContext, useContext } from 'react'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { SSAOPass } from 'three/examples/jsm/postprocessing/SSAOPass'
import { useSize } from 'react-use'
import style from './PaneProvider.module.scss'

class PaneUtils {
  constructor(canvas) {
    this.init(canvas)
    this.render()
  }

  init(canvas) {
    const renderer = new THREE.WebGLRenderer({ canvas, antialias: false })
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setClearColor(0xf9f9f9)
    const composer = new EffectComposer(renderer)
    const group = new THREE.Object3D()
    const scene = new THREE.Scene()
    scene.add(group)

    const camera = new THREE.PerspectiveCamera(60, 1, 0.01, 100)
    camera.up.set(0, 0, 1)
    const helperCamera = camera.clone()

    const controls = new OrbitControls(camera, renderer.domElement)
    controls.addEventListener('change', () => this.render())
    controls.enablePan = false
    controls.enableRotate = false

    const helperControls = new OrbitControls(helperCamera, renderer.domElement)
    helperControls.enablePan = false
    helperControls.enableRotate = false

    const ssaoPass = new SSAOPass(scene, camera, renderer.domElement.width, renderer.domElement.height)
    ssaoPass.kernelRadius = 0.003
    ssaoPass.minDistance = 0.00085
    ssaoPass.maxDistance = 1
    Object.assign(ssaoPass.beautyRenderTarget, { depthTexture: new THREE.DepthTexture() })
    ssaoPass.ssaoMaterial.uniforms['tDepth'].value = ssaoPass.beautyRenderTarget.depthTexture
    composer.addPass(ssaoPass)

    this.renderer = renderer
    this.composer = composer
    this.camera = camera
    this.helperCamera = helperCamera
    this.controls = controls
    this.helperControls = helperControls
    this.group = group
    this.ssaoPass = ssaoPass
  }

  getPaneContainer() {
    const { renderer } = this
    return renderer.domElement.parentNode
  }

  getTopControlsContainer() {
    const paneContainer = this.getPaneContainer()
    return paneContainer.querySelector('[data-role="top-controls"]')
  }

  getBottomControlsContainer() {
    const paneContainer = this.getPaneContainer()
    return paneContainer.querySelector('[data-role="bottom-controls"]')
  }

  updateCanvasSize(width, height) {
    const { camera, helperCamera, renderer, composer } = this
    const aspect = width / height
    camera.aspect = aspect
    camera.updateProjectionMatrix()
    helperCamera.aspect = aspect
    helperCamera.updateProjectionMatrix()
    renderer.setSize(width, height)
    composer.setSize(width, height)
  }

  updateCameraPosition(x, y, z) {
    const { camera, helperCamera } = this
    camera.position.set(x, y, z)
    helperCamera.position.set(x, y, z)
  }

  updateCameraLookAt(x, y, z) {
    const { camera, helperCamera } = this
    camera.lookAt(x, y, z)
    helperCamera.lookAt(x, y, z)
  }

  updateControlsTarget(x, y, z) {
    const { controls, helperControls } = this
    controls.target.set(x, y, z)
    controls.update()
    helperControls.target.set(x, y, z)
    helperControls.update()
  }

  add(object) {
    const { group } = this
    group.add(object)
  }

  remove(object) {
    const { group } = this
    group.remove(object)
  }

  render() {
    const { composer } = this
    composer.render()
  }
}

const PaneUtilsContext = createContext(null)

export const usePaneUtils = () => useContext(PaneUtilsContext)

export const PaneProvider = ({ children }) => {
  const canvasRef = useRef()
  const canvas = canvasRef.current

  const [element, { width, height }] = useSize(
    <div className={style['container']}>
      <canvas className={style['canvas']} ref={canvasRef} />
      <div data-role="top-controls" className={style['top-controls']} />
      <div data-role="bottom-controls" className={style['bottom-controls']} />
    </div>,
  )

  const [paneUtils, setPaneUtils] = useState(null)

  useEffect(() => {
    if (canvas) {
      const pu = new PaneUtils(canvas)
      setPaneUtils(pu)
    }
  }, [canvas])

  useEffect(() => {
    if (paneUtils) {
      paneUtils.updateCanvasSize(width, height)
      paneUtils.render()
    }
  }, [paneUtils, width, height])

  return (
    <PaneUtilsContext.Provider value={paneUtils}>
      {element}
      {paneUtils && children}
    </PaneUtilsContext.Provider>
  )
}
