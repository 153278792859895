import { useUnmount } from 'react-use'
import { useMap } from '../map/MapGL'

export const useUnmountLayerEffect = layerId => {
  const map = useMap()

  useUnmount(() => {
    try {
      if (map.getLayer(layerId)) map.removeLayer(layerId)
    } catch (e) {
      // do nothing
    }
  })
}
