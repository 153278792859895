import React from 'react'
import classnames from 'classnames'
import style from './Breadcrumbs.module.scss'

export const Breadcrumbs = ({ items }) => {
  if (!items?.length) return null

  return (
    <div className={style['container']}>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <div key={index} className={style['item']}>
            {item.element}
          </div>
          <i className={classnames('fa fa-angle-right', style['separator'])} />
        </React.Fragment>
      ))}
    </div>
  )
}
