import React from 'react'
import { useTranslation } from 'react-i18next'
import { Legend } from '@project/components'
import { COLOR } from '../utils/color'

export const ParcelsLegend = () => {
  const { t } = useTranslation()
  return (
    <Legend
      items={[
        { color: COLOR.BLACK, text: `${t('common.unplanned')}` },
        { color: COLOR.GRAY, text: `${t('common.planned')}` },
        { color: COLOR.ORANGE, text: `${t('common.uploaded')}` },
        { color: COLOR.YELLOW, text: `${t('common.preProcessed')}` },
        { color: COLOR.LIGHT_GREEN, text: `${t('common.postProcessed')}` },
        { color: COLOR.GREEN, text: `${t('common.processed')}` },
      ]}
      getItemColor={item => item.color}
      getItemText={item => item.text}
    />
  )
}
