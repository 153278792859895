import React from 'react'
import classnames from 'classnames'
import style from './Trigger.module.scss'

export const Trigger = ({ className, label, options, values, onChange, disabled, tabIndex, opened, setOpened }) => {
  const selected = options.filter(o => values?.includes(o.value))
  return (
    <div className={style['container']}>
      <input
        type="text"
        value={selected[0]?.label || ''}
        className={style['input']}
        onKeyDown={event => {
          if (event.keyCode === 32) {
            event.preventDefault()
            if (!opened) setOpened(true)
          }
        }}
        onChange={() => {}}
        tabIndex={tabIndex}
      />
      <div className={classnames(style['trigger'], className, { [style['disabled']]: disabled })}>
        {label && <div className={style['select-label']}>{label}</div>}
        <div className={style['option-labels']}>
          {selected.map(option => (
            <div key={option.value} className={style['option-label']}>
              {option.label}
              <i
                className="fa fa-times"
                onClick={e => {
                  e.stopPropagation()
                  onChange(values.filter(v => v !== option.value))
                }}
              />
            </div>
          ))}
        </div>
        <div className={style['trigger__arrow']}>
          <i className={classnames('fa fa-angle-down', { 'fa-rotate-180': opened && !disabled })} />
        </div>
      </div>
    </div>
  )
}
