import { uid } from 'react-uid'
import { TYPE } from '../../utils/type'

const ADD_NOTIFICATION = '@notifications/add_notification'
const DELETE_NOTIFICATION = '@notifications/delete_notification'

const initialState = {
  items: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      return {
        ...state,
        items: [...state.items, action.notification],
      }
    }
    case DELETE_NOTIFICATION: {
      return {
        ...state,
        items: state.items.filter(({ id }) => id !== action.id),
      }
    }
    default:
      return state
  }
}

export const addNotification = notification => ({ type: ADD_NOTIFICATION, notification })

export const deleteNotification = id => ({ type: DELETE_NOTIFICATION, id })

export const pushNotification =
  ({ message, type = TYPE.SUCCESS, timeout }) =>
  dispatch => {
    const id = uid({})
    const notification = { id, message, type, timeout }
    dispatch(addNotification(notification))
    timeout && setTimeout(() => dispatch(deleteNotification(id)), timeout)
    return id
  }
