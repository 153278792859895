import React from 'react'
import classnames from 'classnames'
import { useWindowSize } from 'react-use'
import style from './MobileHorizontalScroll.module.scss'

export const MobileHorizontalScroll = ({ breakpoint = 800, minWidth = 1000, maxHeight = '60vh', children }) => {
  const { width: windowWidth } = useWindowSize()
  const isMobile = windowWidth <= breakpoint

  return (
    <div className={classnames({ [style['mobile']]: isMobile })} style={isMobile ? { maxHeight } : {}}>
      <div style={{ minWidth: isMobile ? `${minWidth}px` : 'unset' }}>{children}</div>
    </div>
  )
}
