import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Spinner } from '@project/components'
import { usePipelines } from 'api'
import { PipelinesView } from '../../common/PipelinesView'
import { ScanDashboard } from '../../common/ScanDashboard'
import { ParcelMap } from '../../common/ParcelMap'

export const Pipelines = () => {
  const { t } = useTranslation()
  const { projectId } = useParams()
  const [pipelines] = usePipelines(projectId)

  const data = useMemo(() => {
    if (!pipelines?.project) return null
    return { scanId: null, pipelines: pipelines.project }
  }, [pipelines])

  if (!data) return <Spinner />

  if (!data.pipelines.length) return t('table.fallback.nothingToDisplay')

  return <PipelinesView dashboard={<ScanDashboard scan={data} />} map={<ParcelMap />} />
}
