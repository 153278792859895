import React from 'react'
import ReactDOM from 'react-dom'
import { useQueryParams } from '@project/routing'
import { Spinner } from '@project/components'
import { usePaneUtils, useTile } from '@project/pointcloud3D'
import style from './Progress.module.scss'

export const Progress = () => {
  const paneUtils = usePaneUtils()
  const [{ geohash }] = useQueryParams()
  const tile = useTile()

  if (!geohash) return null
  if (!tile) return ReactDOM.createPortal(<Spinner className={style['spinner']} />, paneUtils.getPaneContainer())
  if (tile.progress === 1) return null
  return (
    <div className={style['bar']}>
      <div className={style['indicator']} style={{ width: `${tile.progress * 100}%` }} />
    </div>
  )
}
