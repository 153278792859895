export const MAIN_GROUP = Object.freeze({
  MINERAL: 3001,
  SPRUCE_MIRE: 3002,
  PINE_MIRE: 3003,
  OPEN_BOG: 3004,
  OPEN_FEN: 3005,
})

export const mainGroupColorSchemeIds = {
  [MAIN_GROUP.MINERAL]: 'legend-ont-1',
  [MAIN_GROUP.SPRUCE_MIRE]: 'legend-ont-2',
  [MAIN_GROUP.PINE_MIRE]: 'legend-ont-3',
  [MAIN_GROUP.OPEN_BOG]: 'legend-ont-4',
  [MAIN_GROUP.OPEN_FEN]: 'legend-ont-5',
}
