import React from 'react'
import classnames from 'classnames'
import style from './Button.module.scss'

export const Button = ({ className, icon, green = true, transparent, children, type = 'button', ...restProps }) => (
  <button
    className={classnames(
      {
        [style['button']]: true,
        [style['button--green']]: green,
        [style['button--transparent']]: transparent,
      },
      className,
    )}
    type={type}
    {...restProps}
  >
    {icon && <span className={style['icon']}>{icon}</span>}
    <span className={style['caption']}>{children}</span>
  </button>
)
