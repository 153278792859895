import React, { useMemo } from 'react'
import gh from 'latlon-geohash'
import * as turf from '@turf/turf'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryParams } from '@project/routing'
import { Ruler as ToolkitRuler, useTile } from '@project/pointcloud3D'
import { setBottomLevel, setTopLevel } from '../../../../../../redux/ducks/tree'
import { setRulerActive } from '../../../../../../redux/ducks/editor'
import style from './Ruler.module.scss'

export const getBBoxHeight = (geohash, bbox) => {
  const { minX, maxX, minZ, maxZ } = bbox
  const { sw, ne } = gh.bounds(geohash)
  const p1 = turf.point([ne.lon, sw.lat])
  const p2 = turf.point([sw.lon, sw.lat])
  const dx = turf.distance(p1, p2, { units: 'kilometers' }) * 1000
  return (dx * (maxZ - minZ)) / (maxX - minX)
}

export const Ruler = () => {
  const [{ geohash }] = useQueryParams()
  const tile = useTile()
  const dispatch = useDispatch()
  const bottomLevel = useSelector(state => state.tree.bottomLevel)
  const topLevel = useSelector(state => state.tree.topLevel)
  const rulerActive = useSelector(state => state.editor.rulerActive)

  const { normalizedBBox: bbox } = tile || {}

  const height = useMemo(() => {
    if (!geohash || !bbox) return null
    return getBBoxHeight(geohash, bbox)
  }, [geohash, bbox])

  if (!height) return null

  return (
    <ToolkitRuler
      height={height}
      bottomValue={bottomLevel}
      onBottomValueChange={value => dispatch(setBottomLevel(value))}
      topValue={topLevel}
      onTopValueChange={value => dispatch(setTopLevel(value))}
      onDragStart={() => dispatch(setRulerActive(true))}
      onDragEnd={() => dispatch(setRulerActive(false))}
      topIndicatorColor="#333"
      bottomIndicatorColor="#333"
      showIndicators={rulerActive}
      barClassName={style['bar']}
    />
  )
}
