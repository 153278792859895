export default {
  hints: {
    title: 'Shortcuts',
    hide: 'Hide',
    show: 'Show',
    r: 'Delete vertex',
    q: 'Stick vertices with equal coordinates together',
    m: 'Attach vertex to the nearest neighboring vertex',
    b: 'Snap vertex to property border',
    c: 'Merge compartments',
    a: 'Create new compartment on empty area',
    d: 'Delete compartment',
    s: 'Split compartment',
    e: 'Expand compartment',
    v: 'Validate geometry',
    ctrlZ: 'Undo',
    ctrlShiftZ: 'Redo',
  },
  validation: {
    message: 'Geometry errors found. Use <1>V + N</1> combination to navigate to next error.',
  },
}
