import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@project/components'
import style from './OrderProgress.module.scss'

export const OrderProgress = ({ orderProjects }) => {
  const { t } = useTranslation()

  const totalUavScans = orderProjects.reduce((acc, p) => acc + p.status.uavScans, 0)
  const totalPlannedUavScans = orderProjects.reduce((acc, p) => acc + p.plannedUavScans, 0)
  const uavProgress = (totalUavScans / totalPlannedUavScans) * 100
  const uavLabel = uavProgress && uavProgress !== Infinity ? `${uavProgress.toFixed(0)}%` : '—'

  const totalBackpackScans = orderProjects.reduce((acc, p) => acc + p.status.backpackScans, 0)
  const totalPlannedBackpackScans = orderProjects.reduce((acc, p) => acc + p.plannedBackpackScans, 0)
  const backpackProgress = (totalBackpackScans / totalPlannedBackpackScans) * 100
  const backpackLabel = backpackProgress && backpackProgress !== Infinity ? `${backpackProgress.toFixed(0)}%` : '—'

  return (
    <Tooltip
      trigger={<div className={style['trigger']}>{`${uavLabel} / ${backpackLabel}`}</div>}
      content={
        <div>
          <div>
            {t('orderProgress.uavProgress')}: {uavLabel}
          </div>
          <div>
            {t('orderProgress.backpackProgress')}: {backpackLabel}
          </div>
        </div>
      }
    />
  )
}
