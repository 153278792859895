export default {
  hints: {
    title: '???', // TODO: sync with FI and EN versions
    hide: '???', // TODO: sync with FI and EN versions
    show: '???', // TODO: sync with FI and EN versions
    r: '???', // TODO: sync with FI and EN versions
    q: '???', // TODO: sync with FI and EN versions
    m: '???', // TODO: sync with FI and EN versions
    b: '???', // TODO: sync with FI and EN versions
    c: '???', // TODO: sync with FI and EN versions
    a: '???', // TODO: sync with FI and EN versions
    d: '???', // TODO: sync with FI and EN versions
    s: '???', // TODO: sync with FI and EN versions
    e: '???', // TODO: sync with FI and EN versions
    v: '???', // TODO: sync with FI and EN versions
    ctrlZ: '???', // TODO: sync with FI and EN versions
    ctrlShiftZ: '???', // TODO: sync with FI and EN versions
  },
  validation: {
    message: '???', // TODO: sync with FI and EN versions
  },
}
