import { useEffect } from 'react'
import { usePaneUtils, useTile } from '@project/pointcloud3D'

export const OrbitControls = () => {
  const paneUtils = usePaneUtils()
  const tile = useTile()
  const { minX, minY, minZ, maxX, maxY, maxZ } = tile?.normalizedBBox || {}

  useEffect(() => {
    const { controls } = paneUtils
    controls.enablePan = true
  }, [paneUtils])

  useEffect(() => {
    const x = (minX + maxX) / 2
    const y = (minY + maxY) / 2
    const z = (minZ + maxZ) / 2

    const dx = maxX - minX
    const dy = maxY - minY

    const { fov } = paneUtils.camera

    const cameraY = dx > dy ? (0.5 * dx) / Math.tan((Math.PI * fov) / 180) : y
    const cameraX = dx < dy ? (0.5 * dy) / Math.tan((Math.PI * fov) / 180) : x

    paneUtils.updateCameraPosition(cameraX, cameraY, z)
    paneUtils.updateCameraLookAt(x, y, z)
    paneUtils.updateControlsTarget(x, y, z)
    paneUtils.render()
  }, [paneUtils, minX, minY, minZ, maxX, maxY, maxZ])

  return null
}
