export const UNEVEN_AGED = Object.freeze({
  TRUE: 'ONTOLOGY_UNEVEN_AGED_TRUE',
  FALSE: 'ONTOLOGY_UNEVEN_AGED_FALSE',
})

export const unevenAgedColorSchemeIds = {
  [UNEVEN_AGED.TRUE]: 'legend-ont-1',
  [UNEVEN_AGED.FALSE]: 'legend-ont-3',
}

export const getUnevenAgedEntityByValue = value => {
  if (value === true) return UNEVEN_AGED.TRUE
  if (value === false) return UNEVEN_AGED.FALSE
  return null
}

export const getUnevenAgedValueByEntity = entity => {
  if (entity === UNEVEN_AGED.TRUE) return true
  if (entity === UNEVEN_AGED.FALSE) return false
  return null
}
