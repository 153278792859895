import React from 'react'
import { MapView } from '@project/components'
import { ParcelsMap } from './partials/ParcelsMap'
import { ParcelsLegend } from './partials/ParcelsLegend'

export const ParcelsMapView = ({ parcelIds, setPropertyFilterValue }) => (
  <MapView
    map={<ParcelsMap parcelIds={parcelIds} setPropertyFilterValue={setPropertyFilterValue} />}
    aside={<ParcelsLegend />}
  />
)
