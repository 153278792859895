import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@project/components'
import style from './ProjectStatus.module.scss'

const getLabel = value => (isNaN(value) || value === null ? '—' : value)

export const ProjectStatus = ({ project }) => {
  const { t } = useTranslation()
  const { plannedUavScans, plannedBackpackScans, status } = project

  if (!status) return '—'

  const {
    text,
    stage,
    uavScans,
    preprocessedUavScans,
    processedUavScans,
    backpackScans,
    preprocessedBackpackScans,
    processedBackpackScans,
  } = status

  return (
    <Tooltip
      trigger={<div className={style['trigger']}>{text || '—'}</div>}
      content={
        <div>
          {stage && <div className={style['title']}>{stage}</div>}
          <div className={style['section']}>
            <div>UAV</div>
            <ul>
              <li>
                {t('status.planned')}: {getLabel(plannedUavScans)}
              </li>
              <li>
                {t('status.uploaded')}: {getLabel(uavScans)}
              </li>
              <li>
                {t('status.slammed')}: {getLabel(preprocessedUavScans)}
              </li>
              <li>
                {t('status.postProcessed')}: {getLabel(processedUavScans)}
              </li>
            </ul>
          </div>
          <div className={style['section']}>
            <div>Backpack</div>
            <ul>
              <li>
                {t('status.planned')}: {getLabel(plannedBackpackScans)}
              </li>
              <li>
                {t('status.uploaded')}: {getLabel(backpackScans)}
              </li>
              <li>
                {t('status.preProcessed')}: {getLabel(preprocessedBackpackScans)}
              </li>
              <li>
                {t('status.postProcessed')}: {getLabel(processedBackpackScans)}
              </li>
            </ul>
          </div>
        </div>
      }
    />
  )
}
