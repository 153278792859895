import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAsyncFn } from 'react-use'
import { Form, Field } from 'react-final-form'
import {
  Dialog,
  DialogClose,
  DialogHeader,
  DialogContent,
  DialogButtons,
  Button,
  FormControl,
  TextInput,
  FormControlMessage,
  Select,
  MultiSelect,
  Spacer,
} from '@project/components'
import { requiredValidator } from '@project/validation'
import { useProject, usePipelines } from 'api'
import { createJob } from 'api/requests'

export const CreateJobPopup = ({ mutation, onClose }) => {
  const { t } = useTranslation()
  const { projectId } = useParams()
  const [project] = useProject(projectId)
  const refreshPipelines = usePipelines(projectId)[4]

  const emptyInitialValues = useMemo(() => [], [])

  const [{ loading }, onSubmit] = useAsyncFn(
    async fields => {
      try {
        const args = mutation.arguments
          .map(({ type, name }) => {
            if (type === 'boolean') return `${name}: ${fields[name]}`
            if (type === 'string' || type === 'choice') return `${name}: "${fields[name]}"`
            if (type === 'multiple-choice') return `${name}: [${fields[name].map(n => `"${n}"`).join(' ')}]`
            return null
          })
          .filter(Boolean)
          .join(', ')

        const query = `
          mutation {
            ${mutation.name}(${args}) {
              success
            }
          }
        `
        await createJob(query)
        await new Promise(resolve => setTimeout(resolve, 2000))
        await refreshPipelines(true, true)
        onClose()
      } catch (error) {
        console.log(error)
        // do nothing
      }
    },
    [refreshPipelines],
  )

  return (
    <Dialog loading={loading || !project}>
      <DialogClose onClose={onClose} />
      <DialogHeader>{t('dialog.title.createJob')}</DialogHeader>
      <DialogContent>
        {t('dialog.description.createJob')}
        <Spacer height={15} />
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {mutation.arguments
                .filter(argument => !argument.stringValue)
                .map(argument => {
                  if (argument.type === 'string') {
                    return (
                      <FormControl>
                        <Field
                          name={argument.name}
                          validate={argument.required ? requiredValidator : () => undefined}
                          render={({ input, meta }) => (
                            <>
                              <TextInput label={argument.name} {...input} />
                              {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                            </>
                          )}
                        />
                      </FormControl>
                    )
                  }

                  if (argument.type === 'choice') {
                    return (
                      <FormControl>
                        <Field
                          name={argument.name}
                          validate={argument.required ? requiredValidator : () => undefined}
                          initialValue={argument.choiceDefault}
                          render={({ input, meta }) => (
                            <>
                              <Select label={argument.name} options={argument.choices} {...input} />
                              {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                            </>
                          )}
                        />
                      </FormControl>
                    )
                  }

                  if (argument.type === 'multiple-choice') {
                    return (
                      <FormControl>
                        <Field
                          name={argument.name}
                          validate={values => (values.length || !argument.required ? undefined : requiredValidator())}
                          initialValue={argument.multipleChoiceDefault || emptyInitialValues}
                          render={({ input, meta }) => (
                            <>
                              <MultiSelect
                                label={argument.name}
                                options={argument.choices}
                                values={input.value}
                                {...input}
                              />
                              {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                            </>
                          )}
                        />
                      </FormControl>
                    )
                  }

                  if (argument.type === 'boolean') {
                    const options = [
                      { value: true, label: 'Yes' },
                      { value: false, label: 'No' },
                    ]
                    return (
                      <FormControl>
                        <Field
                          name={argument.name}
                          validate={argument.required ? requiredValidator : () => undefined}
                          initialValue={argument.booleanDefault}
                          render={({ input, meta }) => (
                            <>
                              <Select label={argument.name} options={options} value={input.value} {...input} />
                              {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                            </>
                          )}
                        />
                      </FormControl>
                    )
                  }

                  return null
                })}

              {mutation.arguments
                .filter(argument => argument.stringValue)
                .map(({ name, stringValue }) => (
                  <Field
                    key={name}
                    name={name}
                    initialValue={stringValue}
                    render={({ input }) => <input {...input} type="hidden" disabled />}
                  />
                ))}

              <DialogButtons>
                <Button onClick={onClose} disabled={loading} transparent>
                  {t('common.cancel')}
                </Button>
                <Button type="submit" disabled={loading}>
                  {t('common.ok')}
                </Button>
              </DialogButtons>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  )
}
