import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import { forgotPasswordRequest, forgotPasswordSubmit, login } from '../../../../../../redux/ducks/auth'
import { validateEmail, validateCode, validatePassword } from '../../util/validate'
import { AuthInput } from '../../elements/AuthInput'
import { AuthButton } from '../../elements/AuthButton'

export const ForgotPassword = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [codeWasSent, setCodeWasSent] = useState(false)

  const onRequest = async ({ username }) => {
    try {
      await dispatch(forgotPasswordRequest(username))
      setCodeWasSent(true)
    } catch (e) {
      /* do nothing */
    }
  }

  const onSubmit = async ({ username, code, password }) => {
    try {
      await dispatch(forgotPasswordSubmit(username, code, password))
      await dispatch(login(username, password))
    } catch (e) {
      /* do nothing */
    }
  }

  return (
    <Form
      onSubmit={codeWasSent ? onSubmit : onRequest}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <AuthInput
            name="username"
            label={t('auth:label.email')}
            placeholder={t('auth:placeholder.email')}
            validate={validateEmail}
            disabled={codeWasSent}
          />
          {codeWasSent && (
            <>
              <AuthInput name="code" label={t('auth:label.code')} placeholder="XXXXXX" validate={validateCode} />
              <AuthInput
                name="password"
                type="password"
                label={t('auth:label.newPassword')}
                placeholder="********"
                validate={validatePassword}
              />
              <AuthInput
                name="passwordRepeat"
                type="password"
                label={t('auth:label.repeatPassword')}
                placeholder="********"
                validate={(value, allValues) =>
                  validatePassword(value) ||
                  (value === allValues.password ? undefined : t('auth:validation.passwordsShouldMatch'))
                }
              />
            </>
          )}
          <AuthButton type="submit">
            {codeWasSent ? t('auth:submit.resetPassword') : t('auth:submit.continue')}
          </AuthButton>
        </form>
      )}
    />
  )
}
