import React, { useEffect, useRef } from 'react'
import ReactDOMServer from 'react-dom/server'
import mapboxgl from 'mapbox-gl'
import { useMap } from '../../../base/map/MapGL'
import { FillLayer } from '../../../base/geoJSON/FillLayer'
import { Popup } from '../../overlay/Popup'

export const PopupLayer = ({ zIndex, getPopupTitle, getPopupContent, ...rest }) => {
  const map = useMap()

  const timeoutRef = useRef(-1)
  const popupRef = useRef(null)

  const showPopup = !!(getPopupTitle || getPopupContent)

  useEffect(() => {
    const onMove = () => {
      clearTimeout(timeoutRef.current)
      if (popupRef.current) popupRef.current.remove()
    }
    if (showPopup) {
      map.on('move', onMove)
    }
    return () => {
      map.off('move', onMove)
      if (popupRef.current) popupRef.current.remove()
    }
  }, [map, showPopup])

  return (
    <FillLayer
      fillColor="transparent"
      onMouseMove={
        showPopup &&
        (event => {
          const { features, lngLat } = event
          if (features.length === 0) return
          clearTimeout(timeoutRef.current)
          if (!popupRef.current) {
            popupRef.current = new mapboxgl.Popup({ closeButton: false, closeOnClick: false, maxWidth: '400px' })
          } else {
            popupRef.current.remove()
          }
          timeoutRef.current = setTimeout(() => {
            const title = getPopupTitle && getPopupTitle(features[0])
            const content = getPopupContent && getPopupContent(features[0])
            const html = ReactDOMServer.renderToString(<Popup title={title} content={content} />)
            popupRef.current.setLngLat(lngLat).setHTML(html).addTo(map)
          }, 700)
        })
      }
      onMouseLeave={
        showPopup &&
        (() => {
          clearTimeout(timeoutRef.current)
        })
      }
      zIndex={zIndex}
      {...rest}
    />
  )
}
