import React from 'react'
import i18next from 'i18next'
import classnames from 'classnames'
import './LanguageSwitch.scss'

export const LanguageSwitch = () => {
  const languages = i18next.options.supportedLngs.filter(lng => lng !== 'cimode')
  if (languages.length < 2) return null
  return (
    <div className="language-switch">
      {languages.map(lng => {
        const className = classnames({
          'language-switch__item': true,
          'language-switch__item--active': i18next.language === lng,
        })
        return (
          <div key={lng} className={className} onClick={() => i18next.changeLanguage(lng)}>
            {lng}
          </div>
        )
      })}
    </div>
  )
}
