import { useState, useEffect, useRef } from 'react'
import { uniqueId, isEqual } from 'lodash'
import { useMap, useLayerOrderRegulator } from '../../map/MapGL'
import { useGeoJSONSource } from '../GeoJSONSource'
import { useUnmountLayerEffect } from '../../hooks/useUnmountLayerEffect'

const getPaint = (lineColor, lineWidth, lineOpacity) => {
  const paint = {
    'line-color': lineColor,
    'line-width': lineWidth,
    'line-opacity': lineOpacity,
  }
  Object.keys(paint).forEach(key => {
    if (paint[key] === undefined) delete paint[key]
  })
  return paint
}

const getLayout = (lineCap, lineJoin, visibility) => {
  const layout = {
    'line-cap': lineCap,
    'line-join': lineJoin,
    visibility,
  }
  Object.keys(layout).forEach(key => {
    if (layout[key] === undefined) delete layout[key]
  })
  return layout
}

export const LineLayer = ({
  lineColor,
  lineWidth,
  lineOpacity,
  dynamicPaint = false,
  //
  lineCap,
  lineJoin,
  visibility,
  dynamicLayout = false,
  //
  onMouseMove,
  onMouseLeave,
  onMouseDown,
  onMouseUp,
  onClick,
  dynamicCallbacks = false,
  //
  zIndex,
}) => {
  const map = useMap()
  const sourceId = useGeoJSONSource()[0]
  const layerId = useState(uniqueId())[0]
  const paintRef = useRef(getPaint(lineColor, lineWidth, lineOpacity))
  const layoutRef = useRef(getLayout(lineCap, lineJoin, visibility))

  const regulator = useLayerOrderRegulator(layerId, zIndex || 0)

  useEffect(() => {
    const layer = { id: layerId, type: 'line', source: sourceId, paint: paintRef.current, layout: layoutRef.current }
    map.addLayer(layer)
    regulator.orderLayers()
  }, [map, sourceId, layerId, paintRef, layoutRef, regulator])

  useUnmountLayerEffect(layerId)

  /// //////////////////////////////
  /// //////////////////////////////

  useEffect(() => {
    if (dynamicPaint) {
      const paint = getPaint(lineColor, lineWidth, lineOpacity)
      Object.entries(paint).forEach(([key, value]) => {
        if (!isEqual(value, paintRef.current[key])) {
          map.setPaintProperty(layerId, key, value)
        }
      })
      paintRef.current = paint
    }
  }, [map, layerId, lineColor, lineWidth, lineOpacity, dynamicPaint, paintRef])

  /// //////////////////////////////
  /// //////////////////////////////

  useEffect(() => {
    if (dynamicLayout) {
      const layout = getLayout(lineCap, lineJoin, visibility)
      Object.entries(layout).forEach(([key, value]) => {
        if (!isEqual(value, layoutRef.current[key])) {
          map.setLayoutProperty(layerId, key, value)
        }
      })
      layoutRef.current = layout
    }
  }, [map, layerId, lineCap, lineJoin, visibility, dynamicLayout, layoutRef])

  /// //////////////////////////////
  /// //////////////////////////////

  const [callbacks, setCallbacks] = useState(null)

  useEffect(() => {
    if (!callbacks || dynamicCallbacks) {
      setCallbacks({
        mousemove: onMouseMove && (event => onMouseMove(event, sourceId, layerId)),
        mouseleave: onMouseLeave && (event => onMouseLeave(event, sourceId, layerId)),
        mousedown: onMouseDown && (event => onMouseDown(event, sourceId, layerId)),
        mouseup: onMouseUp && (event => onMouseUp(event, sourceId, layerId)),
        click: onClick && (event => onClick(event, sourceId, layerId)),
      })
    }
  }, [onMouseMove, onMouseLeave, onMouseDown, onMouseUp, onClick, dynamicCallbacks, sourceId, layerId, callbacks])

  useEffect(() => {
    Object.entries(callbacks || {}).forEach(([key, value]) => {
      if (value) map.on(key, layerId, value)
    })

    return () => {
      Object.entries(callbacks || {}).forEach(([key, value]) => {
        if (value) map.off(key, layerId, value)
      })
    }
  }, [map, layerId, callbacks])

  return null
}
