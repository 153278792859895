import { useEffect } from 'react'
import mapboxgl from 'mapbox-gl'
import { useMap } from '../../map/MapGL'
import style from './NavigationControl.module.scss'

/* eslint-disable no-underscore-dangle */
export const NavigationControl = ({ showCompass = true }) => {
  const map = useMap()

  useEffect(() => {
    if (!map) return () => {}

    const control = new mapboxgl.NavigationControl({ showCompass })
    map.addControl(control, 'bottom-right')
    control._container.classList.add(style['navigation-control'])

    return () => {
      map.removeControl(control)
    }
  }, [map, showCompass])

  return null
}
