export const ACCESSIBILITY = Object.freeze({
  ALL_YEAR: 6001,
  NOT_DURING_FROST_HEAVE: 6002,
  DRY_SUMMER: 6003,
  WINTER: 6004,
  NOT_DEFINED: 6005,
})

export const accessibilityColorSchemeIds = {
  [ACCESSIBILITY.ALL_YEAR]: 'legend-ont-1',
  [ACCESSIBILITY.NOT_DURING_FROST_HEAVE]: 'legend-ont-2',
  [ACCESSIBILITY.DRY_SUMMER]: 'legend-ont-3',
  [ACCESSIBILITY.WINTER]: 'legend-ont-4',
  [ACCESSIBILITY.NOT_DEFINED]: 'legend-ont-5',
}
