import React, { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as turf from '@turf/turf'
import { LineLayer, GeoJSONSource, useMap } from '@project/mapbox'
import { setCrownRadius } from '../../../../../../../../redux/ducks/tree'

export const CrownRadiusCircle = ({ zIndex }) => {
  const map = useMap()
  const dispatch = useDispatch()
  const coordinate = useSelector(state => state.tree.coordinate)
  const crownRadius = useSelector(state => state.tree.crownRadius)

  const [dragging, setDragging] = useState(false)

  const crownRadiusCircle = useMemo(() => {
    const options = { steps: 50, units: 'kilometers' }
    return coordinate && turf.circle(coordinate, (crownRadius || 2) / 1000, options)
  }, [coordinate, crownRadius])

  useEffect(() => {
    const onMouseMove = event => {
      if (map.getZoom() > 19) {
        const { lng, lat } = event.lngLat
        const p1 = turf.point([lng, lat])
        const p2 = turf.point(coordinate)
        const distance = turf.distance(p1, p2, { units: 'kilometers' })
        dispatch(setCrownRadius(distance * 1000))
      } else {
        setDragging(false)
      }
    }

    if (map && coordinate && dragging) {
      map.dragPan.disable()
      map.on('mousemove', onMouseMove)
    }

    return () => {
      map.dragPan.enable()
      map.off('mousemove', onMouseMove)
    }
  }, [map, coordinate, dragging, dispatch])

  if (!crownRadiusCircle) return null

  return (
    <>
      <GeoJSONSource data={crownRadiusCircle} dynamicData>
        <LineLayer lineColor="purple" lineWidth={3} zIndex={zIndex} />
        <LineLayer
          lineColor="transparent"
          lineWidth={12}
          onMouseDown={() => setDragging(true)}
          onMouseUp={() => setDragging(false)}
          zIndex={zIndex + 0.1}
        />
      </GeoJSONSource>
    </>
  )
}
