import React, { useState, useMemo } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { MobileHorizontalScroll, Spacer, Spinner } from '@project/components'
import { test } from 'utils/wildcard'
import { useProjects, useParcels, useOrders, useCustomers } from 'api'
import { ParcelsMapView } from 'components/common/ParcelsMapView'
import { Filters } from 'components/common/Filters'
import { ButtonBar } from 'components/common/ButtonBar'
import { CreateButton } from 'components/common/CreateButton'
import { ProjectsTable } from '../../common/ProjectsTable'
import { CreateProjectPopup } from './partials/CreateProjectPopup'
import { EditProjectPopup } from '../../common/EditProjectPopup'
import style from './ProjectsPage.module.scss'

export const ProjectsPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [projects] = useProjects()
  const [parcels] = useParcels()
  const [customers] = useCustomers()
  const [orders] = useOrders()

  const [propertyFilterValue, setPropertyFilterValue] = useState('')
  const [customerFilterValue, setCustomerFilterValue] = useState('')

  const [fitBounds, setFitBounds] = useState(true)

  const unfilteredData = useMemo(() => {
    if (!projects || !parcels || !customers || !orders) return null

    return projects.map(project => {
      const parcel = parcels.find(p => p.parcelNumber === project.parcelNumber)
      const order = orders.find(o => o.orderId === project.order?.orderId)
      const customer = customers.find(c => c.customerId === order?.customerId)
      return { ...project, parcel, customer, order }
    })
  }, [projects, parcels, customers, orders])

  const data = useMemo(() => {
    if (!unfilteredData) return null
    return unfilteredData
      .filter(item => test(propertyFilterValue, item.propertyId))
      .filter(item => test(customerFilterValue, item.customer?.name))
  }, [unfilteredData, propertyFilterValue, customerFilterValue])

  const parcelIds = useMemo(
    () => (data ? [...new Set(data.map(p => `${p.propertyId}:${p.parcelNumber}`))] : []),
    [data],
  )

  if (!data) return <Spinner />

  return (
    <>
      <div className={style['header']}>
        <ButtonBar>
          <CreateButton label={t('dialog.trigger.createProject')} onClick={() => navigate('create-project')} />
        </ButtonBar>
        <Filters
          propertyFilterValue={propertyFilterValue}
          setPropertyFilterValue={setPropertyFilterValue}
          customerFilterValue={customerFilterValue}
          setCustomerFilterValue={setCustomerFilterValue}
          fitBounds={fitBounds}
          setFitBounds={setFitBounds}
        />
      </div>
      <ParcelsMapView parcelIds={parcelIds} setPropertyFilterValue={setPropertyFilterValue} />
      <Spacer height={25} />
      <MobileHorizontalScroll>
        <ProjectsTable data={data} />
      </MobileHorizontalScroll>
      <Routes>
        <Route path="create-project" element={<CreateProjectPopup onClose={() => navigate('.')} />} />
        <Route path="edit-project/:projectId" element={<EditProjectPopup onClose={() => navigate('.')} />} />
      </Routes>
    </>
  )
}
