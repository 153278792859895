import React from 'react'
import { Row } from './partials/Row'
import style from './Body.module.scss'

export const Body = ({ data, columns, SubComponent, getRowStyle }) => (
  <tbody className={style['tbody']}>
    {data.map((item, index) => (
      <Row key={index} columns={columns} dataItem={item} SubComponent={SubComponent} getRowStyle={getRowStyle} />
    ))}
  </tbody>
)
