import React from 'react'
import { useParams } from 'react-router-dom'
import {
  MapGL,
  GeoJSONSource,
  ScaleControl,
  NavigationControl,
  CtrlScrollZoomControl,
  PropertyLineLayer,
} from '@project/mapbox'
import { useProject, useParcel } from 'api'
import { TilesSubstrate } from 'components/map/substrate'
import { Spinner } from '@project/components'
import { CreateTreeSubstrate } from './partials/CreateTreeSubstrate'
import { ZoomTracker } from './partials/ZoomTracker'
import { HeightAutoComplete } from './partials/HeightAutoComplete'
import { RemoveTreeSubstrate } from './partials/RemoveTreeSubstrate'
import { AddedTreesSubstrate } from './partials/AddedTreesSubstrate'
import { RemovedTreesSubstrate } from './partials/RemovedTreesSubstrate'
import { TrunksSubstrate } from './partials/TrunksSubstrate'
import { NavigationControl as GeohashNavigationControl } from './partials/NavigationControl'
import { GeohashStatusSubstrate } from './partials/GeohashStatusSubstrate'
import { SelectedGeohashControl } from './partials/SelectedGeohashControl'
import { SkippedGeohashesControl } from './partials/SkippedGeohashesControl'

export const EditorMap = () => {
  const { projectId } = useParams()
  const [project] = useProject(projectId)
  const { propertyId, parcelNumber } = project || {}
  const [parcel] = useParcel(propertyId, parcelNumber)

  if (!parcel) return <Spinner />

  return (
    <MapGL bounds={parcel.geometry.bbox}>
      <TilesSubstrate zIndex={0} />
      <TrunksSubstrate zIndex={5} />
      <GeoJSONSource data={parcel.geometry}>
        <PropertyLineLayer zIndex={10} />
      </GeoJSONSource>
      <GeohashStatusSubstrate zIndex={15} />
      <SelectedGeohashControl zIndex={20} />
      <AddedTreesSubstrate zIndex={25} />
      <RemovedTreesSubstrate zIndex={30} />
      <CreateTreeSubstrate zIndex={35} />
      <RemoveTreeSubstrate zIndex={40} />
      <SkippedGeohashesControl zIndex={45} />
      <HeightAutoComplete />
      <ScaleControl />
      <CtrlScrollZoomControl />
      <NavigationControl />
      <ZoomTracker />
      <GeohashNavigationControl />
    </MapGL>
  )
}
