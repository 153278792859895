import React, { useEffect, useRef } from 'react'
import * as THREE from 'three'
import { LasFile } from '@project/lib'
import lasFileUrl from './udcbt90snj00.las'
import './Tree.scss'

class PCanvas {
  constructor(canvas) {
    this.init(canvas)
  }

  init(canvas) {
    const renderer = new THREE.WebGLRenderer({ canvas, antialias: false, alpha: true })
    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(50, 1, 0.01, 1000)

    camera.position.x = 0
    camera.position.y = 1.65
    camera.position.z = 0
    camera.up.set(0, 0, 1)
    camera.lookAt(0, 0, 0)

    const particles = new THREE.BufferGeometry()
    const material = new THREE.PointsMaterial({
      size: 0.005,
      color: 0x2e4123,
      transparent: true,
      opacity: 0.35,
    })
    const points = new THREE.Points(particles, material)
    points.frustumCulled = false

    scene.add(points)

    renderer.render(scene, camera)

    this.renderer = renderer
    this.scene = scene
    this.camera = camera
    this.particles = particles
    this.points = points
  }

  updateCanvasSize(width, height) {
    const { camera, renderer } = this
    const aspect = width / height
    camera.aspect = aspect
    camera.updateProjectionMatrix()
    renderer.setSize(width, height)
  }

  updateBufferGeometry(positions, colors) {
    const { particles } = this
    particles.setAttribute('position', new THREE.BufferAttribute(positions, 3))
    particles.setAttribute('color', new THREE.BufferAttribute(colors, 3))
  }

  rotatePoints(angle) {
    const { points } = this
    points.rotateZ(angle)
  }

  getCanvas() {
    return this.renderer.domElement
  }

  render() {
    const { renderer, scene, camera } = this
    renderer.render(scene, camera)
  }
}

export const Tree = () => {
  const canvasRef = useRef()

  useEffect(() => {
    const canvas = canvasRef.current
    const pc = window.WebGLRenderingContext && new PCanvas(canvas)

    const loadAndDraw = async () => {
      const response = await fetch(lasFileUrl)
      const buffer = await response.arrayBuffer()
      const lasFile = new LasFile()
      lasFile.addBufferData(new Uint8Array(buffer))
      pc.updateBufferGeometry(lasFile.normalizedPositions, lasFile.colors)
      pc.render()
    }

    const updateCanvasSize = () => {
      const { width, height } = pc.getCanvas().parentElement.getBoundingClientRect()
      pc.updateCanvasSize(width, height)
      pc.render()
    }

    const animate = () => {
      requestAnimationFrame(animate)
      pc.rotatePoints(Math.PI / 180)
      pc.render()
    }

    if (pc) {
      loadAndDraw()
      updateCanvasSize()
      animate()
      window.addEventListener('resize', updateCanvasSize, false)
    }

    return () => {
      window.removeEventListener('resize', updateCanvasSize)
    }
  }, [canvasRef])

  return (
    <div className="tree">
      <canvas ref={canvasRef} />
    </div>
  )
}
