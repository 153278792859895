import React from 'react'
import ReactDOM from 'react-dom'
import { Spinner } from '@project/components'
import { useSpinnerOverlayNode } from '../../../base/map/MapGL'

export const SpinnerOverlay = () => {
  const node = useSpinnerOverlayNode()
  if (!node) return null
  return ReactDOM.createPortal(<Spinner />, node)
}
