import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from '@project/components'
import { pushNotification } from '@project/notifications'
import { restore } from '../../redux/ducks/auth'
import { PATHS } from '../../util/paths'
import { Logo } from './partials/Logo'
import { Tree } from './partials/Tree'
import { Forms } from './partials/Forms'
import { Header } from './partials/Header'
import { Footer } from './partials/Footer'
import './Auth.scss'

export const Auth = ({ children }) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const user = useSelector(state => state.auth.user)
  const restoring = useSelector(state => state.auth.restoring)
  const loading = useSelector(state => state.auth.loading)

  useEffect(() => {
    dispatch(restore())
  }, [dispatch])

  useEffect(() => {
    const message = localStorage.getItem('AUTH_NOTIFICATION')
    if (message) {
      pushNotification({ message, type: 'error', timeout: 10000 })
      localStorage.removeItem('AUTH_NOTIFICATION')
    }
  }, [])

  if (restoring) return null
  if (user && !user.challengeName && pathname !== PATHS.CHANGE_PASSWORD) return children

  return (
    <div className="auth">
      <div className="auth__content">
        <div className="auth__left-section">
          <div className="auth__tree">
            <Tree />
          </div>
          <div className="auth__logo">
            <Logo />
          </div>
          <div className="auth__footer">
            <Footer />
          </div>
        </div>
        <div className="auth__right-section">
          <div className="auth__mobile-logo">
            <Logo />
          </div>
          <Header />
          <Forms />
          {loading && <Spinner />}
        </div>
      </div>
    </div>
  )
}
