import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import { DialogContent, DialogButtons, Button, Checkbox, Spinner } from '@project/components'
import { useProperties, usePlannerUsers } from 'api'
import { addPlannerUserProperties, removePlannerUserProperties } from 'api/requests'
import style from './EditProperties.module.scss'

export const EditProperties = ({ onClose }) => {
  const { t } = useTranslation()
  const { email } = useParams()

  const [propertyIds, loadingProperties] = useProperties()
  const [users, loadingUsers, , , refreshUsers] = usePlannerUsers()

  const [userPropertyIds, setUserPropertyIds] = useState([])

  useEffect(() => {
    const ids = users?.find(u => u.email === email)?.properties.map(p => p.propertyId)
    setUserPropertyIds(ids)
  }, [users, email])

  const [{ loading }, onSubmit] = useAsyncFn(async () => {
    try {
      const originalUserPropertyIds = users?.find(u => u.email === email)?.properties.map(p => p.propertyId)

      const idsToAdd = userPropertyIds.filter(id => !originalUserPropertyIds.includes(id))
      if (idsToAdd.length) await addPlannerUserProperties(email, idsToAdd)

      const idsToRemove = originalUserPropertyIds.filter(id => !userPropertyIds.includes(id))
      if (idsToRemove.length) await removePlannerUserProperties(email, idsToRemove)

      await new Promise(resolve => setTimeout(resolve, 2000))
      await refreshUsers(true, true)
      onClose()
    } catch (error) {
      // do nothing
    }
  }, [userPropertyIds, email])

  return (
    <div>
      <DialogContent>
        <div className={style['content']}>
          {userPropertyIds &&
            propertyIds?.map(propertyId => (
              <Checkbox
                key={propertyId}
                label={propertyId}
                checked={userPropertyIds.includes(propertyId)}
                onChange={e => {
                  const ids = userPropertyIds.filter(id => id !== propertyId)
                  if (e.target.checked) ids.push(propertyId)
                  setUserPropertyIds(ids)
                }}
              />
            ))}
        </div>
      </DialogContent>
      <DialogButtons>
        <Button onClick={onClose} disabled={loading} transparent>
          {t('common.cancel')}
        </Button>
        <Button onClick={onSubmit} disabled={loading}>
          {t('common.ok')}
        </Button>
      </DialogButtons>
      {(loading || loadingUsers || loadingProperties) && <Spinner />}
    </div>
  )
}
