import { useState, useEffect, useRef } from 'react'
import { uniqueId, isEqual } from 'lodash'
import { useMap, useLayerOrderRegulator } from '../../map/MapGL'
import { useGeoJSONSource } from '../GeoJSONSource'
import { useUnmountLayerEffect } from '../../hooks/useUnmountLayerEffect'

const getPaint = (
  fillAntialias,
  fillColor,
  fillOpacity,
  fillOutlineColor,
  fillPattern,
  fillTranslate,
  fillTranslateAnchor,
) => {
  const paint = {
    'fill-antialias': fillAntialias,
    'fill-color': fillColor,
    'fill-opacity': fillOpacity,
    'fill-outline-color': fillOutlineColor,
    'fill-pattern': fillPattern,
    'fill-translate': fillTranslate,
    'fill-translate-anchor': fillTranslateAnchor,
  }
  Object.keys(paint).forEach(key => {
    if (paint[key] === undefined) delete paint[key]
  })
  return paint
}

const getLayout = (fillSortKey, visibility) => {
  const layout = {
    'fill-sort-key': fillSortKey,
    visibility,
  }
  Object.keys(layout).forEach(key => {
    if (layout[key] === undefined) delete layout[key]
  })
  return layout
}

export const FillLayer = ({
  fillAntialias,
  fillColor,
  fillOpacity,
  fillOutlineColor,
  fillPattern,
  fillTranslate,
  fillTranslateAnchor,
  dynamicPaint = false,
  //
  fillSortKey,
  visibility,
  dynamicLayout = false,
  //
  onMouseMove,
  onMouseLeave,
  onClick,
  dynamicCallbacks = false,
  //
  zIndex,
}) => {
  const map = useMap()
  const sourceId = useGeoJSONSource()[0]
  const layerId = useState(uniqueId())[0]
  const paintRef = useRef(
    getPaint(fillAntialias, fillColor, fillOpacity, fillOutlineColor, fillPattern, fillTranslate, fillTranslateAnchor),
  )
  const layoutRef = useRef(getLayout(fillSortKey, visibility))

  const regulator = useLayerOrderRegulator(layerId, zIndex || 0)

  useEffect(() => {
    const layer = { id: layerId, type: 'fill', source: sourceId, paint: paintRef.current, layout: layoutRef.current }
    map.addLayer(layer)
    regulator.orderLayers()
  }, [map, sourceId, layerId, paintRef, layoutRef, regulator])

  useUnmountLayerEffect(layerId)

  /// //////////////////////////////
  /// //////////////////////////////

  useEffect(() => {
    if (dynamicPaint) {
      const paint = getPaint(
        fillAntialias,
        fillColor,
        fillOpacity,
        fillOutlineColor,
        fillPattern,
        fillTranslate,
        fillTranslateAnchor,
      )
      Object.entries(paint).forEach(([key, value]) => {
        if (!isEqual(value, paintRef.current[key])) {
          map.setPaintProperty(layerId, key, value)
        }
      })
      paintRef.current = paint
    }
  }, [
    map,
    layerId,
    fillAntialias,
    fillColor,
    fillOpacity,
    fillOutlineColor,
    fillPattern,
    fillTranslate,
    fillTranslateAnchor,
    dynamicPaint,
    paintRef,
  ])

  /// //////////////////////////////
  /// //////////////////////////////

  useEffect(() => {
    if (dynamicLayout) {
      const layout = getLayout(fillSortKey, visibility)
      Object.entries(layout).forEach(([key, value]) => {
        if (!isEqual(value, layoutRef.current[key])) {
          map.setLayoutProperty(layerId, key, value)
        }
      })
      layoutRef.current = layout
    }
  }, [map, layerId, fillSortKey, visibility, dynamicLayout, layoutRef])

  /// //////////////////////////////
  /// //////////////////////////////

  const [callbacks, setCallbacks] = useState(null)

  useEffect(() => {
    if (!callbacks || dynamicCallbacks) {
      setCallbacks({
        mousemove: onMouseMove && (event => onMouseMove(event, sourceId, layerId)),
        mouseleave: onMouseLeave && (event => onMouseLeave(event, sourceId, layerId)),
        click: onClick && (event => onClick(event, sourceId, layerId)),
      })
    }
  }, [onMouseMove, onMouseLeave, onClick, dynamicCallbacks, sourceId, layerId, callbacks])

  useEffect(() => {
    Object.entries(callbacks || {}).forEach(([key, value]) => {
      if (value) map.on(key, layerId, value)
    })

    return () => {
      Object.entries(callbacks || {}).forEach(([key, value]) => {
        if (value) map.off(key, layerId, value)
      })
    }
  }, [map, layerId, callbacks])

  return null
}
