import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useMap } from '@project/mapbox'
import { setEditorEnabled } from '../../../../../../redux/ducks/editor'

export const ZoomTracker = () => {
  const map = useMap()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setEditorEnabled(map.getZoom() > 18.5))
    const onZoom = () => dispatch(setEditorEnabled(map.getZoom() > 18.5))
    map.on('zoom', onZoom)

    return () => map.off('zoom', onZoom())
  }, [map, dispatch])

  return null
}
