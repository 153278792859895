export class LasFile {
  constructor() {
    this.chunks = []
    this.buffer = null
    this.bufferUint8Array = null
    this.dataView = null
    this.numberOfBytesRetrieved = 0
    this.numberOfReadPoints = 0

    this.header = null
    this.positions = null
    this.colors = null
    this.altitudeColors = null
    this.gpsTimes = null
    this.minGpsTime = Infinity
    this.maxGpsTime = -Infinity
    this.gpsTimeColors = null

    this.normalizedPositions = null
    this.normalizedBBox = null
  }

  addBufferData(chunk) {
    if (!this.buffer) {
      this.chunks.push(chunk)
      this.initialize()
    }
    this.bufferUint8Array.set(chunk, this.numberOfBytesRetrieved)
    this.numberOfBytesRetrieved += chunk.length
    this.parse()
  }

  initialize() {
    const { chunks } = this

    let length = 0
    chunks.forEach(chunk => (length += chunk.length))
    if (length < 227) return

    let offset = 0
    const head = new Uint8Array(length)
    chunks.forEach(chunk => {
      head.set(chunk, offset)
      offset += chunk.length
    })

    const header = {}
    const dataView = new DataView(head.buffer)
    header.offsetToPointData = dataView.getUint32(96, true)
    header.pointDataRecordFormat = dataView.getUint8(104)
    header.pointDataRecordLength = dataView.getUint16(105, true)
    header.numberOfPointRecords = dataView.getUint32(107, true)
    header.scaleX = dataView.getFloat64(131, true)
    header.scaleY = dataView.getFloat64(139, true)
    header.scaleZ = dataView.getFloat64(147, true)
    header.offsetX = dataView.getFloat64(155, true)
    header.offsetY = dataView.getFloat64(163, true)
    header.offsetZ = dataView.getFloat64(171, true)
    header.maxX = dataView.getFloat64(179, true)
    header.minX = dataView.getFloat64(187, true)
    header.maxY = dataView.getFloat64(195, true)
    header.minY = dataView.getFloat64(203, true)
    header.maxZ = dataView.getFloat64(211, true)
    header.minZ = dataView.getFloat64(219, true)
    this.header = header

    const { pointDataRecordLength, numberOfPointRecords, offsetToPointData } = header
    const lasFileLength = pointDataRecordLength * numberOfPointRecords + offsetToPointData
    this.buffer = new ArrayBuffer(lasFileLength)
    this.bufferUint8Array = new Uint8Array(this.buffer)
    this.dataView = new DataView(this.buffer)
    this.bufferUint8Array.set(head)
    this.positions = new Float32Array(numberOfPointRecords * 3)
    this.normalizedPositions = new Float32Array(numberOfPointRecords * 3)
    this.colors = new Float32Array(numberOfPointRecords * 3)
    this.altitudeColors = new Float32Array(numberOfPointRecords * 3)
    this.gpsTimes = new Float32Array(numberOfPointRecords)
    this.gpsTimeColors = new Float32Array(numberOfPointRecords * 3)

    const { minX, minY, minZ, maxX, maxY, maxZ } = header
    const maxSize = Math.max(maxX - minX, maxY - minY, maxZ - minZ)

    this.normalizedBBox = {
      minX: (-0.5 * (maxX - minX)) / maxSize,
      minY: (-0.5 * (maxY - minY)) / maxSize,
      minZ: (-0.5 * (maxZ - minZ)) / maxSize,
      maxX: (0.5 * (maxX - minX)) / maxSize,
      maxY: (0.5 * (maxY - minY)) / maxSize,
      maxZ: (0.5 * (maxZ - minZ)) / maxSize,
    }
  }

  parse() {
    const { pointDataRecordFormat, pointDataRecordLength, offsetToPointData } = this.header
    const { scaleX, scaleY, scaleZ, minX, minY, minZ, maxX, maxY, maxZ, offsetX, offsetY, offsetZ } = this.header
    const maxSize = Math.max(maxX - minX, maxY - minY, maxZ - minZ)
    let { numberOfReadPoints } = this
    while (true) {
      const offset = offsetToPointData + numberOfReadPoints * pointDataRecordLength
      if (offset + pointDataRecordLength > this.numberOfBytesRetrieved) break
      const x = this.dataView.getInt32(offset, true) * scaleX + offsetX
      const y = this.dataView.getInt32(offset + 4, true) * scaleY + offsetY
      const z = this.dataView.getInt32(offset + 8, true) * scaleZ + offsetZ
      this.positions[numberOfReadPoints * 3] = x
      this.positions[numberOfReadPoints * 3 + 1] = y
      this.positions[numberOfReadPoints * 3 + 2] = z

      this.normalizedPositions[numberOfReadPoints * 3] = (x - minX) / maxSize - (0.5 * (maxX - minX)) / maxSize
      this.normalizedPositions[numberOfReadPoints * 3 + 1] = (y - minY) / maxSize - (0.5 * (maxY - minY)) / maxSize
      this.normalizedPositions[numberOfReadPoints * 3 + 2] = (z - minZ) / maxSize - (0.5 * (maxZ - minZ)) / maxSize

      const colorOffset = offset + this.getPointRecordColorOffset()

      // set original colors
      this.colors[numberOfReadPoints * 3] = this.dataView.getUint16(colorOffset, true) / 256
      this.colors[numberOfReadPoints * 3 + 1] = this.dataView.getUint16(colorOffset + 2, true) / 256
      this.colors[numberOfReadPoints * 3 + 2] = this.dataView.getUint16(colorOffset + 4, true) / 256
      // set ramp colors
      const i = (z - minZ) / (maxZ - minZ)
      this.altitudeColors[numberOfReadPoints * 3] = 3 * i - 1
      this.altitudeColors[numberOfReadPoints * 3 + 1] = i < 0.5 ? 4 * i : 2.5 * (1 - i)
      this.altitudeColors[numberOfReadPoints * 3 + 2] = 1 - 3 * i

      if (pointDataRecordFormat === 1) {
        const gpsTime = this.dataView.getFloat64(offset + 20, true)
        this.minGpsTime = Math.min(this.minGpsTime, gpsTime)
        this.maxGpsTime = Math.max(this.maxGpsTime, gpsTime)
        this.gpsTimes[numberOfReadPoints] = gpsTime
      }

      numberOfReadPoints += 1
    }
    this.numberOfReadPoints = numberOfReadPoints

    if (pointDataRecordFormat === 1 && this.getProgress() > 0.9) {
      for (let n = 0; n < numberOfReadPoints; n += 1) {
        // set gps time colors
        const i = (this.gpsTimes[n] - this.minGpsTime) / (this.maxGpsTime - this.minGpsTime)
        this.gpsTimeColors[n * 3] = 3 * i - 1
        this.gpsTimeColors[n * 3 + 1] = i < 0.5 ? 4 * i : 2.5 * (1 - i)
        this.gpsTimeColors[n * 3 + 2] = 1 - 3 * i
      }
    }
  }

  getProgress() {
    const { header, numberOfReadPoints } = this
    return header ? numberOfReadPoints / header.numberOfPointRecords : 0
  }

  getData() {
    const {
      header,
      numberOfReadPoints,
      positions,
      normalizedPositions,
      colors,
      altitudeColors,
      gpsTimeColors,
      normalizedBBox,
      extendedBBox,
    } = this
    const progress = header ? numberOfReadPoints / header.numberOfPointRecords : 0
    return {
      header,
      progress,
      positions,
      normalizedPositions,
      colors,
      altitudeColors,
      gpsTimeColors,
      normalizedBBox,
      extendedBBox,
    }
  }

  getPointRecordColorOffset() {
    const { pointDataRecordFormat } = this.header
    if (pointDataRecordFormat === 2) return 20
    return 28 // version 3
  }
}
