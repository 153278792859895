import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useKeyPressEvent } from 'react-use'
import gh from 'latlon-geohash'
import { pushNotification } from '@project/notifications'
import { useQueryParams } from '@project/routing'
import { useMap } from '@project/mapbox'
import { useProject, usePointcloudGeohashesGeoJSON, useTreeCorrectionTiles } from 'api'
import { updateTreeCorrectionTile } from 'api/requests'
import { TILE_STATUSES } from '../../../../../../utils/tileStatuses'

export const NavigationControl = () => {
  const map = useMap()
  const { projectId } = useParams()
  const [queryParams, setQueryParams] = useQueryParams()
  const { geohash } = queryParams
  const [project] = useProject(projectId)
  const [geohashesGeoJSON] = usePointcloudGeohashesGeoJSON(projectId)
  const [treeCorrectionTiles, , , , refreshTreeCorrectionTiles] = useTreeCorrectionTiles(projectId)

  const isUnsaved = useSelector(state => !!state.tree.coordinate)

  const processedGeohashes = useMemo(() => {
    if (!treeCorrectionTiles) return null
    return treeCorrectionTiles.filter(item => item.status === TILE_STATUSES.DONE).map(item => item.geohash)
  }, [treeCorrectionTiles])

  const skippedGeohashes = useMemo(() => {
    if (!treeCorrectionTiles) return null
    return treeCorrectionTiles.filter(item => item.status === TILE_STATUSES.SKIP).map(item => item.geohash)
  }, [treeCorrectionTiles])

  const decodedGeohashes = useMemo(() => {
    if (!geohashesGeoJSON) return null
    const { features } = geohashesGeoJSON
    return features.map(f => ({ geohash: f.properties.geohash, bounds: gh.bounds(f.properties.geohash) }))
  }, [geohashesGeoJSON])

  const nextCandidates = useMemo(() => {
    if (!decodedGeohashes || !skippedGeohashes || !processedGeohashes) return null
    return decodedGeohashes
      .filter(item => !processedGeohashes.includes(item.geohash))
      .filter(item => !skippedGeohashes.includes(item.geohash))
  }, [decodedGeohashes, processedGeohashes, skippedGeohashes])

  const nextGeohash = useMemo(() => {
    if (!geohash || !nextCandidates) return null

    const { ne } = gh.bounds(geohash)

    const rows = {}
    nextCandidates.forEach(candidate => {
      const key = candidate.bounds.ne.lat.toFixed(6)
      if (!rows[key]) rows[key] = []
      rows[key].push(candidate.geohash)
    })

    const currentKey = ne.lat.toFixed(6)
    const currentRow = rows[currentKey]
    const index = currentRow.findIndex(item => item === geohash)
    if (index < currentRow.length - 1) return currentRow[index + 1]

    const rowKeys = Object.keys(rows)
    const currentRowIndex = rowKeys.findIndex(item => item === currentKey)
    if (currentRowIndex > 0) return rows[rowKeys[currentRowIndex - 1]][0]

    return rows[rowKeys[rowKeys.length - 1]][0]
  }, [geohash, nextCandidates])

  useKeyPressEvent('n', async () => {
    if (!nextGeohash || !project) return
    if (isUnsaved) {
      pushNotification({
        message: 'Failed to mark geohash as processed and navigate to the next geohash. Save the tree first.',
        type: 'error',
        timeout: 2500,
      })
      return
    }
    setQueryParams({ ...queryParams, geohash: nextGeohash })
    map.panTo(gh.decode(nextGeohash))
    await updateTreeCorrectionTile(project.jobId, geohash, TILE_STATUSES.DONE)
    await new Promise(resolve => setTimeout(resolve, 1000))
    await refreshTreeCorrectionTiles(true, true)
  })
  return null
}
