import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { changePassword } from '../../../../../../redux/ducks/auth'
import { AuthInput } from '../../elements/AuthInput'
import { validateEmail, validatePassword } from '../../util/validate'
import { AuthButton } from '../../elements/AuthButton'

export const ChangePassword = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector(state => state.auth.user)

  useEffect(() => {
    if (!user) navigate('/', { replace: true })
  }, [user, navigate])

  const onSubmit = async ({ username, oldPassword, newPassword }) => {
    try {
      await dispatch(changePassword(username, oldPassword, newPassword))
    } catch (e) {
      /* do nothing */
    }
  }

  if (!user) return null

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <AuthInput
            name="username"
            initialValue={user.attributes.email}
            label={t('auth:label.email')}
            placeholder={t('auth:placeholder.email')}
            validate={validateEmail}
            disabled
          />
          <AuthInput
            name="oldPassword"
            type="password"
            label={t('auth:label.oldPassword')}
            placeholder="********"
            validate={validatePassword}
          />
          <AuthInput
            name="newPassword"
            type="password"
            label={t('auth:label.newPassword')}
            placeholder="********"
            validate={validatePassword}
          />
          <AuthInput
            name="newPasswordRepeat"
            type="password"
            label={t('auth:label.repeatPassword')}
            placeholder="********"
            validate={(value, allValues) =>
              validatePassword(value) ||
              (value === allValues.newPassword ? undefined : t('auth:validation.passwordsShouldMatch'))
            }
          />
          <AuthButton type="submit">{t('auth:submit.changePassword')}</AuthButton>
        </form>
      )}
    />
  )
}
