import React from 'react'
import classnames from 'classnames'
import { Tooltip } from '@project/components'
import style from './JobId.module.scss'

export const JobId = ({ jobId, description }) => {
  if (!description) return jobId

  return (
    <div className={style['content']}>
      <div>{jobId}</div>
      {description && (
        <Tooltip
          trigger={<div className={classnames('fa fa-info-circle', style['trigger'])} />}
          content={description}
        />
      )}
    </div>
  )
}
