import React from 'react'
import { NavLink } from 'react-router-dom'
import './AuthLink.scss'

export const AuthLink = ({ to, label }) => (
  <div className="auth-link">
    <NavLink className="auth-link__nav-link" to={to}>
      {label}
    </NavLink>
  </div>
)
