import React, { useRef, useEffect, useLayoutEffect } from 'react'
import style from './Content.module.scss'

const getContentX = (triggerRect, contentRect, xAlignment) => {
  if (xAlignment === 'left') return triggerRect.x
  if (xAlignment === 'right') return triggerRect.x + triggerRect.width - contentRect.width
  if (xAlignment === 'center') return triggerRect.x + triggerRect.width / 2 - contentRect.width / 2
  return null
}

const testContentX = (x, contentRect) => {
  if (x === null || x < 0 || x + contentRect.width > document.documentElement.clientWidth) return false
  return true
}

const getContentY = (triggerRect, contentRect, yAlignment) => {
  if (yAlignment === 'bottom') return triggerRect.y + triggerRect.height
  if (yAlignment === 'top') return triggerRect.y - contentRect.height
  if (yAlignment === '*') {
    const spaceTop = triggerRect.y
    const spaceBottom = document.documentElement.clientHeight - triggerRect.y - triggerRect.height
    if (spaceTop > spaceBottom) return 10
    return document.documentElement.clientHeight - contentRect.height - 10
  }
  return null
}

const testContentY = (y, contentRect) => {
  if (y === null || y < 0 || y + contentRect.height > document.documentElement.clientHeight) return false
  return true
}

export const Content = ({
  renderContent,
  triggerContainer,
  xAlignment,
  yAlignment,
  closeOnClick,
  closeOnMouseLeave,
  onClose,
}) => {
  const contentContainerRef = useRef()

  useLayoutEffect(() => {
    const contentContainer = contentContainerRef.current
    if (triggerContainer && contentContainer) {
      const triggerRect = triggerContainer.getBoundingClientRect()
      const contentRect = contentContainer.getBoundingClientRect()

      let x = null
      const xAlignments = [xAlignment, 'left', 'right', 'center']
      xAlignments.forEach(alignment => {
        if (x) return
        const value = getContentX(triggerRect, contentRect, alignment)
        if (testContentX(value, contentRect)) x = value
      })

      let y = null
      const yAlignments = [yAlignment, 'bottom', 'top', '*']
      yAlignments.forEach(alignment => {
        if (y) return
        const value = getContentY(triggerRect, contentRect, alignment)
        if (testContentY(value, contentRect)) y = value
      })

      contentContainer.style.left = `${x}px`
      contentContainer.style.top = `${y}px`
    }
  }, [triggerContainer, contentContainerRef, xAlignment, yAlignment])

  useEffect(() => {
    document.addEventListener('scroll', onClose)
    return () => {
      document.removeEventListener('scroll', onClose)
    }
  }, [onClose])

  useEffect(() => {
    const onMouseMove = e => {
      const { x, y, width, height } = triggerContainer.getBoundingClientRect()
      if (e.clientX < x - 5 || e.clientX > x + width + 5 || e.clientY < y - 5 || e.clientY > y + height + 5) onClose()
    }

    if (closeOnMouseLeave) document.addEventListener('mousemove', onMouseMove)
    return () => {
      document.removeEventListener('mousemove', onMouseMove)
    }
  }, [triggerContainer, closeOnMouseLeave, onClose])

  return (
    <>
      <div className={style['overlay']} onClick={closeOnClick && onClose} />
      <div className={style['content']} ref={contentContainerRef}>
        {renderContent(triggerContainer, onClose)}
      </div>
    </>
  )
}
