import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { verify, login } from '../../../../../../redux/ducks/auth'
import { validateEmail, validateCode } from '../../util/validate'
import { AuthInput } from '../../elements/AuthInput'
import { AuthButton } from '../../elements/AuthButton'

export const Verify = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const credentials = useSelector(state => state.auth.credentials)
  const onSubmit = async ({ username, code }) => {
    try {
      await dispatch(verify(username, code))
      if (credentials && credentials.username === username) {
        const { password } = credentials
        navigate('/login')
        await dispatch(login(username, password))
      }
    } catch (e) {
      /* do nothing */
    }
  }
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <AuthInput
            name="username"
            label={t('auth:label.email')}
            placeholder={t('auth:placeholder.email')}
            validate={validateEmail}
            defaultValue={credentials && credentials.username}
          />
          <AuthInput name="code" label={t('auth:label.code')} placeholder="XXXXXX" validate={validateCode} />
          <AuthButton type="submit">{t('auth:submit.verify')}</AuthButton>
        </form>
      )}
    />
  )
}
