/**
 * Creates a RegExp from the given string, converting asterisks to .* expressions,
 * and escaping all other characters.
 */
export const wildcardToRegExp = s => new RegExp(`^${s.split(/\*+/).map(regExpEscape).join('.*')}$`)

/**
 * RegExp-escapes all characters in the given string.
 */
const regExpEscape = s => s.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')

export const test = (search, value) => {
  const s = search.trim()
  if (s === '') return true
  return value?.includes(s) || wildcardToRegExp(s).test(value)
}
