import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Breadcrumbs as ToolkitBreadcrumbs } from '@project/components'
import { useProject } from 'api'

export const Breadcrumbs = () => {
  const { t } = useTranslation()
  const { projectId } = useParams()
  const [project] = useProject(projectId)

  if (!project) return null

  return (
    <ToolkitBreadcrumbs
      items={[
        { element: <NavLink to="/projects">{t('common.projects')}</NavLink> },
        {
          element: (
            <>
              <b>
                {project.propertyId}:{project.parcelNumber}
              </b>{' '}
              {project.firstScanDate.slice(0, 10)}
            </>
          ),
        },
      ]}
    />
  )
}
