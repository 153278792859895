import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { getChangePasswordRoute } from '@project/auth'
import { Dropdown } from '@project/components'
import style from './Settings.module.scss'

export const Settings = () => {
  const { t } = useTranslation()

  return (
    <div className={style['settings']}>
      <Dropdown
        renderTrigger={() => (
          <div className={style['settings__toggle']}>
            <i className="fa fa-cog" />
          </div>
        )}
        renderContent={() => (
          <div className={style['settings__dropdown']}>
            <NavLink to={getChangePasswordRoute()} target="_blank">
              {t('page-header:changePassword')}
            </NavLink>
          </div>
        )}
        xAlignment="right"
        yAlignment="top"
      />
    </div>
  )
}
