import React from 'react'
import { LineLayer } from '../../../base/geoJSON/LineLayer'

export const PropertyLineLayer = props => (
  <LineLayer
    lineColor="#065939"
    lineWidth={['interpolate', ['linear'], ['zoom'], 10, 1, 14, 2, 20, 3]}
    lineCap="round"
    lineJoin="round"
    {...props}
  />
)
