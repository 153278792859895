import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import { Form, Field } from 'react-final-form'
import {
  Dialog,
  DialogClose,
  DialogHeader,
  DialogContent,
  DialogButtons,
  Button,
  FormControl,
  FormControlMessage,
  TextInput,
} from '@project/components'
import { emailValidator, requiredValidator } from '@project/validation'
import { updateCustomer } from 'api/requests'
import { useCustomer } from 'api'

export const EditCustomerPopup = ({ onClose }) => {
  const { t } = useTranslation()
  const { customerId } = useParams()
  const [customer, loadingCustomer, , , refreshCustomers] = useCustomer(customerId)

  const [{ loading }, onSubmit] = useAsyncFn(
    async fields => {
      const { name, phone, email } = fields
      try {
        await updateCustomer(customerId, name, phone, email)
        await new Promise(resolve => setTimeout(resolve, 2000))
        await refreshCustomers(true, true)
        onClose()
      } catch (error) {
        // do nothing
      }
    },
    [refreshCustomers],
  )

  return (
    <Dialog loading={loading || loadingCustomer}>
      <DialogClose onClose={onClose} />
      <DialogHeader>{t('dialog.title.updateCustomer')}</DialogHeader>
      <DialogContent>
        {customer && (
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormControl>
                  <Field
                    name="name"
                    initialValue={customer.name}
                    validate={requiredValidator}
                    render={({ input, meta }) => (
                      <>
                        <TextInput label={t('common.name')} {...input} />
                        {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                      </>
                    )}
                  />
                </FormControl>
                <FormControl>
                  <Field
                    name="phone"
                    initialValue={customer.phone}
                    validate={requiredValidator}
                    render={({ input, meta }) => (
                      <>
                        <TextInput label={t('common.phone')} {...input} />
                        {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                      </>
                    )}
                  />
                </FormControl>
                <FormControl>
                  <Field
                    name="email"
                    initialValue={customer.email}
                    validate={value => requiredValidator(value) || emailValidator(value)}
                    render={({ input, meta }) => (
                      <>
                        <TextInput label={t('common.email')} {...input} />
                        {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                      </>
                    )}
                  />
                </FormControl>
                <DialogButtons>
                  <Button onClick={onClose} disabled={loading} transparent>
                    {t('common.cancel')}
                  </Button>
                  <Button type="submit" disabled={loading}>
                    {t('common.save')}
                  </Button>
                </DialogButtons>
              </form>
            )}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}
