import React from 'react'
import { PlaneIndicator } from '../PlaneIndicator'
import { Bar } from './partials/Bar'
import { Scale } from './partials/Scale'
import { Slider } from './partials/Slider'

export const Ruler = ({
  height,
  bottomValue,
  onBottomValueChange,
  topValue,
  onTopValueChange,
  onDragStart,
  onDragEnd,
  topIndicatorColor = '#015a01',
  bottomIndicatorColor = '#b30000',
  showIndicators = true,
  barClassName,
}) => {
  if (!height) return null

  return (
    <Bar className={barClassName}>
      <Scale height={height} />
      <Slider
        value={(bottomValue || 0) / height}
        onChange={value => onBottomValueChange(value * height)}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        color={bottomIndicatorColor}
      />
      <Slider
        value={(topValue || 0) / height}
        onChange={value => onTopValueChange(value * height)}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        color={topIndicatorColor}
      />
      {showIndicators && (
        <>
          <PlaneIndicator value={(bottomValue || 0) / height} color={bottomIndicatorColor} />
          <PlaneIndicator value={(topValue || 0) / height} color={topIndicatorColor} />
        </>
      )}
    </Bar>
  )
}
