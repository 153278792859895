import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'

export const store = createStore(() => ({}), applyMiddleware(thunk))

const reducers = {}
export const addReducer = (name, reducer) => {
  reducers[name] = reducer
  store.replaceReducer(combineReducers(reducers))
}
