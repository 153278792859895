import React from 'react'
import moment from 'moment'
import { JOB_STATUS } from 'utils/jobStatus'

export const JobDurationAndCost = ({ status, duration, cost }) => {
  const { SUCCESS, FAILURE } = JOB_STATUS
  if (status !== SUCCESS && status !== FAILURE) return <i className="fa fa-circle-notch fa-spin" />
  if (!duration) return '—'

  const d = moment.duration(duration)
  const hours = d.hours()
  const minutes = d.minutes()
  const seconds = d.seconds()
  const hoursLabel = hours ? `${hours}h` : ''
  const minutesLabel = minutes ? `${minutes}m` : ''
  const secondsLabel = `${seconds}s`
  const durationLabel = `${hoursLabel} ${minutesLabel} ${secondsLabel}`.trim()

  const costLabel = cost ? `$${cost.toFixed(2)}` : '—'

  return `${durationLabel} / ${costLabel}`
}
