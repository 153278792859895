import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAsyncFn } from 'react-use'
import { Form, Field } from 'react-final-form'
import {
  Dialog,
  DialogClose,
  DialogHeader,
  DialogContent,
  DialogButtons,
  Button,
  FormControl,
  FormControlMessage,
  TextInput,
} from '@project/components'
import { emailValidator, requiredValidator } from '@project/validation'
import { createCustomer } from 'api/requests'
import { useCustomers } from 'api'

export const CreateCustomerPopup = ({ onClose }) => {
  const { t } = useTranslation()
  const refreshCustomers = useCustomers()[4]

  const [{ loading }, onSubmit] = useAsyncFn(
    async fields => {
      const { name, phone, email } = fields
      try {
        await createCustomer(name, phone, email)
        await new Promise(resolve => setTimeout(resolve, 2000))
        await refreshCustomers(true, true)
        onClose()
      } catch (error) {
        // do nothing
      }
    },
    [refreshCustomers],
  )

  return (
    <Dialog loading={loading}>
      <DialogClose onClose={onClose} />
      <DialogHeader>{t('dialog.title.createCustomer')}</DialogHeader>
      <DialogContent>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormControl>
                <Field
                  name="name"
                  validate={requiredValidator}
                  render={({ input, meta }) => (
                    <>
                      <TextInput label={t('common.name')} {...input} />
                      {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                    </>
                  )}
                />
              </FormControl>
              <FormControl>
                <Field
                  name="phone"
                  validate={requiredValidator}
                  render={({ input, meta }) => (
                    <>
                      <TextInput label={t('common.phone')} {...input} />
                      {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                    </>
                  )}
                />
              </FormControl>
              <FormControl>
                <Field
                  name="email"
                  validate={value => requiredValidator(value) || emailValidator(value)}
                  render={({ input, meta }) => (
                    <>
                      <TextInput label={t('common.email')} {...input} />
                      {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                    </>
                  )}
                />
              </FormControl>
              <DialogButtons>
                <Button onClick={onClose} disabled={loading} transparent>
                  {t('common.cancel')}
                </Button>
                <Button type="submit" disabled={loading}>
                  {t('common.ok')}
                </Button>
              </DialogButtons>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  )
}
