import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, Routes, Route, Navigate } from 'react-router-dom'
import { Tabs, Tab, Spacer, Spinner } from '@project/components'
import { useProject, usePipelines } from 'api'
import { Breadcrumbs } from './partials/Breadcrumbs'
import { Details } from './partials/Details'
import { Evaluation } from './partials/Evaluation'
import { Pipelines } from './partials/Pipelines'
import { UAVScans } from './partials/UAVScans'
import { BackpackScans } from './partials/BackpackScans'
import { StatusAutoUpdate } from './partials/StatusAutoUpdate'
import { TreeEditor } from './partials/TreeEditor'
import { PointcloudSliceReview } from './partials/PointcloudSliceReview'
import { Plans } from './partials/Plans'

const getCountLabel = (planned, count) => {
  let countLabel = count
  if (isNaN(countLabel)) countLabel = '?'
  let plannedLabel = planned
  if (!plannedLabel) plannedLabel = '?'
  if ((countLabel === '?' || countLabel === 0) && (plannedLabel === '?' || plannedLabel === 0)) return ''
  return ` (${countLabel}/${plannedLabel})`
}

export const PipelinesPage = () => {
  const { t } = useTranslation()
  const { projectId } = useParams()
  const [project] = useProject(projectId)
  const [pipelines] = usePipelines(projectId)

  const plannedUavScans = project?.plannedUavScans
  const plannedBackpackScans = project?.plannedBackpackScans
  const uavCount = pipelines?.uav?.filter(i => !i.label.slice(5).startsWith('99')).length // Filter in order to exclude the multi-slam jobs
  const backpackCount = pipelines?.backpack?.length

  const uavScansDetails = getCountLabel(plannedUavScans, uavCount)
  const backpackScansDetails = getCountLabel(plannedBackpackScans, backpackCount)

  if (!project) return <Spinner />

  return (
    <>
      <Breadcrumbs />
      <Details />
      <Spacer height={10} />
      <Tabs secondary>
        <Tab to="pipelines" label={t('navigation.pipelines')} />
        <Tab to="uav-scans" label={`${t('navigation.uavScans')}${uavScansDetails}`} />
        <Tab to="backpack-scans" label={`${t('navigation.backpackScans')}${backpackScansDetails}`} />
        <Tab to="tree-editor" label={t('navigation.treeEditor')} disabled={!project?.jobId} />
        <Tab to="pointcloud-slice-review" label={t('navigation.pointcloudSliceReview')} />
        {project.parcel.evaluationPipeline && <Tab to="evaluation" label={t('navigation.evaluation')} />}
        <Tab to="plans" label={t('navigation.plans')} />
      </Tabs>
      <Spacer height={30} />
      <Routes>
        <Route path="pipelines" element={<Pipelines />} />
        <Route path="uav-scans" element={<UAVScans />} />
        <Route path="backpack-scans" element={<BackpackScans />} />
        <Route path="tree-editor" element={<TreeEditor />} />
        <Route path="pointcloud-slice-review" element={<PointcloudSliceReview />} />
        <Route path="evaluation" element={<Evaluation />} />
        <Route path="plans/*" element={<Plans />} />
        <Route path="*" element={<Navigate to="pipelines" replace />} />
      </Routes>
      <StatusAutoUpdate />
    </>
  )
}
