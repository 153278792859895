import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import geometry from './ducks/geometry'
import editor from './ducks/editor'
import modes from './ducks/modes'
import polygonBuilder from './ducks/polygonBuilder'
import polygonSplitter from './ducks/polygonSplitter'
import polygonExpander from './ducks/polygonExpander'
import history from './ducks/history'
import validator from './ducks/validator'

const reducer = combineReducers({
  geometry,
  editor,
  modes,
  polygonBuilder,
  polygonSplitter,
  polygonExpander,
  history,
  validator,
})

export const configureStore = () => createStore(reducer, applyMiddleware(thunk))
