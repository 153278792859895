import React from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useMap } from '@project/mapbox'
import style from './Control.module.scss'

export const Control = ({ onPrevClick, onNextClick, iconClassName, titleKey }) => {
  const { t } = useTranslation()
  const map = useMap()

  if (!map) return null

  return ReactDOM.createPortal(
    <div className={style['control']}>
      <div className={style['control__icon']}>
        <i className={`fa ${iconClassName}`} />
      </div>
      <div className={style['control__title']}>{t(titleKey)}</div>
      <div className={style['control__arrows']}>
        <div className={style['control__arrow']} onClick={onPrevClick}>
          <i className="fa fa-angle-left" />
        </div>
        <div className={style['control__arrow']} onClick={onNextClick}>
          <i className="fa fa-angle-right" />
        </div>
      </div>
    </div>,
    map.getContainer(),
  )
}
