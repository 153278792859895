export default {
  tileLayer: {
    baseMap: 'peruskartta',
    aerialMap: 'MML ilmakuva',
    preforAerialMap: 'PreFor ilmakuva',
    preforCHMMap: 'PreFor latvusmalli',
  },
  control: {
    showBaseMap: 'Näytä pohjakartta',
  },
}
