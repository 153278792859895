import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAsyncFn } from 'react-use'
import { Form, Field } from 'react-final-form'
import {
  Dialog,
  DialogClose,
  DialogHeader,
  DialogContent,
  DialogButtons,
  Button,
  Select,
  FormControl,
  FormControlMessage,
  TextInput,
} from '@project/components'
import { requiredValidator } from '@project/validation'
import { createOrder } from 'api/requests'
import { useCustomers, useOrders } from 'api'

export const CreateOrderPopup = ({ onClose }) => {
  const { t } = useTranslation()

  const [customers, loadingCustomers] = useCustomers()
  const refreshOrders = useOrders()[4]

  const [{ loading }, onSubmit] = useAsyncFn(
    async fields => {
      const { customerId, description } = fields
      try {
        await createOrder(customerId, description)
        await new Promise(resolve => setTimeout(resolve, 2000))
        await refreshOrders(true, true)
        onClose()
      } catch (error) {
        // do nothing
      }
    },
    [refreshOrders],
  )

  return (
    <Dialog loading={loading || loadingCustomers}>
      <DialogClose onClose={onClose} />
      <DialogHeader>{t('dialog.title.createOrder')}</DialogHeader>
      <DialogContent>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormControl>
                <Field
                  name="customerId"
                  validate={requiredValidator}
                  render={({ input, meta }) => (
                    <>
                      <Select
                        label={t('common.customer')}
                        options={customers?.map(({ customerId, name }) => ({ value: customerId, label: name }))}
                        {...input}
                      />
                      {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                    </>
                  )}
                />
              </FormControl>
              <FormControl>
                <Field
                  name="description"
                  validate={requiredValidator}
                  render={({ input, meta }) => (
                    <>
                      <TextInput label={t('common.description')} {...input} />
                      {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                    </>
                  )}
                />
              </FormControl>
              <DialogButtons>
                <Button onClick={onClose} disabled={loading} transparent>
                  {t('common.cancel')}
                </Button>
                <Button type="submit" disabled={loading}>
                  {t('common.ok')}
                </Button>
              </DialogButtons>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  )
}
