import i18next from 'i18next'
import * as wkt from 'wellknown'

export const requiredValidator = value => (value !== undefined ? undefined : i18next.t('validation:required'))

export const emailValidator = value => (/\S+@\S+\.\S+/.test(value) ? undefined : i18next.t('validation:emailFormat'))

export const passwordValidator = value =>
  /^(?=.*?[a-z])(?=.*[A-Z]).{6,}$/.test(value) ? undefined : i18next.t('validation:passwordFormat')

export const naturalNumberValidator = value =>
  /^([1-9]\d*)$/.test(value) ? undefined : i18next.t('validation:naturalNumber')

export const lengthRangeValidator = (min, max) => value =>
  value && value.length >= min && value.length <= max ? undefined : i18next.t('validation:lengthRange', { min, max })

export const exactLengthValidator = n => value =>
  value && value.length === n ? undefined : i18next.t('validation:exactLength', { n })

export const wktStringValidator = value => {
  try {
    const geometry = wkt.parse(value)
    const { type, coordinates } = geometry

    if (type !== 'MultiPolygon') throw new Error()

    for (let i = 0; i < coordinates.length; i++) {
      for (let j = 0; j < coordinates[i].length; j++) {
        for (let k = 0; k < coordinates[i][j].length; k++) {
          const [lng, lat] = coordinates[i][j][k]
          if (lng < -180 || lng > 180 || lat < -90 || lat > 90) throw new Error()
        }
      }
    }

    return undefined
  } catch (e) {
    return i18next.t('validation:multiPolygonInWKTFormat')
  }
}
