import React from 'react'
import { pushNotification } from '@project/notifications'
import style from './CopyToClipboard.module.scss'

export const CopyToClipboard = ({ children, textToCopy }) => {
  if (textToCopy === undefined) return children

  return (
    <div className={style['container']}>
      <div className={style['main']}>{children}</div>
      <div
        onClick={() => {
          navigator.clipboard.writeText(textToCopy)
          pushNotification({ message: 'Copied to clipboard!', timeout: 1500 })
        }}
        className={style['icon']}
      >
        <i className="far fa-clone" />
      </div>
    </div>
  )
}
