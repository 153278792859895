export const distanceBetweenPoints = (pointA, pointB) => {
  const [x1, y1] = pointA
  const [x2, y2] = pointB
  return ((x1 - x2) ** 2 + (y1 - y2) ** 2) ** 0.5
}

export const isPointOnLine = (lineStart, lineEnd, point) => {
  const d1 = distanceBetweenPoints(lineStart, point)
  const d2 = distanceBetweenPoints(lineEnd, point)
  const d = distanceBetweenPoints(lineStart, lineEnd)
  return Math.abs(d1 + d2 - d) < 1e-6
}

export const getVertexHash = ({ coordinate: [lat, lng] }) => `${lat.toFixed(6)}-${lng.toFixed(6)}`

export const coordsAreEq = (coordinate1, coordinate2) => {
  const [lat1, lng1] = coordinate1
  const [lat2, lng2] = coordinate2
  return lat1.toFixed(6) === lat2.toFixed(6) && lng1.toFixed(6) === lng2.toFixed(6)
}

export const pointToCoordinate = (point, map) => {
  const [x, y] = point
  const { lat, lng } = map.unproject({ x, y })
  return [lng, lat]
}

export const pushLastPoint = coordinates => {
  const first = coordinates[0]
  const last = coordinates[coordinates.length - 1]
  if (first[0] !== last[0] || first[1] !== last[1]) {
    coordinates.push([...first])
  }
}

export const clearCoords = coordinates =>
  coordinates.filter((coordinate, index) => {
    if (index === 0) return true
    const [lat, lng] = coordinate
    const [prevLat, prevLng] = coordinates[index - 1]
    return lat !== prevLat || lng !== prevLng
  })

export const coordinatesFromLatLngToContainerPoint = (coordinates, map) =>
  coordinates.map(coordinate => {
    const { x, y } = map.project(coordinate)
    return [x, y]
  })

export const polygonFromLatLngToContainerPoint = (polygon, map) => {
  const rings = polygon.geometry.coordinates.map(ring => coordinatesFromLatLngToContainerPoint(ring, map))
  return { ...polygon, geometry: { ...polygon.geometry, coordinates: rings } }
}

export const getPolygonPath = polygon => {
  const rings = polygon.geometry.coordinates
  let result = ''
  for (let i = 0; i < rings.length; i++) {
    result += 'M '
    for (let j = 0; j < rings[i].length; j++) {
      const [x, y] = rings[i][j]
      result += `${x} ${y} `
    }
    result += 'z '
  }
  return result
}
