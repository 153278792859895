import React, { createContext, useState, useEffect, useContext } from 'react'

class OrderRegulator {
  constructor(map) {
    this.map = map
    this.orderData = []
  }

  addLayer(layerId, zIndex) {
    this.orderData.push({ layerId, zIndex })
    this.orderData.sort((a, b) => a.zIndex - b.zIndex)
  }

  removeLayer(layerId) {
    this.orderData = this.orderData.filter(item => item.layerId !== layerId)
  }

  orderLayers() {
    const order = this.orderData.map(item => item.layerId)
    this.map.style['_order'] = order
  }
}

const LayerOrderRegulatorContext = createContext({})

export const useLayerOrderRegulator = (layerId, zIndex) => {
  const regulator = useContext(LayerOrderRegulatorContext)

  useEffect(() => {
    regulator.addLayer(layerId, zIndex)

    return () => {
      regulator.removeLayer(layerId)
    }
  }, [layerId, zIndex, regulator])

  return regulator
}

export const LayerOrderRegulatorProvider = ({ map, children }) => {
  const orderRegulator = useState(new OrderRegulator(map))[0]

  return <LayerOrderRegulatorContext.Provider value={orderRegulator}>{children}</LayerOrderRegulatorContext.Provider>
}
