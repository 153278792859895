import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import { Form, Field } from 'react-final-form'
import moment from 'moment'
import {
  Dialog,
  DialogClose,
  DialogHeader,
  DialogContent,
  DialogButtons,
  Button,
  Select,
  FormControl,
  FormControlMessage,
  TextInput,
  TextArea,
  DatePicker,
} from '@project/components'
import { requiredValidator } from '@project/validation'
import { updateProject } from 'api/requests'
import { useProject, useOrders } from 'api'

const scansNumberValidator = (value, t) => {
  if (value === undefined) return undefined
  return /^([0-9]\d*)$/.test(value) ? undefined : t('validation.scansNumber')
}

export const EditProjectPopup = ({ onClose }) => {
  const { t } = useTranslation()
  const { projectId } = useParams()

  const [project, loadingProject, , , refreshProjects] = useProject(projectId)
  const [orders, loadingOrders] = useOrders()

  const [{ loading }, onSubmit] = useAsyncFn(
    async fields => {
      const { crs, firstScanDate, orderNumber, plannedUavScans, plannedBackpackScans, notes } = fields
      try {
        await updateProject(projectId, crs, firstScanDate, orderNumber, plannedUavScans, plannedBackpackScans, notes)
        await new Promise(resolve => setTimeout(resolve, 2000))
        await refreshProjects(true, true)
        onClose()
      } catch (error) {
        // do nothing
      }
    },
    [projectId, refreshProjects],
  )

  return (
    <Dialog loading={loading || loadingProject || loadingOrders}>
      <DialogClose onClose={onClose} />
      <DialogHeader>{t('dialog.title.updateProject')}</DialogHeader>
      <DialogContent>
        {project && orders && (
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormControl>
                  <Field
                    name="orderNumber"
                    initialValue={project.order?.orderNumber}
                    render={({ input, meta }) => (
                      <>
                        <Select
                          label={t('common.orderNumber')}
                          options={
                            orders
                              ? orders
                                  .sort((a, b) => a.orderNumber - b.orderNumber)
                                  .map(o => ({
                                    label: `${o.orderNumber} - ${o.description}`,
                                    value: o.orderNumber,
                                  }))
                              : []
                          }
                          {...input}
                        />
                        {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                      </>
                    )}
                  />
                </FormControl>
                <FormControl>
                  <Field
                    name="firstScanDate"
                    initialValue={project.firstScanDate.split(' ')[0]}
                    validate={requiredValidator}
                    render={({ input, meta }) => (
                      <>
                        <DatePicker
                          selected={input.value ? moment(input.value, 'YYYY-MM-DD').toDate() : null}
                          onChange={d => input.onChange(d ? moment(d).format('YYYY-MM-DD') : '')}
                          dateFormat="yyyy-MM-dd"
                          customInput={<TextInput label={t('common.firstScanDate')} {...input} />}
                          dropdownMode="select"
                          showMonthDropdown
                          showYearDropdown
                        />
                        {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                      </>
                    )}
                  />
                </FormControl>
                <FormControl>
                  <Field
                    name="crs"
                    initialValue={project.crs}
                    validate={requiredValidator}
                    render={({ input, meta }) => (
                      <>
                        <TextInput label={t('common.crs')} {...input} />
                        {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                      </>
                    )}
                  />
                </FormControl>
                <FormControl>
                  <Field
                    name="plannedUavScans"
                    initialValue={!isNaN(project.plannedUavScans) ? project.plannedUavScans : undefined}
                    validate={v => scansNumberValidator(v, t)}
                    render={({ input, meta }) => (
                      <>
                        <TextInput label={t('common.plannedUavScans')} {...input} />
                        {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                      </>
                    )}
                  />
                </FormControl>
                <FormControl>
                  <Field
                    name="plannedBackpackScans"
                    initialValue={!isNaN(project.plannedBackpackScans) ? project.plannedBackpackScans : undefined}
                    validate={v => scansNumberValidator(v, t)}
                    render={({ input, meta }) => (
                      <>
                        <TextInput label={t('common.plannedBackpackScans')} {...input} />
                        {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                      </>
                    )}
                  />
                </FormControl>
                <FormControl>
                  <Field
                    name="notes"
                    initialValue={project.notes}
                    render={({ input }) => <TextArea label={t('common.notes')} {...input} />}
                  />
                </FormControl>
                <DialogButtons>
                  <Button onClick={onClose} disabled={loading} transparent>
                    {t('common.cancel')}
                  </Button>
                  <Button type="save" disabled={loading}>
                    {t('common.ok')}
                  </Button>
                </DialogButtons>
              </form>
            )}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}
