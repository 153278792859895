import React from 'react'
import classnames from 'classnames'
import style from './LegendItem.module.scss'

export const LegendItem = ({ title, text, color, image, className }) => (
  <div className={classnames(style['container'], className)}>
    <div className={style['rect']} style={image ? { backgroundImage: image } : { background: color }} />
    <div className={style['info']}>
      {title && <div className={style['title']}>{title}</div>}
      {text && <div className={style['text']}>{text}</div>}
    </div>
  </div>
)
