import { useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { usePipelines } from 'api'

export const StatusAutoUpdate = () => {
  const { projectId } = useParams()
  const [pipelines, , , , refreshPipelines] = usePipelines(projectId)

  const processing = useMemo(() => {
    if (!pipelines) return false
    const { backpack, project, uav } = pipelines
    const flat = [...project, ...backpack.map(item => item.pipelines), ...uav.map(item => item.pipelines)].flat()
    return flat.map(item => item.status).includes('processing')
  }, [pipelines])

  useEffect(() => {
    let intervalId = null
    if (processing) {
      intervalId = setInterval(() => refreshPipelines(true, true), 20000)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [processing, refreshPipelines])

  return null
}
