export const FERTILITY_CLASS = Object.freeze({
  GROVE: 4001,
  GROVY: 4002,
  MESIC: 4003,
  DRYISH: 4004,
  DRY: 4005,
  BARREN: 4006,
  ROCKY: 4007,
  FELL: 4008,
  UNKNOWN: 4009,
})

export const fertilityClassColorSchemeIds = {
  [FERTILITY_CLASS.GROVE]: 'legend-ont-1',
  [FERTILITY_CLASS.GROVY]: 'legend-ont-2',
  [FERTILITY_CLASS.MESIC]: 'legend-ont-3',
  [FERTILITY_CLASS.DRYISH]: 'legend-ont-4',
  [FERTILITY_CLASS.DRY]: 'legend-ont-5',
  [FERTILITY_CLASS.BARREN]: 'legend-ont-6',
  [FERTILITY_CLASS.ROCKY]: 'legend-ont-7',
  [FERTILITY_CLASS.FELL]: 'legend-ont-8',
  [FERTILITY_CLASS.UNKNOWN]: 'legend-default-1',
}
