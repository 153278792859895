import React, { useMemo } from 'react'
import { Spinner } from '@project/components'
import { useProjects, useParcels } from 'api'
import { ProjectsTable } from '../../../../../../common/ProjectsTable'

export const OrderProjectsTable = ({ orderId }) => {
  const [projects] = useProjects()
  const [parcels] = useParcels()

  const data = useMemo(() => {
    if (!projects || !parcels) return null

    return projects
      .filter(project => project.order?.orderId === orderId)
      .map(project => {
        const parcel = parcels.find(p => p.parcelNumber === project.parcelNumber)
        return { ...project, parcel }
      })
  }, [projects, parcels, orderId])

  if (!data) return <Spinner />

  return <ProjectsTable data={data} detailed={false} />
}
