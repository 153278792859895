import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useQueryParams } from '@project/routing'
import { MODES } from '../../utils/modes'
import style from './ShortcutMessages.module.scss'

export const ShortcutMessages = () => {
  const { t } = useTranslation()
  const [{ geohash }] = useQueryParams()
  const editorEnabled = useSelector(state => state.editor.editorEnabled)
  const mode = useSelector(state => state.editor.mode)

  return (
    <div className={style['container']}>
      {!geohash && <Message text={t('editor.messages.chooseMapTile')} />}
      {geohash && !editorEnabled && mode !== MODES.GEOHASH && <Message text={t('editor.messages.zoomLevelIsLow')} />}
      {editorEnabled && mode === MODES.ADD && <Message text={t('editor.messages.clickToAddTree')} />}
      {editorEnabled && mode === MODES.REMOVE && <Message text={t('editor.messages.clickToRemoveTree')} />}
      {mode === MODES.GEOHASH && <Message text={t('editor.messages.clickToChangeGeohashStatus')} />}
    </div>
  )
}

const Message = ({ text }) => <div className={style['message']}>{text}</div>
