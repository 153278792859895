import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import { Form, Field } from 'react-final-form'
import {
  Dialog,
  DialogClose,
  DialogHeader,
  DialogContent,
  DialogButtons,
  Button,
  Select,
  FormControl,
  FormControlMessage,
  TextInput,
} from '@project/components'
import { requiredValidator } from '@project/validation'
import { updateOrder } from 'api/requests'
import { useOrder, useCustomers } from 'api'
import { ORDER_STATUS } from 'utils/orderStatus'

export const EditOrderPopup = ({ onClose }) => {
  const { t } = useTranslation()
  const { orderId } = useParams()

  const [order, loadingOrder, , , refreshOrders] = useOrder(orderId)
  const [customers, loadingCustomers] = useCustomers()

  const [{ loading }, onSubmit] = useAsyncFn(
    async fields => {
      const { customerId, status, description } = fields
      try {
        await updateOrder(orderId, customerId, status, description)
        await new Promise(resolve => setTimeout(resolve, 2000))
        await refreshOrders(true, true)
        onClose()
      } catch (error) {
        // do nothing
      }
    },
    [refreshOrders],
  )

  return (
    <Dialog loading={loading || loadingOrder || loadingCustomers}>
      <DialogClose onClose={onClose} />
      <DialogHeader>{t('dialog.title.updateOrder')}</DialogHeader>
      <DialogContent>
        {order && (
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormControl>
                  <Field
                    name="customerId"
                    initialValue={order.customerId}
                    validate={requiredValidator}
                    render={({ input, meta }) => (
                      <>
                        <Select
                          label={t('common.customer')}
                          options={customers?.map(({ customerId, name }) => ({ value: customerId, label: name }))}
                          {...input}
                        />
                        {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                      </>
                    )}
                  />
                </FormControl>
                <FormControl>
                  <Field
                    name="status"
                    initialValue={order.status}
                    validate={requiredValidator}
                    render={({ input, meta }) => (
                      <>
                        <Select
                          label={t('common.status')}
                          options={Object.values(ORDER_STATUS).map(value => ({ value, label: value }))}
                          {...input}
                        />
                        {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                      </>
                    )}
                  />
                </FormControl>
                <FormControl>
                  <Field
                    name="description"
                    validate={requiredValidator}
                    initialValue={order.description}
                    render={({ input, meta }) => (
                      <>
                        <TextInput label={t('common.description')} {...input} />
                        {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                      </>
                    )}
                  />
                </FormControl>
                <DialogButtons>
                  <Button onClick={onClose} disabled={loading} transparent>
                    {t('common.cancel')}
                  </Button>
                  <Button type="save" disabled={loading}>
                    {t('common.ok')}
                  </Button>
                </DialogButtons>
              </form>
            )}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}
