import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useAsyncFn } from 'react-use'
import { useProject } from 'api'
import { createParcelKmlDownload, createBackpackPlannerDownload } from 'api/requests'
import { MobileHorizontalScroll, Spinner } from '@project/components'
import { TableIcon } from '../../../../common/TableIcon'
import style from './PipelinesView.module.scss'

export const PipelinesView = ({ dashboard, map }) => {
  const { t } = useTranslation()
  const { projectId } = useParams()
  const [{ propertyId, parcelNumber }] = useProject(projectId)

  const [statusA, downloadParcelKmlFn] = useAsyncFn(async () => {
    try {
      const { url } = await createParcelKmlDownload(propertyId, parcelNumber)
      const link = document.createElement('a')
      link.href = url
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (e) {
      // TODO
    }
  }, [propertyId, parcelNumber])

  const [statusB, downloadBackpackPlannerFn] = useAsyncFn(async () => {
    try {
      const { success } = await createBackpackPlannerDownload(projectId)
    } catch (e) {
      // TODO
    }
  }, [projectId])

  if (statusA.loading || statusB.loading) return <Spinner />

  return (
    <div className={style['container']}>
      <div className={style['dashboard']}>
        <MobileHorizontalScroll breakpoint={730} minWidth={730} maxHeight="10000px">
          {dashboard}
        </MobileHorizontalScroll>
      </div>
      <div className={style['map']}>
        <div className={style['map-content']}>{map}</div>
        <div className={style['download-block']}>
          {t('common.downloadParcelKml')}&nbsp;
          <TableIcon
            iconTitle={t('common.downloadParcelKml')}
            iconClassName="fa fa-cloud-download-alt"
            onClick={() => downloadParcelKmlFn()}
          />
        </div>
        <div className={style['download-block']}>
          {t('common.downloadBackpackPlanner')}&nbsp;
          <TableIcon
            iconTitle={t('common.downloadBackpackPlanner')}
            iconClassName="fa fa-cloud-download-alt"
            onClick={() => downloadBackpackPlannerFn()}
          />
        </div>
      </div>
    </div>
  )
}
