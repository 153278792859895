const generateImageData = (width, height, lineWidth) => {
  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height
  const context = canvas.getContext('2d')
  context.lineWidth = lineWidth
  context.strokeStyle = 'rgba(0, 0, 0, 0.4)'
  context.beginPath()
  context.moveTo(0, 0)
  context.lineTo(width, height)
  context.stroke()
  const imageData = context.getImageData(0, 0, width, height)
  const dataURL = canvas.toDataURL()
  return { imageData, dataURL }
}

export const fillPatterns = [{ id: 'fill-pattern-1', ...generateImageData(4, 4, 1) }]
