export const CUTTING = Object.freeze({
  REST: 20000,
  UPPER_TREE_REMOVAL: 20001,
  FIRST_THINNING: 20002,
  THINNING: 20003,
  STRIP_CUT: 20004,
  CLEAR_CUT: 20005,
  SHELTERWOOD_CUT: 20007,
  SEED_TREE_CUT: 20008,
  UPPER_THINNING: 20011,
  PATCH_CUT: 20015,
  //
  UNDERSTOREY_CLEARING: 21450,
  TENDING_OF_SAPLING_STAND: 21740,
  THINNING_OF_SAPLING_STAND: 21890,
  //
  REGENERATION_NATURAL_PINE: 22101,
  REGENERATION_NATURAL_SPRUCE: 22102,
  REGENERATION_NATURAL_BENDULA: 22103,
  REGENERATION_NATURAL_PUBESCENS: 22104,
  //
  REGENERATION_INSEMINATION_PINE: 22201,
  REGENERATION_INSEMINATION_SPRUCE: 22202,
  REGENERATION_INSEMINATION_BENDULA: 22203,
  REGENERATION_INSEMINATION_PUBESCENS: 22204,
  //
  REGENERATION_PLANTING_PINE: 22301,
  REGENERATION_PLANTING_SPRUCE: 22302,
  REGENERATION_PLANTING_BENDULA: 22303,
  REGENERATION_PLANTING_PUBESCENS: 22304,
  //
  SOIL_PREPARATION_NOT_NEEDED: 23500,
  SOIL_PREPARATION_GENERAL: 23501,
  SOIL_PREPARATION_SCALPING: 23510,
  SOIL_PREPARATION_MOUNDING: 23520,
  SOIL_PREPARATION_HARROWING: 23530,
  SOIL_PREPARATION_PLOUGHING: 23540,
})

export const cuttingColorSchemeIds = {
  [CUTTING.REST]: 'legend-gray-7',
  [CUTTING.UPPER_TREE_REMOVAL]: 'legend-action-1',
  [CUTTING.FIRST_THINNING]: 'legend-action-7',
  [CUTTING.THINNING]: 'legend-action-4',
  [CUTTING.STRIP_CUT]: 'legend-action-8',
  [CUTTING.CLEAR_CUT]: 'legend-action-9',
  [CUTTING.SHELTERWOOD_CUT]: 'legend-action-11',
  [CUTTING.SEED_TREE_CUT]: 'legend-action-12',
  [CUTTING.UPPER_THINNING]: 'legend-action-3',
  [CUTTING.PATCH_CUT]: 'legend-action-10',
  //
  [CUTTING.UNDERSTOREY_CLEARING]: 'legend-gray-8',
  [CUTTING.TENDING_OF_SAPLING_STAND]: 'legend-action-2',
  [CUTTING.THINNING_OF_SAPLING_STAND]: 'legend-action-6',
  //
  [CUTTING.REGENERATION_NATURAL_PINE]: 'legend-ont-1',
  [CUTTING.REGENERATION_NATURAL_SPRUCE]: 'legend-ont-2',
  [CUTTING.REGENERATION_NATURAL_BENDULA]: 'legend-ont-3',
  [CUTTING.REGENERATION_NATURAL_PUBESCENS]: 'legend-ont-4',
  //
  [CUTTING.REGENERATION_INSEMINATION_PINE]: 'legend-ont-5',
  [CUTTING.REGENERATION_INSEMINATION_SPRUCE]: 'legend-ont-6',
  [CUTTING.REGENERATION_INSEMINATION_BENDULA]: 'legend-ont-7',
  [CUTTING.REGENERATION_INSEMINATION_PUBESCENS]: 'legend-ont-8',
  //
  [CUTTING.REGENERATION_PLANTING_PINE]: 'legend-ont-9',
  [CUTTING.REGENERATION_PLANTING_SPRUCE]: 'legend-ont-10',
  [CUTTING.REGENERATION_PLANTING_BENDULA]: 'legend-ont-11',
  [CUTTING.REGENERATION_PLANTING_PUBESCENS]: 'legend-ont-12',
  //
  [CUTTING.SOIL_PREPARATION_NOT_NEEDED]: 'legend-ont-13',
  [CUTTING.SOIL_PREPARATION_GENERAL]: 'legend-ont-13',
  [CUTTING.SOIL_PREPARATION_SCALPING]: 'legend-ont-13',
  [CUTTING.SOIL_PREPARATION_MOUNDING]: 'legend-ont-13',
  [CUTTING.SOIL_PREPARATION_HARROWING]: 'legend-ont-13',
  [CUTTING.SOIL_PREPARATION_PLOUGHING]: 'legend-ont-13',
}
