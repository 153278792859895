import React from 'react'
import { ProcessedGeohashes } from './partials/ProcessedGeohashes'
import { SkippedGeohashes } from './partials/SkippedGeohashes'

export const GeohashStatusSubstrate = ({ zIndex }) => (
  <>
    <SkippedGeohashes zIndex={zIndex} />
    <ProcessedGeohashes zIndex={zIndex + 0.1} />
  </>
)
