const TRUNK_BLOCK_SIZE = 13

export const parseTrunks = arrayBuffer => {
  const dataView = new DataView(arrayBuffer)
  const numberOfTrunks = arrayBuffer.byteLength / TRUNK_BLOCK_SIZE
  const result = []
  for (let i = 0; i < numberOfTrunks; i++) {
    const offset = TRUNK_BLOCK_SIZE * i
    const lat = dataView.getFloat32(offset)
    const lng = dataView.getFloat32(offset + 4)
    const height = dataView.getUint16(offset + 8) / 10
    const species = dataView.getUint8(offset + 10)
    const value = dataView.getUint8(offset + 11)
    const dbh = dataView.getUint8(offset + 12) / 100
    result.push({ lat, lng, height, species, value, dbh })
  }
  return result
}
