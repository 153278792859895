import React from 'react'
import style from './Checkbox.module.scss'

export const Checkbox = ({ label, ...inputProps }) => (
  <div className={style['checkbox']}>
    <label className={style['input-wrapper']}>
      <input {...inputProps} className={style['input']} type="checkbox" />
      <div className={style['box']} />
    </label>
    {label && <div className={style['label']}>{label}</div>}
  </div>
)
