import { uid } from 'react-uid'
import { useDataLoader } from '@project/api'
import { useProject } from './useProject'
import { getTreeCorrectionTiles } from '../../requests'

const hookId = uid({})

export const useTreeCorrectionTiles = projectId => {
  const [project] = useProject(projectId)
  const jobId = project?.jobId
  return useDataLoader(hookId, getTreeCorrectionTiles, [jobId])
}
