export default {
  required: 'This field is required',
  emailFormat: 'Wrong email pattern',
  passwordFormat:
    'Password must have length greater than or equal to 6 and contain at least one uppercase and lowercase letter',
  naturalNumber: 'Value should be a positive number without fractional component',
  lengthRange: 'Value length should be in range of {{min}}-{{max}} characters',
  exactLength: 'Value length should be exactly {{n}} characters',
  multiPolygonInWKTFormat: 'Value should be a MultiPolygon in WKT format',
}
