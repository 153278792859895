import React from 'react'
import classnames from 'classnames'
import { TYPE } from '../../../../utils/type'
import style from './Notification.module.scss'

const getConfig = type => {
  if (type === TYPE.SUCCESS) return { color: '#358c5e', iconClassName: 'fa fa-circle-check' }
  if (type === TYPE.ERROR) return { color: '#d80404', iconClassName: 'fa fa-circle-exclamation' }
  return null
}

export const Notification = ({ type, message, onRemoveBtnClick }) => {
  const { color, iconClassName } = getConfig(type) || {}
  return (
    <div className={style['notification']} style={{ borderColor: color }}>
      <i className={classnames(style['icon'], iconClassName)} style={{ color }} />
      <span className={style['message']}>{message}</span>
      <i className={classnames(style['remove-icon'], 'fa fa-times')} onClick={onRemoveBtnClick} />
    </div>
  )
}
