import React from 'react'
import { useAsyncFn } from 'react-use'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { createTreesDownload } from 'api/requests'
import { TableIcon } from '../../../../../../../../../../common/TableIcon'

export const TreesDownload = ({ status, agent, algorithmPipelineJobId }) => {
  const { t } = useTranslation()
  const { projectId } = useParams()

  const [{ loading }, downloadTreesFn] = useAsyncFn(
    async source => {
      try {
        const { url } = await createTreesDownload(projectId, algorithmPipelineJobId, source.toUpperCase())
        const link = document.createElement('a')
        link.href = url
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (e) {
        // TODO
      }
    },
    [projectId, algorithmPipelineJobId],
  )

  return (
    <>
      {status === 'success' &&
        (agent === 'algorithm_pipeline' || agent === 'forestry_local_pipeline') &&
        (loading ? (
          <i className="fa fa-circle-notch fa-spin" />
        ) : (
          <TableIcon
            iconTitle={t('common.downloadTrees')}
            iconClassName="fa fa-cloud-download-alt"
            onClick={() => downloadTreesFn(agent === 'forestry_local_pipeline' ? 'local_pipeline' : agent)}
          />
        ))}
    </>
  )
}
