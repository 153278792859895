export const SOIL_TYPE = Object.freeze({
  COARSE_GRAINED_MINERAL_SOIL: 5010,
  COARSE_GRAINED_MORAINE: 5011,
  COARSE_GRAINED_SORTED_SOIL: 5012,
  FINE_GRAINED_MINERAL_SOIL: 5020,
  FINE_GRAINED_MORAINE: 5021,
  FINE_GRAINED_SORTED_SOIL: 5022,
  STONY_COARSE_GRAINED_MINERAL_SOIL: 5030,
  STONY_COARSE_GRAINED_MORAINE: 5031,
  STONY_COARSE_GRAINED_SORTED_SOIL: 5032,
  STONY_FINE_GRAINED_MINERAL_SOIL: 5040,
  ROCK_OR_ROCK_FIELD: 5050,
  PEAT: 5060,
  CAREX_PEAT: 5061,
  SPHAGNUM_PEAT: 5062,
  WOODY_PEAT: 5063,
  CAREX_PEAT_VON_POST_OVER5: 5064,
  SPHAGNUM_PEAT_VON_POST_OVER5: 5065,
  CAREX_PEAT_VON_POST_UNDER5: 5066,
  SPHAGNUM_PEAT_VON_POST_UNDER5: 5067,
  MOULD: 5070,
  SLUDGE: 5080,
})

export const soilTypeColorSchemeIds = Object.keys(SOIL_TYPE).reduce((acc, key, index) => {
  const id = SOIL_TYPE[key]
  acc[id] = `legend-ont-${index + 1}`
  return acc
}, {})
