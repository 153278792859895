import React from 'react'
import classnames from 'classnames'
import { NavLink } from 'react-router-dom'
import './Tab.scss'

export const Tab = ({ to, label, secondary, disabled, replace }) => (
  <NavLink
    onClick={e => {
      if (disabled) e.preventDefault()
    }}
    className={({ isActive }) =>
      classnames('tab', { 'tab--secondary': secondary, 'tab--disabled': disabled, 'tab--active': isActive })
    }
    to={to}
    replace={replace}
  >
    {label}
  </NavLink>
)
