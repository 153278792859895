import React, { useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { Button } from '@project/components'
import { usePipelines } from 'api'
import { PIPELINE_STATUS } from 'utils/pipelineStatus'
import style from './PipelineControl.module.scss'
import { CreateJobPopup } from './partials/CreateJobPopup'
import { PipelineStatus } from './partials/PipelineStatus'

export const PipelineControl = ({ pipeline }) => {
  const { t } = useTranslation()
  const { projectId } = useParams()
  const [pipelines] = usePipelines(projectId)
  const [showPopup, setShowPopup] = useState(false)

  const { agent, mutation, label, jobs, status, parallel } = pipeline

  const algorithmPipelineJobId = useMemo(() => {
    if (agent !== 'forestry_local_pipeline') return null
    const algorithmPipeline = pipelines?.project?.find(p => p.agent === 'algorithm_pipeline')
    const algorithmPipelineJob = algorithmPipeline?.jobs?.find(j => j.status === 'aws_batch.status.success')
    return algorithmPipelineJob?.jobId || null
  }, [agent, pipelines])

  // TODO: disable start button for blocked pipeline
  // const { BLOCKED, PROCESSING, PROCESSED } = PIPELINE_STATUS
  // const canStart = status !== BLOCKED && status !== PROCESSING
  const { PROCESSING, PROCESSED } = PIPELINE_STATUS
  const canStart = parallel || status !== PROCESSING
  const processed = status === PROCESSED

  return (
    <>
      <div className={style['container']}>
        <div className={style['agent']}>
          <div className={style['label']}>{t('common.agent')}</div>
          <div className={style['value']}>{label}</div>
        </div>
        <div className={style['date']}>
          <div className={style['label']}>{t('common.startDate')}</div>
          <div className={style['value']}>{jobs?.[0]?.created?.slice(0, -3) || '—'}</div>
        </div>
        <div className={style['status']}>
          <div className={style['label']}>{t('common.status')}</div>
          <div className={style['value']}>{status ? <PipelineStatus status={status} /> : '—'}</div>
        </div>
        {pipeline.mutation && (
          <div
            className={classnames({
              [style['button']]: true,
              [style['button-orange']]: processed,
            })}
          >
            <Button
              onClick={() => setShowPopup(true)}
              disabled={!canStart || (agent === 'forestry_local_pipeline' && !algorithmPipelineJobId)}
            >
              {processed ? t('common.restart') : t('common.start')}
            </Button>
          </div>
        )}
      </div>
      {showPopup && <CreateJobPopup mutation={mutation} onClose={() => setShowPopup(false)} />}
    </>
  )
}
