import React, { useState, useRef, useLayoutEffect, useCallback } from 'react'
import classnames from 'classnames'
import style from './VirtualForestView.module.scss'

const dispatchResizeEvent = () => {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, false)
  document.dispatchEvent(event)
}

export const VirtualForestView = ({ content, aside, fullscreenClassName }) => {
  const panelRef = useRef()
  const triggerRef = useRef()
  const asideRef = useRef()
  const [asideWidth, setAsideWidth] = useState(450)
  const [dragging, setDragging] = useState(false)
  const [fullscreen, setFullscreen] = useState(false)

  const onMouseMove = useCallback(e => {
    const { left: panelLeft, width: panelWidth } = panelRef.current.getBoundingClientRect()
    let width = panelWidth - (e.clientX - panelLeft) - 15
    width = width < 200 ? 200 : width
    width = width > panelWidth - 400 ? panelWidth - 400 : width
    triggerRef.current.style.right = `${width}px`
    asideRef.current.style.flex = `0 0 ${width}px`
  }, [])

  const onMouseDown = useCallback(() => {
    document.body.removeEventListener('mousemove', onMouseMove)
    document.body.addEventListener('mousemove', onMouseMove, false)
    setDragging(true)
  }, [onMouseMove])

  const onMouseUp = useCallback(
    e => {
      document.body.removeEventListener('mousemove', onMouseMove)
      const { left: panelLeft, width: panelWidth } = panelRef.current.getBoundingClientRect()
      const width = panelWidth - (e.clientX - panelLeft) - 15
      setAsideWidth(width)
      setDragging(false)
    },
    [onMouseMove],
  )

  useLayoutEffect(dispatchResizeEvent, [dragging, fullscreen])

  return (
    <div
      ref={panelRef}
      className={classnames({
        [style['container']]: true,
        [style['container--fullscreen']]: fullscreen,
        [fullscreenClassName]: fullscreen,
      })}
    >
      <div className={style['content']}>
        {content}
        <div className={style['fullscreen-toggle']} onClick={() => setFullscreen(!fullscreen)}>
          <i className={classnames('fa', fullscreen ? 'fa-window-minimize' : 'fa-expand-arrows-alt')} />
        </div>
      </div>
      <div className={style['spacer']} />
      <div ref={asideRef} className={style['aside']} style={{ width: asideWidth }}>
        {aside}
      </div>
      <div
        ref={triggerRef}
        className={style['trigger']}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        style={{ right: asideWidth }}
      >
        <i className="fa fa-arrows-alt-h" />
      </div>
    </div>
  )
}
