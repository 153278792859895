import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CopyToClipboard } from '@project/components'
import { useProject } from 'api'
import style from './Details.module.scss'

export const Details = () => {
  const { t } = useTranslation()
  const { projectId } = useParams()
  const [project] = useProject(projectId)

  if (!project) return null

  return (
    <div className={style['container']}>
      <div>
        {t('common.parcelNumber')}: {project.parcelNumber}
      </div>
      <div>
        {t('common.firstScanDate')}: {project.firstScanDate}
      </div>
      <div>
        {t('common.projectNumber')}: {project.projectNumber}
      </div>
      <CopyToClipboard textToCopy={project.projectId}>
        <div>
          {t('common.projectId')}: {project.projectId}
        </div>
      </CopyToClipboard>
    </div>
  )
}
