import { useEffect, useState } from 'react'
import * as THREE from 'three'
import { usePaneUtils, useTile } from '../Pointcloud3D'

export const BBox = () => {
  const paneUtils = usePaneUtils()
  const tile = useTile()
  const [box, setBox] = useState(null)

  const { minX, minY, minZ, maxX, maxY, maxZ } = tile?.normalizedBBox || {}

  useEffect(() => {
    const geometry = new THREE.EdgesGeometry(new THREE.BoxGeometry(0, 0, 0))
    const material = new THREE.LineBasicMaterial({ color: 0xcccccc, depthWrite: false })
    const b = new THREE.LineSegments(geometry, material)
    setBox(b)
  }, [])

  useEffect(() => {
    if (box) {
      paneUtils.add(box)
      paneUtils.render()
    }

    return () => {
      paneUtils.remove(box)
      paneUtils.render()
    }
  }, [box, paneUtils])

  useEffect(() => {
    if (box) {
      box.geometry.dispose()
      box.geometry = new THREE.EdgesGeometry(new THREE.BoxGeometry(maxX - minX, maxY - minY, maxZ - minZ))
      box.position.z = (minZ + maxZ) / 2
      paneUtils.render()
    }
  }, [minX, minY, minZ, maxX, maxY, maxZ, box, paneUtils])

  return null
}
