export default {
  tileLayer: {
    baseMap: 'Grundkarte',
    aerialMap: 'NLS Luftaufnahme',
    preforAerialMap: 'PreFor aerial map',
    preforCHMMap: 'PreFor CHM',
  },
  control: {
    showBaseMap: 'Show base map',
  },
}
