import _ from 'lodash'

export const makeHistogramData = bucketData => {
  const data = _(bucketData || [])
    .groupBy('bucket')
    .map(bucketItems => ({
      bucket: bucketItems[0].bucket,
      ..._(bucketItems)
        .groupBy('dataLabel')
        .map((dataLabelItems, dataLabel) => [dataLabel, _.sumBy(dataLabelItems, 'dataValue')])
        .fromPairs()
        .value(),
    }))
    .value()

  const step = data.length >= 2 ? data[1].bucket - data[0].bucket : 0
  return data.map(item => ({ ...item, min: item.bucket, max: item.bucket + step, x: item.bucket + 0.5 * step }))
}
