import React from 'react'
import { useTranslation } from 'react-i18next'
import { CopyToClipboard } from '@project/components'
import { JobId } from './partials/JobId'
import { JobStatus } from './partials/JobStatus'
import { JobDurationAndCost } from './partials/JobDurationAndCost'
import { TreesDownload } from './partials/TreesDownload'
import style from './JobsTable.module.scss'

export const JobsTable = ({ jobs, agent }) => {
  const { t } = useTranslation()

  return (
    <table className={style['table']}>
      <thead>
        <tr>
          <th>{t('common.jobId')}</th>
          <th>{t('common.createDate')}</th>
          <th>{t('common.status')}</th>
          <th>{t('common.duration')}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {jobs.map(job => {
          const { jobId, description, created, reason, duration, cost, params } = job
          const status = job.status.split('.').pop()
          return (
            <tr key={jobId}>
              <td>
                <CopyToClipboard textToCopy={jobId}>
                  <JobId jobId={jobId} description={description} />
                </CopyToClipboard>
              </td>
              <td>{created.slice(0, -3)}</td>
              <td>
                <JobStatus status={status} reason={reason} />
              </td>
              <td>
                <JobDurationAndCost status={status} duration={duration} cost={cost} />
              </td>
              <td>
                <TreesDownload
                  status={status}
                  agent={agent}
                  algorithmPipelineJobId={agent === 'forestry_local_pipeline' ? params.algorithmPipelineJobId : jobId}
                />
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
