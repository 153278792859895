import { useEffect } from 'react'
import { usePaneUtils, useTile } from '../Pointcloud3D'

export const OrbitControls = () => {
  const paneUtils = usePaneUtils()
  const tile = useTile()
  const { minX, minY, minZ, maxX, maxY, maxZ } = tile?.normalizedBBox || {}

  useEffect(() => {
    const { controls, helperControls, group } = paneUtils
    controls.enablePan = true
    helperControls.enableRotate = true
    helperControls.addEventListener('change', () => {
      group.rotation.y = 0.5 * Math.PI - helperControls.getPolarAngle()
      group.rotation.z = Math.PI - helperControls.getAzimuthalAngle()
      paneUtils.render()
    })
  }, [paneUtils])

  useEffect(() => {
    const x = (minX + maxX) / 2
    const y = (minY + maxY) / 2
    const z = (minZ + maxZ) / 2
    const max = Math.max(maxY - minY, maxZ - minZ)
    const { fov } = paneUtils.camera
    const cameraX = maxX + (maxX - minX) / 2 + max / (2 * Math.tan((Math.PI * 0.5 * fov) / 180))
    paneUtils.updateCameraPosition(cameraX, y, z)
    paneUtils.updateCameraLookAt(x, y, z)
    paneUtils.updateControlsTarget(x, y, z)
    paneUtils.render()
  }, [paneUtils, minX, minY, minZ, maxX, maxY, maxZ])

  return null
}
