import auth from '@aws-amplify/auth'
import { tokenHelper } from '../../externalMethods/tokenHelper'

const region = process.env.REACT_APP_COGNITO_AUTH_REGION
const userPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID
const userPoolWebClientId = process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID
const authenticationFlowType = process.env.REACT_APP_COGNITO_AUTH_FLOW_TYPE

auth.configure({ region, userPoolId, userPoolWebClientId, authenticationFlowType })

const SET_USER = '@auth/set-user'
const SET_CREDENTIALS = '@auth/set-credentials'
const SET_LOADING = '@auth/set-loading'
const SET_RESTORING = '@auth/set-restoring'
const SET_ERROR = '@auth/set-error'

const initialState = {
  user: null,
  credentials: null,
  loading: false,
  restoring: true,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER: {
      return { ...state, user: action.user }
    }
    case SET_CREDENTIALS: {
      return { ...state, credentials: action.credentials }
    }
    case SET_LOADING: {
      return { ...state, loading: action.loading }
    }
    case SET_RESTORING: {
      return { ...state, restoring: action.restoring }
    }
    case SET_ERROR: {
      return { ...state, error: action.error }
    }
    default:
      return state
  }
}

const setUser = user => ({ type: SET_USER, user })

const setCredentials = credentials => ({ type: SET_CREDENTIALS, credentials })

const setLoading = loading => ({ type: SET_LOADING, loading })

const setRestoring = restoring => ({ type: SET_RESTORING, restoring })

const setError = error => ({ type: SET_ERROR, error })

export const restore = () => async dispatch => {
  try {
    const user = await auth.currentAuthenticatedUser()
    dispatch(setUser(user))
    await tokenHelper.preWarm()
  } catch (e) {
    // do nothing
  } finally {
    dispatch(setRestoring(false))
  }
}

export const login = (username, password) => async dispatch => {
  dispatch(setLoading(true))
  dispatch(setError(null))
  try {
    const user = await auth.signIn(username, password)
    dispatch(setUser(user))
    await tokenHelper.preWarm()
  } catch (e) {
    dispatch(setError(e))
  } finally {
    dispatch(setLoading(false))
  }
}

export const logout = message => async () => {
  try {
    if (message) localStorage.setItem('AUTH_NOTIFICATION', message)
    await auth.signOut()
    window.location.reload()
  } catch (e) {
    /* do nothing */
  }
}

export const register = (username, password) => async dispatch => {
  dispatch(setLoading(true))
  dispatch(setError(null))
  try {
    await auth.signUp({ username, password })
    const credentials = { username, password }
    dispatch(setCredentials(credentials))
  } catch (e) {
    dispatch(setError(e))
    throw e
  } finally {
    dispatch(setLoading(false))
  }
}

export const verify = (username, code) => async dispatch => {
  dispatch(setLoading(true))
  dispatch(setError(null))
  try {
    await auth.confirmSignUp(username, code)
  } catch (e) {
    dispatch(setError(e))
    throw e
  } finally {
    dispatch(setLoading(false))
  }
}

export const forgotPasswordRequest = username => async dispatch => {
  dispatch(setLoading(true))
  dispatch(setError(null))
  try {
    await auth.forgotPassword(username)
  } catch (e) {
    dispatch(setError(e))
    throw e
  } finally {
    dispatch(setLoading(false))
  }
}

export const forgotPasswordSubmit = (username, code, newPassword) => async dispatch => {
  dispatch(setLoading(true))
  dispatch(setError(null))
  try {
    await auth.forgotPasswordSubmit(username, code, newPassword)
  } catch (e) {
    dispatch(setError(e))
    throw e
  } finally {
    dispatch(setLoading(false))
  }
}

export const changePassword = (username, oldPassword, newPassword) => async (dispatch, getState) => {
  dispatch(setLoading(true))
  dispatch(setError(null))
  try {
    const { user } = getState().auth
    if (user.attributes.email !== username) return
    await auth.changePassword(user, oldPassword, newPassword)
    await auth.signOut({ global: true })
    await auth.signIn(username, newPassword)
    window.location.replace('/')
  } catch (e) {
    dispatch(setError(e))
  } finally {
    dispatch(setLoading(false))
  }
}

export const resetPassword = newPassword => async (dispatch, getState) => {
  dispatch(setLoading(true))
  dispatch(setError(null))
  try {
    const user = await auth.completeNewPassword(getState().auth.user, newPassword)
    dispatch(setUser(user))
  } catch (e) {
    dispatch(setError(e))
    throw e
  } finally {
    dispatch(setLoading(false))
  }
}

export const clearError = () => setError(null)
