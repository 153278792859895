import React from 'react'
import ReactDOM from 'react-dom'
import { Checkbox } from '@project/components'
import { useLayersControlNode } from '../../map/MapGL'
import style from './LayerControl.module.scss'

export const LayerControl = ({ title, visible, setVisible, orderIndex, children }) => {
  const node = useLayersControlNode()

  if (!node) return null

  return ReactDOM.createPortal(
    <div className={style['container']} style={{ order: orderIndex }}>
      <div className={style['header']}>
        <Checkbox checked={visible} onChange={() => setVisible(!visible)} />
        <span className={style['title']}>{title}</span>
      </div>
      {children && <div className={style['content']}>{children}</div>}
    </div>,
    node,
  )
}
