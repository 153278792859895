import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { deleteNotification } from '../../redux/ducks/notifications'
import { Notification } from './partials/Notification'
import style from './Notifications.module.scss'

const animationDuration = 300

export const Notifications = () => {
  const dispatch = useDispatch()
  const items = useSelector(state => state.notifications.items)

  return (
    <div className={style['notifications']}>
      <TransitionGroup component={null}>
        {items
          .slice()
          .reverse()
          .map(({ id, type, message }) => (
            <CSSTransition
              key={id}
              timeout={animationDuration}
              classNames={{
                enter: style['item-container--enter'],
                enterActive: style['item-container--enter-active'],
                exitActive: style['item-container--exit-active'],
              }}
            >
              <div className={style['item-container']}>
                <div className={style['item']}>
                  <Notification
                    type={type}
                    message={message}
                    onRemoveBtnClick={() => dispatch(deleteNotification(id))}
                  />
                </div>
              </div>
            </CSSTransition>
          ))}
      </TransitionGroup>
    </div>
  )
}
