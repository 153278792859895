import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import gh from 'latlon-geohash'
import * as turf from '@turf/turf'
import { GeoJSONSource, CircleLayer } from '@project/mapbox'
import { getEntityColor } from '@project/ontology'
import { useTreeCorrections } from 'api'

const getTrunkCircleRadius = expand => {
  const minR = 2 * Math.pow(1.575, 10 - 22)
  const maxR = 2 * Math.pow(1.575, 24 - 22)
  const heightExp = ['+', ['max', ['number', ['get', 'height']], 8], expand ? 4 : 0]
  return ['interpolate', ['exponential', 1.575], ['zoom'], 5, ['*', minR, heightExp], 24, ['*', maxR, heightExp]]
}

const getTrunkCircleColor = () => ['get', 'color']

export const AddedTreesSubstrate = ({ zIndex }) => {
  const { projectId } = useParams()
  const [treeCorrections] = useTreeCorrections(projectId)

  const omissions = treeCorrections?.omissions
  const commissions = treeCorrections?.commissions

  const geoJSON = useMemo(() => {
    if (!omissions || !commissions) return null
    const removedTreeIds = commissions.map(item => item.geohash)
    const features = omissions
      .map(tree => {
        const { geohash, height, species } = tree
        const { lat, lon: lng } = gh.decode(geohash)
        const color = getEntityColor(species)
        return turf.point([lng, lat], { lat, lng, height, color, id: geohash })
      })
      .filter(item => !removedTreeIds.includes(item.properties.id))
    return turf.featureCollection(features)
  }, [omissions, commissions])

  return (
    <>
      <GeoJSONSource data={geoJSON} dynamicData>
        <CircleLayer
          circleRadius={getTrunkCircleRadius(true)}
          circleColor="purple"
          zIndex={zIndex}
          dynamicPaint
          dynamicCallbacks
        />
        <CircleLayer
          circleRadius={getTrunkCircleRadius()}
          circleColor={getTrunkCircleColor()}
          zIndex={zIndex + 0.1}
          dynamicPaint
          dynamicCallbacks
        />
      </GeoJSONSource>
    </>
  )
}
