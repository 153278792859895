const SET_VALIDATING = '@@validator/SET_VALIDATING'
const SET_KINKS = '@@validator/SET_KINKS'
const SET_INTERSECTIONS = '@@validator/SET_INTERSECTIONS'

const initialState = {
  validating: false,
  kinks: null,
  intersections: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_VALIDATING: {
      return { ...state, validating: action.validating }
    }
    case SET_KINKS: {
      return { ...state, kinks: action.kinks }
    }
    case SET_INTERSECTIONS: {
      return { ...state, intersections: action.intersections }
    }
    default:
      return state
  }
}

export const setValidating = validating => ({ type: SET_VALIDATING, validating })

export const setKinks = kinks => ({ type: SET_KINKS, kinks })

export const setIntersections = intersections => ({ type: SET_INTERSECTIONS, intersections })
