import { DEVELOPMENT_CLASS, SPECIES } from '@project/ontology'

const entities = {
  // start development classes
  [DEVELOPMENT_CLASS.CLASS_T1]: 'icon-dev-class-t1',
  [DEVELOPMENT_CLASS.CLASS_T2]: 'icon-dev-class-t2',
  [DEVELOPMENT_CLASS.CLASS_02]: 'icon-dev-class-02',
  [DEVELOPMENT_CLASS.CLASS_03]: 'icon-dev-class-03',
  [DEVELOPMENT_CLASS.CLASS_04]: 'icon-dev-class-04',
  [DEVELOPMENT_CLASS.CLASS_05]: 'icon-dev-class-y1-05-s0',
  [DEVELOPMENT_CLASS.CLASS_S0]: 'icon-dev-class-y1-05-s0',
  [DEVELOPMENT_CLASS.CLASS_Y1]: 'icon-dev-class-y1-05-s0',
  [DEVELOPMENT_CLASS.CLASS_A0]: 'icon-dev-class-a0',
  // end development classes
  // start tree species
  [SPECIES.BETULA_SPP]: 'icon-species-birches',
  [SPECIES.PICEA_ABIES]: 'icon-species-pines',
  [SPECIES.PINUS_SYLVESTRIS]: 'icon-species-spruces',
  [SPECIES.POPULUS_TREMULA]: 'icon-species-aspen',
  [SPECIES.UNKNOWN]: 'icon-question-mark',
  // end tree species
}

export const getEntityIconClassName = entityId => entities[entityId] || ''
