import React from 'react'
import { useTranslation } from 'react-i18next'
import { HtmlTable, Spinner } from '@project/components'
import { useNavigate } from 'react-router'
import { TableIcon } from 'components/common/TableIcon'

const getColumns = (t, navigate) => [
  {
    Header: t('common.parcel'),
    Cell: ({ original }) => `${original.propertyId}:${original.parcelNumber}`,
    noWrap: true,
  },
  {
    Header: t('common.createDate'),
    Cell: ({ original }) => original.created,
  },
  {
    Header: t('common.source'),
    Cell: ({ original }) => original.source,
  },
  {
    Header: '',
    Cell: ({ original }) => (
      <TableIcon
        iconClassName="fa fa-pen-to-square"
        onClick={() => navigate(`edit-parcel/${original.propertyId}/${original.parcelNumber}`, { replace: true })}
      />
    ),
    width: 0,
  },
]

const comparator = (a, b) => a.propertyId.localeCompare(b.propertyId)

export const ParcelsTable = ({ data }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  if (!data) return <Spinner />

  if (data.length === 0) return t('table.fallback.nothingToDisplay')

  return <HtmlTable data={data.sort(comparator)} columns={getColumns(t, navigate)} stickyHeader />
}
