import React from 'react'
import { NavLink } from 'react-router-dom'
import './Logo.scss'
import logo from './logo.png'

const brandingEnabled = process.env.REACT_APP_FEATURE_PREFOR_BRANDING === 'enabled'

export const Logo = () => {
  if (!brandingEnabled) return null
  return (
    <NavLink to="/" className="logo">
      <img className="logo__img" src={logo} alt="PreFor Logo" />
    </NavLink>
  )
}
