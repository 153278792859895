import React, { useState, useMemo } from 'react'
import { Head } from './partials/Head'
import { Body } from './partials/Body'
import style from './HtmlTable.module.scss'

const defaultComparator = (a, b) => {
  if (!a) return -1
  if (!b) return 1
  if (typeof a === 'number') return a - b
  if (typeof a === 'string') return a.localeCompare(b)

  return String(a).localeCompare(String(b))
}

export const HtmlTable = ({ data, columns, SubComponent, title, getRowStyle, stickyHeader }) => {
  const [sortId, setSortId] = useState(null)
  const [sortOrder, setSortOrder] = useState(true)

  const sorted = useMemo(() => {
    if (!sortId) return data
    const column = columns.find(c => c.id === sortId)
    if (!column) return data
    const { accessor, sortMethod } = column
    const comparator = sortMethod || defaultComparator

    return data
      .map(item => ({ item, value: accessor ? accessor(item) : item }))
      .sort((a, b) => comparator(a.value, b.value) * (sortOrder ? 1 : -1))
      .map(({ item }) => item)
  }, [data, columns, sortId, sortOrder])

  if (!data || !data.length || !columns || !columns.length) return null

  return (
    <div>
      {title && <div className={style['title']}>{title}</div>}
      <table className={style['table']}>
        <Head
          columns={columns}
          sortId={sortId}
          setSortId={setSortId}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          stickyHeader={stickyHeader}
        />
        <Body data={sorted} columns={columns} SubComponent={SubComponent} getRowStyle={getRowStyle} />
      </table>
    </div>
  )
}
