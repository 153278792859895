export const OPERATION_URGENCY = Object.freeze({
  URGENT: 10001,
  FIRST_FIVE_YEARS: 10002,
  SECOND_FIVE_YEARS: 10003,
  THIRD_FIVE_YEARS: 10004,
  FOURTH_FIVE_YEARS: 10005,
  REST: 10006,
  IN_FINANCE_SEASON: 10007,
  IN_END_OF_FINANCE_SEASON: 10008,
})

export const operationUrgencyColorSchemeIds = {
  [OPERATION_URGENCY.URGENT]: 'legend-ont-1',
  [OPERATION_URGENCY.FIRST_FIVE_YEARS]: 'legend-ont-2',
  [OPERATION_URGENCY.SECOND_FIVE_YEARS]: 'legend-ont-3',
  [OPERATION_URGENCY.THIRD_FIVE_YEARS]: 'legend-ont-4',
  [OPERATION_URGENCY.FOURTH_FIVE_YEARS]: 'legend-ont-5',
  [OPERATION_URGENCY.REST]: 'legend-ont-6',
  [OPERATION_URGENCY.IN_FINANCE_SEASON]: 'legend-ont-7',
  [OPERATION_URGENCY.IN_END_OF_FINANCE_SEASON]: 'legend-ont-8',
}
