import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import { Dialog, DialogClose, DialogHeader, DialogContent, Checkbox } from '@project/components'
import { usePlannerUsers, useProjectPlan } from 'api'
import { grantPlanAccess, revokePlanAccess } from 'api/requests'

export const EditPlanPopup = ({ onClose }) => {
  const { t } = useTranslation()
  const { projectId, planId } = useParams()
  const [plan, loadingPlan, , , refreshPlan] = useProjectPlan(projectId, planId)
  const [users, loadingUsers, , , refreshUsers] = usePlannerUsers()

  const [{ loading }, onChange] = useAsyncFn(
    async (email, allowAccess) => {
      try {
        if (allowAccess) await grantPlanAccess(email, [planId])
        else await revokePlanAccess(email, [planId])
        await new Promise(resolve => setTimeout(resolve, 2000))
        await Promise.all([refreshPlan(true), refreshUsers(true)])
      } catch (error) {
        // do nothing
      }
    },
    [refreshPlan],
  )

  return (
    <Dialog loading={loading || loadingPlan || loadingUsers}>
      <DialogClose onClose={onClose} />
      <DialogHeader>{`${t('common.plan')} "${plan?.name}"`}</DialogHeader>
      <DialogContent>
        {plan &&
          users?.map(user => (
            <Checkbox
              key={user.email}
              label={user.email}
              checked={!!plan.users.find(u => u.email === user.email)}
              onChange={event => onChange(user.email, event.target.checked)}
            />
          ))}
      </DialogContent>
    </Dialog>
  )
}
