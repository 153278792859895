import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { usePaneUtils, ControlToggle } from '@project/pointcloud3D'

const options = [
  {
    mode: 'strong',
    parameters: { kernelRadius: 0.001, minDistance: 0.0000025, maxDistance: 2000 },
  },
  {
    mode: 'normal',
    parameters: { kernelRadius: 0.001, minDistance: 0.000005, maxDistance: 2000 },
  },
  {
    mode: 'low',
    parameters: { kernelRadius: 0.001, minDistance: 0.0000075, maxDistance: 2000 },
  },
  {
    mode: 'none',
    parameters: { kernelRadius: 0.001, minDistance: 1, maxDistance: 2000 },
  },
]

export const SSAOControl = () => {
  const { t } = useTranslation()
  const paneUtils = usePaneUtils()
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const { kernelRadius, minDistance, maxDistance } = options[index].parameters
    const { ssaoPass } = paneUtils
    ssaoPass.kernelRadius = kernelRadius
    ssaoPass.minDistance = minDistance
    ssaoPass.maxDistance = maxDistance
    paneUtils.render()
  }, [index, paneUtils])

  return ReactDOM.createPortal(
    <>
      <ControlToggle
        iconClassName="fa fa-exchange-alt"
        label={`${t('ssao.label')} - ${t(`ssao.mode.${options[index].mode}`)}`}
        onClick={() => setIndex(index >= options.length - 1 ? 0 : index + 1)}
      />
    </>,
    paneUtils.getBottomControlsContainer(),
  )
}
