import { useDispatch } from 'react-redux'
import { useKeyPressEvent } from 'react-use'
import { setMode } from '../../../../redux/ducks/editor'
import { MODES } from '../../../../utils/modes'

export const ModesShortcuts = () => {
  const dispatch = useDispatch()

  useKeyPressEvent(
    'a',
    () => dispatch(setMode(MODES.ADD)),
    () => dispatch(setMode(null)),
  )

  useKeyPressEvent(
    'r',
    () => dispatch(setMode(MODES.REMOVE)),
    () => dispatch(setMode(null)),
  )

  useKeyPressEvent(
    'g',
    () => dispatch(setMode(MODES.GEOHASH)),
    () => dispatch(setMode(null)),
  )

  return null
}
