import React from 'react'
import { useQueryParams } from '@project/routing'
import { useTranslation } from 'react-i18next'
import style from './Placeholder.module.scss'

export const Placeholder = () => {
  const { t } = useTranslation()
  const [{ geohash }] = useQueryParams()
  if (geohash) return null

  return <div className={style['placeholder']}>{t('pointcloud3D:placeholder')}</div>
}
