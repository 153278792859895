import React from 'react'
import classnames from 'classnames'
import { Field } from 'react-final-form'
import './AuthInput.scss'

export const AuthInput = ({ name, label, placeholder, validate, disabled, ...rest }) => (
  <Field name={name} validate={validate} {...rest}>
    {({ input, meta }) => {
      const showError = (meta.error || meta.submitError) && meta.touched
      return (
        <div className="auth-input">
          <label className="auth-input__label" htmlFor={name}>
            {label}
          </label>
          <input
            className={classnames({
              'auth-input__input': true,
              'auth-input__input--error': showError,
            })}
            id={name}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            {...input}
          />
          <div
            className={classnames({
              'auth-input__message': true,
              'auth-input__message--active': showError,
            })}
          >
            {meta.error || meta.submitError}
          </div>
        </div>
      )
    }}
  </Field>
)
