import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useQueryParams } from '@project/routing'
import { Pointcloud3D, OrbitControls, Points, BBox, Progress, Placeholder } from '@project/pointcloud3D'
import { usePointcloud } from 'api'
import { TreeTrunk } from './partials/TreeTrunk'
import { TreeCrown } from './partials/TreeCrown'
import { Ruler } from './partials/Ruler'
import { TreeTops } from './partials/TreeTops'

export const EditorPointcloud = () => {
  const { projectId } = useParams()
  const [{ geohash }] = useQueryParams()
  const [tile] = usePointcloud(projectId, geohash)
  const rulerActive = useSelector(state => state.editor.rulerActive)

  const customTile = useMemo(() => {
    if (!tile) return null
    const customBBox = { ...tile.normalizedBBox, maxZ: tile.normalizedBBox.minZ + 1 }
    return { ...tile, normalizedBBox: customBBox }
  }, [tile])

  return (
    <Pointcloud3D tile={customTile}>
      <OrbitControls />
      <Points />
      <BBox />
      {!rulerActive && (
        <>
          <TreeTrunk />
          <TreeCrown />
        </>
      )}
      <Ruler />
      <TreeTops />
      <Progress />
      <Placeholder />
    </Pointcloud3D>
  )
}
