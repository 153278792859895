import React from 'react'
import classnames from 'classnames'
import style from './Toggle.module.scss'

export const Toggle = ({ value, onChange, disabled }) => (
  <div
    className={classnames({
      [style['container']]: true,
      [style['checked']]: value,
      [style['disabled']]: disabled,
    })}
    onClick={() => {
      if (!disabled) onChange?.(!value)
    }}
  >
    <div className={style['handle']} />
  </div>
)
