import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Spinner } from '@project/components'
import { usePipelines } from 'api'
import { ScanDashboard } from '../../common/ScanDashboard'
import { PipelinesView } from '../../common/PipelinesView'
import { ParcelMap } from '../../common/ParcelMap'

export const BackpackScans = () => {
  const { t } = useTranslation()
  const { projectId } = useParams()
  const [pipelines] = usePipelines(projectId)

  if (!pipelines?.backpack) return <Spinner />

  if (!pipelines.backpack.length) return t('table.fallback.nothingToDisplay')

  return (
    <PipelinesView
      dashboard={pipelines.backpack.map(scan => (
        <ScanDashboard key={scan.scanId} scan={scan} />
      ))}
      map={<ParcelMap />}
    />
  )
}
