import React from 'react'
import { uid } from 'react-uid'
import { Scrollbars } from 'react-custom-scrollbars'
import { LegendItem } from './partials/LegendItem'
import style from './Legend.module.scss'

const LegendItemsContainer = ({ height, scrollable, children }) => {
  if (scrollable)
    return (
      <>
        <Scrollbars style={{ height }}>{children}</Scrollbars>
        <div className={style['scroll-shadow']} />
      </>
    )
  return children
}

export const Legend = ({
  legendTitle,
  items,
  getItemColor,
  getItemTitle,
  getItemText,
  getItemImage,
  getItemClassName,
  height = '100%',
  scrollable,
  className,
}) => (
  <div className={className}>
    {legendTitle && <div className={style['title']}>{legendTitle}</div>}
    <LegendItemsContainer height={height} scrollable={scrollable}>
      <div className={style['items']}>
        {items?.map((item, index) => {
          const color = getItemColor && getItemColor(item, index)
          const title = getItemTitle && getItemTitle(item, index)
          const text = getItemText && getItemText(item, index)
          const image = getItemImage && getItemImage(item, index)
          const itemClassName = getItemClassName && getItemClassName(item, index)
          return (
            <LegendItem
              key={uid(index)}
              title={title}
              text={text}
              color={color}
              image={image}
              className={itemClassName}
            />
          )
        })}
      </div>
    </LegendItemsContainer>
  </div>
)
