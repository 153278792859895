import { colorSchemes } from '@project/lib'

import { SPECIES, speciesColorSchemeIds } from './classes/species'
import { DEVELOPMENT_CLASS, developmentClassColorSchemeIds } from './classes/developmentClass'
import { ACCESSIBILITY, accessibilityColorSchemeIds } from './classes/accessibility'
import { CUTTING, cuttingColorSchemeIds } from './classes/cutting'
import { FERTILITY_CLASS, fertilityClassColorSchemeIds } from './classes/fertilityClass'
import { MAIN_GROUP, mainGroupColorSchemeIds } from './classes/mainGroup'
import { OPERATION_URGENCY, operationUrgencyColorSchemeIds } from './classes/operationUrgency'
import { DRAINAGE_STATE, drainageStateColorSchemeIds } from './classes/drainageState'
import { SOIL_TYPE, soilTypeColorSchemeIds } from './classes/soilType'
import { STOREY, storeyColorSchemeIds } from './classes/storey'
import { PLANTED, plantedColorSchemeIds, getPlantedEntityByValue, getPlantedValueByEntity } from './classes/planted'
import {
  UNEVEN_AGED,
  unevenAgedColorSchemeIds,
  getUnevenAgedEntityByValue,
  getUnevenAgedValueByEntity,
} from './classes/unevenAged'

//
//
const getEntities = ontologyMap => Object.values(ontologyMap)

export const speciesIds = getEntities(SPECIES)
export const developmentClassIds = getEntities(DEVELOPMENT_CLASS)
export const accessibilityIds = getEntities(ACCESSIBILITY)
export const cuttingIds = getEntities(CUTTING)
export const fertilityClassIds = getEntities(FERTILITY_CLASS)
export const mainGroupIds = getEntities(MAIN_GROUP)
export const operationUrgencyIds = getEntities(OPERATION_URGENCY)
export const drainageStateIds = getEntities(DRAINAGE_STATE)
export const soilTypeIds = getEntities(SOIL_TYPE)
export const storeyIds = getEntities(STOREY)
export const plantedIds = getEntities(PLANTED)
export const unevenAgedIds = getEntities(UNEVEN_AGED)

//
//
export const entityLists = [
  SPECIES,
  DEVELOPMENT_CLASS,
  ACCESSIBILITY,
  CUTTING,
  FERTILITY_CLASS,
  MAIN_GROUP,
  OPERATION_URGENCY,
  DRAINAGE_STATE,
  SOIL_TYPE,
  STOREY,
  PLANTED,
  UNEVEN_AGED,
].map(item => Object.values(item))

const entityIndices = new Map(
  entityLists.flatMap((entityList, listIndex) =>
    entityList.map((entity, entityIndex) => [entity, { listIndex, entityIndex }]),
  ),
)

export const entitiesComparator = (a, b) => {
  const ai = entityIndices.get(a)
  const bi = entityIndices.get(b)
  if (ai && bi) {
    return ai.listIndex - bi.listIndex || ai.entityIndex - bi.entityIndex
  }
  if (!ai && !bi) return 0
  return ai ? -1 : 1
}

export const uniqEntities = entities => [...new Set(entities)].sort(entitiesComparator)

//
//
const getTranslationKeys = (map, prefix, hasDescription) =>
  Object.keys(map).reduce((acc, key) => {
    const id = map[key]
    acc[id] = {
      caption: `ontology:caption.${prefix}__${key}`,
      ...(hasDescription && { description: `ontology:description.${prefix}__${key}` }),
    }
    return acc
  }, {})

const speciesTranslationKeys = getTranslationKeys(SPECIES, 'SPECIES')
const developmentClassTranslationKeys = getTranslationKeys(DEVELOPMENT_CLASS, 'DEVELOPMENT_CLASS', true)
const accessibilityTranslationKeys = getTranslationKeys(ACCESSIBILITY, 'ACCESSIBILITY')
const cuttingTranslationKeys = getTranslationKeys(CUTTING, 'CUTTING')
const fertilityClassTranslationKeys = getTranslationKeys(FERTILITY_CLASS, 'FERTILITY_CLASS')
const mainGroupTranslationKeys = getTranslationKeys(MAIN_GROUP, 'MAIN_GROUP')
const operationUrgencyTranslationKeys = getTranslationKeys(OPERATION_URGENCY, 'OPERATION_URGENCY')
const drainageStateTranslationKeys = getTranslationKeys(DRAINAGE_STATE, 'DRAINAGE_STATE')
const soilTypeTranslationKeys = getTranslationKeys(SOIL_TYPE, 'SOIL_TYPE')
const storeyTranslationKeys = getTranslationKeys(STOREY, 'STOREY')
const plantedTranslationKeys = getTranslationKeys(PLANTED, 'PLANTED')
const unevenAgedTranslationKeys = getTranslationKeys(UNEVEN_AGED, 'UNEVEN_AGED')

const entityTranslationKeys = {
  ...speciesTranslationKeys,
  ...developmentClassTranslationKeys,
  ...accessibilityTranslationKeys,
  ...cuttingTranslationKeys,
  ...fertilityClassTranslationKeys,
  ...mainGroupTranslationKeys,
  ...operationUrgencyTranslationKeys,
  ...drainageStateTranslationKeys,
  ...soilTypeTranslationKeys,
  ...storeyTranslationKeys,
  ...plantedTranslationKeys,
  ...unevenAgedTranslationKeys,
}

export const getEntityCaption = (entity, t) => {
  const keyItem = entityTranslationKeys[entity]
  return keyItem && t(keyItem.caption)
}

export const getEntityDescription = (entity, t) => {
  const keyItem = entityTranslationKeys[entity]
  return keyItem && t(keyItem.description)
}

export const getEntityLabel = (entity, t) => {
  const keyItem = entityTranslationKeys[entity]
  const captionKey = keyItem && keyItem.caption
  const descriptionKey = keyItem && keyItem.description
  return captionKey && descriptionKey ? `${t(captionKey)} - ${t(descriptionKey)}` : t(captionKey) || null
}

//
//
const entityColorSchemeIds = {
  ...speciesColorSchemeIds,
  ...developmentClassColorSchemeIds,
  ...accessibilityColorSchemeIds,
  ...cuttingColorSchemeIds,
  ...fertilityClassColorSchemeIds,
  ...mainGroupColorSchemeIds,
  ...operationUrgencyColorSchemeIds,
  ...drainageStateColorSchemeIds,
  ...soilTypeColorSchemeIds,
  ...storeyColorSchemeIds,
  ...plantedColorSchemeIds,
  ...unevenAgedColorSchemeIds,
}

export const getEntityColor = entity => {
  const colorScheme = colorSchemes.find(item => item.id === entityColorSchemeIds[entity])
  return colorScheme && colorScheme.hex
}

export { getPlantedEntityByValue, getPlantedValueByEntity, getUnevenAgedEntityByValue, getUnevenAgedValueByEntity }

export {
  SPECIES,
  DEVELOPMENT_CLASS,
  ACCESSIBILITY,
  CUTTING,
  FERTILITY_CLASS,
  MAIN_GROUP,
  OPERATION_URGENCY,
  DRAINAGE_STATE,
  SOIL_TYPE,
  STOREY,
  PLANTED,
  UNEVEN_AGED,
}
