import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import * as turf from '@turf/turf'
import { GeoJSONSource, FillLayer } from '@project/mapbox'
import { usePointcloudGeohashesGeoJSON, useTreeCorrectionTiles } from 'api'
import { TILE_STATUSES } from '../../../../../../../../utils/tileStatuses'

export const SkippedGeohashes = ({ zIndex }) => {
  const { projectId } = useParams()
  const [geohashesGeoJSON] = usePointcloudGeohashesGeoJSON(projectId)
  const [treeCorrectionTiles] = useTreeCorrectionTiles(projectId)
  const geohashesToBeSkipped = useSelector(state => state.data.geohashesToBeSkipped)

  const geoJSON = useMemo(() => {
    if (!geohashesGeoJSON || !treeCorrectionTiles) return null

    const geohashes = treeCorrectionTiles
      .filter(item => item.status === TILE_STATUSES.SKIP)
      .filter(item => !geohashesToBeSkipped.includes(item.geohash))
      .map(item => item.geohash)

    const features = geohashes
      .map(geohash => geohashesGeoJSON.features.find(f => f.properties.geohash === geohash))
      .filter(Boolean)

    return turf.featureCollection(features)
  }, [geohashesGeoJSON, treeCorrectionTiles, geohashesToBeSkipped])

  return (
    <GeoJSONSource data={geoJSON} dynamicData>
      <FillLayer fillColor="#000" fillOpacity={0.75} zIndex={zIndex} />
    </GeoJSONSource>
  )
}
