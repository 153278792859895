import React from 'react'
import ReactDOM from 'react-dom'
import { useMap } from '../../../MapInstanceProvider'
import style from './SpinnerContainer.module.scss'

export const SpinnerContainer = React.forwardRef((props, ref) => {
  const map = useMap()

  if (!map) return null

  return ReactDOM.createPortal(<div ref={ref} className={style['spinner-container']} />, map.getContainer())
})
