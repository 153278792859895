import React, { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useAsyncFn } from 'react-use'
import gh from 'latlon-geohash'
import * as turf from '@turf/turf'
import { GeoJSONSource, CircleLayer, SpinnerOverlay } from '@project/mapbox'
import { useParams } from 'react-router-dom'
import { useQueryParams } from '@project/routing'
import { useProject, useTrunks, useTreeCorrections } from 'api'
import { createCommission, deleteTreeCorrection } from 'api/requests'
import { MODES } from '../../../../../../utils/modes'

const getTrunkCircleRadius = () => {
  const minR = 2 * Math.pow(1.575, 10 - 22)
  const maxR = 2 * Math.pow(1.575, 24 - 22)
  const heightExp = ['max', ['number', ['get', 'height']], 8]
  return ['interpolate', ['exponential', 1.575], ['zoom'], 5, ['*', minR, heightExp], 24, ['*', maxR, heightExp]]
}

export const RemoveTreeSubstrate = ({ zIndex }) => {
  const hoverRef = useRef()
  const { projectId } = useParams()
  const [{ geohash }] = useQueryParams()
  const [project] = useProject(projectId)
  const [trunks] = useTrunks(projectId)
  const [treeCorrections, , , , refreshTreeCorrections] = useTreeCorrections(projectId)

  const mode = useSelector(state => state.editor.mode)

  const [{ loading }, onRemove] = useAsyncFn(
    async (treeGeohash, added, removed) => {
      try {
        if (!project || !treeGeohash) return
        if (added || removed) await deleteTreeCorrection(project.jobId, treeGeohash)
        else await createCommission(project.jobId, treeGeohash)
        await new Promise(resolve => setTimeout(resolve, 500))
        await refreshTreeCorrections(true, true)
      } catch (error) {
        // do nothing
      }
    },
    [project, refreshTreeCorrections],
  )

  const geohashTrunks = useMemo(() => {
    if (!trunks || !treeCorrections || !geohash) return null

    const addedTrees = treeCorrections.omissions.map(item => {
      const { lat, lon: lng } = gh.decode(item.geohash)
      return { ...item, lat, lng, added: true }
    })
    const { sw, ne } = gh.bounds(geohash)
    return [...trunks, ...addedTrees].filter(
      ({ lat, lng }) => lat >= sw.lat && lat <= ne.lat && lng >= sw.lon && lng <= ne.lon,
    )
  }, [trunks, treeCorrections, geohash])

  const geohashTrunksGeoJSON = useMemo(() => {
    if (!geohashTrunks || !treeCorrections) return null
    const removedTreeIds = treeCorrections.commissions.map(item => item.geohash)
    const features = geohashTrunks.map((trunk, index) => {
      const { lat, lng, height, added } = trunk
      const id = gh.encode(lat, lng, 12)
      const removed = removedTreeIds.includes(id)
      return turf.point([lng, lat], { lat, lng, height, added, removed, id }, { id: index + 1 })
    })
    return turf.featureCollection(features)
  }, [geohashTrunks, treeCorrections])

  return (
    <>
      <GeoJSONSource data={geohashTrunksGeoJSON} dynamicData>
        <CircleLayer
          circleRadius={getTrunkCircleRadius()}
          circleColor="#ff0000"
          circleOpacity={mode === MODES.REMOVE ? ['case', ['boolean', ['feature-state', 'hover'], false], 0.85, 0] : 0}
          onClick={event => {
            const { features } = event
            if (mode === MODES.REMOVE && features.length > 0) {
              const { id, added, removed } = features[0].properties
              onRemove(id, added, removed)
            }
          }}
          onMouseMove={(event, sourceId) => {
            const { target: map, features } = event
            if (features.length > 0) {
              if (hoverRef.current) map.setFeatureState({ source: sourceId, id: hoverRef.current }, { hover: false })
              hoverRef.current = features[0].id
              map.setFeatureState({ source: sourceId, id: features[0].id }, { hover: true })
            }
          }}
          onMouseLeave={(event, sourceId) => {
            const { target: map } = event
            if (hoverRef.current) map.setFeatureState({ source: sourceId, id: hoverRef.current }, { hover: false })
            hoverRef.current = null
          }}
          zIndex={zIndex}
          dynamicPaint
          dynamicCallbacks
        />
      </GeoJSONSource>
      {loading && <SpinnerOverlay />}
    </>
  )
}
