import React from 'react'
import style from './ProgressIndicator.module.scss'

export const ProgressIndicator = ({ count = 0, total }) => {
  if (!total) return null
  return (
    <div className={style['progress']}>
      <div className={style['bar']}>
        <div className={style['indicator']} style={{ width: `${(count / total) * 100}%` }} />
      </div>
      <div className={style['label']}>{`${count} / ${total}`}</div>
    </div>
  )
}
