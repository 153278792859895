export default {
  caption: {
    // species
    SPECIES__BETULA_SPP: 'Birch',
    SPECIES__PICEA_ABIES: 'Spruce',
    SPECIES__PINUS_SYLVESTRIS: 'Pine',
    SPECIES__POPULUS_TREMULA: 'Aspen',
    SPECIES__BETULA_PENDULA: 'Silver birch',
    SPECIES__BETULA_PUBESCENS: 'Downy birch',
    SPECIES__ALNUS_INCANA: 'Grey alder',
    SPECIES__ALNUS_GLUTINOSA: 'Black alder',
    SPECIES__OTHER_CONIFEROUS: 'Other coniferous',
    SPECIES__OTHER_DECIDUOUS: 'Other deciduous',
    SPECIES__PSEUDOTSUGA_MENZIESII: 'Douglas fir',
    SPECIES__JUNIPERUS_COMMUNIS: 'Common juniper',
    SPECIES__PINUS_CONTORTA: 'Lodgepole pine',
    SPECIES__ULMUS_LAEVIS: 'Ulmus laevis',
    SPECIES__LARIX_SPP: 'Larix spp.',
    SPECIES__TILIA_CORDATA: 'Tilia cordata',
    SPECIES__PICEA_MARIANA: 'Black spruce',
    SPECIES__SALIX_SPP: 'Salix spp.',
    SPECIES__SORBUS_AUCUPARIA: 'Rowan',
    SPECIES__ABIES_SPP: 'Fir',
    SPECIES__SALIX_CAPREA: 'Salix caprea',
    SPECIES__FRAXINUS_EXCELSIOR: 'European ash',
    SPECIES__PINUS_CEMBRA: 'Swiss pine',
    SPECIES__PICEA_OMORIKA: 'Serbian spruce',
    SPECIES__QUERCUS_ROBUR: 'English oak',
    SPECIES__PRUNUS_PADUS: 'Prunus padus',
    SPECIES__ACER_PLATANOIDES: 'Norway maple',
    SPECIES__BETULA_PENDULA_VAR_CARELICA: 'Curly birch',
    SPECIES__ULMUS_GLABRA: 'Scotch elm',
    SPECIES__DECIDUOUS: 'Deciduous',
    SPECIES__CONIFEROUS: 'Coniferous',
    SPECIES__PINUS: 'Pinus (genus)',
    SPECIES__PICEA: 'Picea (genus)',
    SPECIES__BETULA: 'Betula (genus)',
    SPECIES__PSEUDOTSUGA: 'Pseudotsuga (genus)',
    SPECIES__JUNIPERUS: 'Juniperus (genus)',
    SPECIES__ULMUS: 'Ulmus (genus)',
    SPECIES__LARIX: 'Larix (genus)',
    SPECIES__TILIA: 'Tilia (genus)',
    SPECIES__SALIX: 'Salix (genus)',
    SPECIES__SORBUS: 'Sorbus (genus)',
    SPECIES__FRAXINUS: 'Fraxinus (genus)',
    SPECIES__QUERCUS: 'Quercus (genus)',
    SPECIES__PRUNUS: 'Prunus (genus)',
    SPECIES__ACER: 'Acer (genus)',
    SPECIES__CARPINUS: 'Carpinus (genus)',
    SPECIES__CORNUS: 'Cornus (genus)',
    SPECIES__CORYLUS: 'Corylus (genus)',
    SPECIES__CRATAEGUS: 'Crataegus (genus)',
    SPECIES__MALUS: 'Malus (genus)',
    SPECIES__SAMBUCUS: 'Sambucus (genus)',
    SPECIES__FAGUS: 'Fagus (genus)',
    SPECIES__POPULUS: 'Populus (genus)',
    SPECIES__ABIES: 'Abies (genus)',
    SPECIES__ALNUS: 'Alnus (genus)',
    SPECIES__UNKNOWN: 'Unclassified',
    // development classes
    DEVELOPMENT_CLASS__CLASS_02: '02',
    DEVELOPMENT_CLASS__CLASS_03: '03',
    DEVELOPMENT_CLASS__CLASS_04: '04',
    DEVELOPMENT_CLASS__CLASS_05: '05',
    DEVELOPMENT_CLASS__CLASS_A0: 'A0',
    DEVELOPMENT_CLASS__CLASS_ER: 'ER',
    DEVELOPMENT_CLASS__CLASS_S0: 'S0',
    DEVELOPMENT_CLASS__CLASS_T1: 'T1',
    DEVELOPMENT_CLASS__CLASS_T2: 'T2',
    DEVELOPMENT_CLASS__CLASS_Y1: 'Y1',
    // accessibility
    ACCESSIBILITY__ALL_YEAR: 'All year',
    ACCESSIBILITY__NOT_DURING_FROST_HEAVE: 'Not during frost heave',
    ACCESSIBILITY__DRY_SUMMER: 'Dry summer',
    ACCESSIBILITY__WINTER: 'Winter',
    ACCESSIBILITY__NOT_DEFINED: 'Unknown',
    // cutting
    CUTTING__REST: 'Rest',
    CUTTING__UPPER_TREE_REMOVAL: 'Upper tree removal',
    CUTTING__FIRST_THINNING: 'First thinning',
    CUTTING__THINNING: 'Thinning',
    CUTTING__STRIP_CUT: 'Strip cut',
    CUTTING__CLEAR_CUT: 'Clear cut',
    CUTTING__SHELTERWOOD_CUT: 'Shelterwood cut',
    CUTTING__SEED_TREE_CUT: 'Seed-tree cut',
    CUTTING__UPPER_THINNING: 'Upper thinning',
    CUTTING__PATCH_CUT: 'Patch cut',
    CUTTING__TENDING_OF_SAPLING_STAND: 'Tending of sapling stand',
    CUTTING__THINNING_OF_SAPLING_STAND: 'Thinning of sapling stand',
    CUTTING__UNDERSTOREY_CLEARING: 'Understorey clearing',
    //
    CUTTING__REGENERATION_NATURAL_PINE: 'Pine natural regeneration',
    CUTTING__REGENERATION_NATURAL_SPRUCE: 'Spruce natural regeneration',
    CUTTING__REGENERATION_NATURAL_BENDULA: 'Silver birch natural regeneration',
    CUTTING__REGENERATION_NATURAL_PUBESCENS: 'Downy birch natural regeneration',
    //
    CUTTING__REGENERATION_INSEMINATION_PINE: 'Pine insemination',
    CUTTING__REGENERATION_INSEMINATION_SPRUCE: 'Spruce insemination',
    CUTTING__REGENERATION_INSEMINATION_BENDULA: 'Silver birch insemination',
    CUTTING__REGENERATION_INSEMINATION_PUBESCENS: 'Downy birch insemination',
    //
    CUTTING__REGENERATION_PLANTING_PINE: 'Pine planting',
    CUTTING__REGENERATION_PLANTING_SPRUCE: 'Spruce planting',
    CUTTING__REGENERATION_PLANTING_BENDULA: 'Silve birch planting',
    CUTTING__REGENERATION_PLANTING_PUBESCENS: 'Downy birch planting',
    //
    CUTTING__SOIL_PREPARATION_NOT_NEEDED: 'Preparation not needed',
    CUTTING__SOIL_PREPARATION_GENERAL: 'Soil preparation',
    CUTTING__SOIL_PREPARATION_SCALPING: 'Scalping',
    CUTTING__SOIL_PREPARATION_MOUNDING: 'Mounding',
    CUTTING__SOIL_PREPARATION_HARROWING: 'Harrowing',
    CUTTING__SOIL_PREPARATION_PLOUGHING: 'Ploughing',
    // drainage state
    DRAINAGE_STATE__NOT_DRAINED_MINERAL_LAND: 'Not drained mineral land',
    DRAINAGE_STATE__PALUDIFIED_MINERAL_LAND: 'Paludified mineral land',
    DRAINAGE_STATE__DRAINED_MINERAL_LAND: 'Drained mineral land',
    DRAINAGE_STATE__PEATLAND: 'Peatland',
    DRAINAGE_STATE__DITCHED_PEATLAND: 'Ditched peatland',
    DRAINAGE_STATE__CHANGED_PEATLAND: 'Changed peatland',
    DRAINAGE_STATE__DRAINED_PEATLAND: 'Drained peatland',
    // fertility classes:
    FERTILITY_CLASS__GROVE: 'Herb-rich forest',
    FERTILITY_CLASS__GROVY: 'Herb-rich heath forest',
    FERTILITY_CLASS__MESIC: 'Moist heath forest (mesic)',
    FERTILITY_CLASS__DRYISH: 'Dryish heath forest (sub-xeric)',
    FERTILITY_CLASS__DRY: 'Dry heath forest (xeric)',
    FERTILITY_CLASS__BARREN: 'Barren heath forest',
    FERTILITY_CLASS__ROCKY: 'Rocky ground',
    FERTILITY_CLASS__FELL: 'Wasteland',
    FERTILITY_CLASS__UNKNOWN: 'Unknown',
    // main group
    MAIN_GROUP__MINERAL: 'Mineral',
    MAIN_GROUP__SPRUCE_MIRE: 'Mire',
    MAIN_GROUP__PINE_MIRE: 'Pine mire',
    MAIN_GROUP__OPEN_BOG: 'Open bog',
    MAIN_GROUP__OPEN_FEN: 'Open fen',
    // operation urgency
    OPERATION_URGENCY__URGENT: 'Immediately',
    OPERATION_URGENCY__FIRST_FIVE_YEARS: '1 to 5 years',
    OPERATION_URGENCY__SECOND_FIVE_YEARS: '6 to 10 years',
    OPERATION_URGENCY__THIRD_FIVE_YEARS: '11 to 15 years',
    OPERATION_URGENCY__FOURTH_FIVE_YEARS: '16 to 20 years',
    OPERATION_URGENCY__REST: 'Rest',
    OPERATION_URGENCY__IN_FINANCE_SEASON: 'In finance season',
    OPERATION_URGENCY__IN_END_OF_FINANCE_SEASON: 'In end of finance season',
    // soil type
    SOIL_TYPE__COARSE_GRAINED_MINERAL_SOIL: 'Coarse grained mineral soil',
    SOIL_TYPE__COARSE_GRAINED_MORAINE: 'Coarse grained moraine',
    SOIL_TYPE__COARSE_GRAINED_SORTED_SOIL: 'Coarse grained sorted soil',
    SOIL_TYPE__FINE_GRAINED_MINERAL_SOIL: 'Grained mineral soil',
    SOIL_TYPE__FINE_GRAINED_MORAINE: 'Fine grained moraine',
    SOIL_TYPE__FINE_GRAINED_SORTED_SOIL: 'Grained sorted soil',
    SOIL_TYPE__STONY_COARSE_GRAINED_MINERAL_SOIL: 'Coarse grained mineral soil',
    SOIL_TYPE__STONY_COARSE_GRAINED_MORAINE: 'Coarse grained moraine',
    SOIL_TYPE__STONY_COARSE_GRAINED_SORTED_SOIL: 'Coarse grained sorted soil',
    SOIL_TYPE__STONY_FINE_GRAINED_MINERAL_SOIL: 'Fine grained mineral soil',
    SOIL_TYPE__ROCK_OR_ROCK_FIELD: 'Rock or rock field',
    SOIL_TYPE__PEAT: 'Peat',
    SOIL_TYPE__CAREX_PEAT: 'Carex peat',
    SOIL_TYPE__SPHAGNUM_PEAT: 'Sphagnum peat',
    SOIL_TYPE__WOODY_PEAT: 'Woody peat',
    SOIL_TYPE__CAREX_PEAT_VON_POST_OVER5: 'Carex peat von post over5',
    SOIL_TYPE__SPHAGNUM_PEAT_VON_POST_OVER5: 'Sphagnum peat von post over5',
    SOIL_TYPE__CAREX_PEAT_VON_POST_UNDER5: 'Carex peat von post under5',
    SOIL_TYPE__SPHAGNUM_PEAT_VON_POST_UNDER5: 'Sphagnum peat von post under5',
    SOIL_TYPE__MOULD: 'Mould',
    SOIL_TYPE__SLUDGE: 'Sludge',
    // storey
    STOREY__DOMINANT: 'Dominant storey',
    STOREY__LOWER: 'Lower storey',
    STOREY__UPPER: 'Upper storey',
    // planted
    PLANTED__TRUE: 'Planted',
    PLANTED__FALSE: 'Not planted',
    // uneven aged
    UNEVEN_AGED__TRUE: 'Uneven-aged',
    UNEVEN_AGED__FALSE: 'Not uneven-aged',
  },

  description: {
    // development classes
    DEVELOPMENT_CLASS__CLASS_02: 'Young thinning stand',
    DEVELOPMENT_CLASS__CLASS_03: 'Advanced thinning stand',
    DEVELOPMENT_CLASS__CLASS_04: 'Mature stand',
    DEVELOPMENT_CLASS__CLASS_05: 'Shelter tree stand',
    DEVELOPMENT_CLASS__CLASS_A0: 'Regeneration area',
    DEVELOPMENT_CLASS__CLASS_ER: 'Uneven-aged stand',
    DEVELOPMENT_CLASS__CLASS_S0: 'Seed tree stand',
    DEVELOPMENT_CLASS__CLASS_T1: 'Young seedling stand',
    DEVELOPMENT_CLASS__CLASS_T2: 'Advanced seedling stand',
    DEVELOPMENT_CLASS__CLASS_Y1: 'Overstorey seedling stand',
  },
}
