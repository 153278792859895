export default {
  title: {
    login: 'Tunnemme metsäsi puut.',
    register: 'Uusi käyttäjä',
    forgotPassword: 'Salasanan palauttaminen',
    verify: 'Sähköpostiosoitteen varmennus',
    changePassword: 'Vaihda salasana',
    resetPassword: 'Nollaa salasana',
  },

  navigation: {
    login: 'Kirjaudu',
    register: 'Uusi käyttäjä',
    forgotPassword: 'Unohtuiko salasana?',
  },

  submit: {
    login: 'Kirjaudu',
    register: 'Valmis',
    forgotPassword: 'Nollaa salasana',
    verify: 'Valmis',
    continue: 'Jatka',
    changePassword: 'Vaihda salasana',
    resetPassword: 'Valmis',
  },

  label: {
    email: 'Sähköposti',
    password: 'Salasana',
    newPassword: 'Uusi salasana',
    oldPassword: 'Vanha salasana',
    repeatPassword: 'Salasana uudestaan',
    code: 'Koodi',
  },

  placeholder: {
    email: 'etunimi.sukunimi@esimerkki.fi',
  },

  validation: {
    passwordsShouldMatch: 'Salasanojen tulisi vastata',
  },

  description: {
    default:
      'Metsäsi ja sen puut. Ne kasvavat ja niitä hoidetaan – mutta kuinka tarkasti tiedät mitä omistat? ' +
      'PreFor tietää, puun ja millimetrin tarkkuudella.',
    verify:
      'Lähetimme sinulle äsken varmennuskoodin sähköpostiin. Viimeistele rekisteröityminen antamalla ' +
      'varmennuskoodi tähän lomakkeeseen.',
    forgotPassword:
      'Syötä käyttäjänimesi salasanan palautusta varten. Lähetämme sähköpostiisi vahvistuskoodin uuden salasanan asettamiseksi.',
    resetPassword: 'Kirjauduit sisään väliaikaisella salasanalla. Jatkaaksesi, syötä uusi pysyvä salasana.',
  },

  error: {
    incorrectUsernameOrPassword: 'Väärä käyttäjätunnus tai salasana',
    userDoesNotExist: 'Käyttäjää ei löydy',
    userAlreadyExists: 'Käyttäjä on jo olemassa',
    invalidVerificationCode: 'Väärä koodi',
    userIsNotConfirmed: 'Käyttäjän sähköpostiosoitetta ei ole varmennettu',
    temporaryPasswordHasExpired: 'Väliaikainen salasana on vanhentunut ja täytyy vaihtaa järjestelmävalvojan toimesta',
    passwordCannotBeReset: 'Käyttäjänä salasanaa ei voitu vaihtaa nykytilassa',
  },
}
