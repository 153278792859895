import React from 'react'
import { PipelineControl } from './partials/PipelineControl'
import { JobsTable } from './partials/JobsTable'
import style from './PipelineWidget.module.scss'

export const PipelineWidget = ({ pipeline }) => {
  if (!pipeline) return null

  return (
    <div className={style['container']}>
      <div className={style['control']}>
        <PipelineControl pipeline={pipeline} />
      </div>
      {!!pipeline.jobs?.length && (
        <div className={style['jobs']}>
          <JobsTable jobs={pipeline.jobs} agent={pipeline.agent} />
        </div>
      )}
    </div>
  )
}
