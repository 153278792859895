import _ from 'lodash'

const PUSH_SNAPSHOT = '@@history/PUSH_SNAPSHOT'
const SET_INDEX = '@@history/SET_INDEX'

const initialState = {
  snapshots: [],
  index: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PUSH_SNAPSHOT: {
      const { snapshots, index } = state
      const previous = snapshots.slice(0, index + 1).slice(-10)
      return { snapshots: [...previous, action.snapshot], index: previous.length }
    }
    case SET_INDEX: {
      return { ...state, index: action.index }
    }
    default:
      return state
  }
}

export const takeHistorySnapshot = () => (dispatch, getState) => {
  const polygons = _.cloneDeep(getState().geometry.polygons)
  dispatch(pushSnapshot({ polygons }))
}

export const pushSnapshot = snapshot => ({ type: PUSH_SNAPSHOT, snapshot })

export const setIndex = index => ({ type: SET_INDEX, index })
