import { useEffect, useState } from 'react'
import * as THREE from 'three'
import { useQueryParams } from '@project/routing'
import { usePaneUtils, useTile } from '@project/pointcloud3D'
import { useSelector } from 'react-redux'
import { getPosition } from '../../utils/position'

export const TreeTrunk = () => {
  const paneUtils = usePaneUtils()
  const [{ geohash }] = useQueryParams()
  const tile = useTile()
  const coordinate = useSelector(state => state.tree.coordinate)

  const { normalizedBBox: bbox } = tile || {}

  const [cylinder, setCylinder] = useState(null)

  useEffect(() => {
    if (!cylinder) {
      const geometry = new THREE.CylinderGeometry(0.008, 0.008, 1, 8)
      const material = new THREE.MeshBasicMaterial({ color: 0x800080, transparent: true, opacity: 0.8 })
      const mesh = new THREE.Mesh(geometry, material)
      mesh.geometry.rotateX(Math.PI * 0.5)
      setCylinder(mesh)
    }
  }, [paneUtils, cylinder])

  useEffect(() => {
    if (cylinder && bbox) {
      cylinder.geometry.height = bbox.maxZ - bbox.minZ
      paneUtils.render()
    }
  }, [cylinder, bbox, paneUtils])

  useEffect(() => {
    if (coordinate && bbox && cylinder) {
      const [lng, lat] = coordinate
      const { x, y, z } = getPosition(lat, lng, geohash, bbox)
      cylinder.position.set(x, y, z)
      const outside = x < bbox.minX || x > bbox.maxX || y < bbox.minY || y > bbox.maxY
      if (outside) paneUtils.remove(cylinder)
      else paneUtils.add(cylinder)
      paneUtils.render()
    }

    return () => {
      paneUtils.remove(cylinder)
      paneUtils.render()
    }
  }, [coordinate, geohash, bbox, cylinder, paneUtils])

  return null
}
