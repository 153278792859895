import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router'
import { useAsyncFn } from 'react-use'
import moment from 'moment'
import { HtmlTable, Spinner } from '@project/components'
import { useProjectPlans } from 'api'
import { createCompartmentsDownload } from 'api/requests'
import { TableIcon } from '../../../../../../common/TableIcon'

const getColumns = (t, navigate, projectId, downloadCompartmentsFn) => [
  {
    Header: t('common.planId'),
    Cell: ({ original }) => original.planId,
  },
  {
    Header: t('common.name'),
    Cell: ({ original }) => original.name,
  },
  {
    Header: t('common.createDate'),
    Cell: ({ original }) => moment(original.created).format('YYYY-mm-DD hh:mm'),
  },
  {
    Header: t('common.users'),
    Cell: ({ original }) => original.users.map((user, index) => <div key={index}>{user.email}</div>),
  },
  {
    Header: t('common.parcels'),
    Cell: ({ original }) =>
      original.compartmentVersions.map((cv, index) => (
        <div key={index}>
          {cv.propertyId}:{cv.parcelNumber}&nbsp;
          <TableIcon
            iconTitle={t('common.downloadCompartments')}
            iconClassName="fa fa-cloud-download-alt"
            onClick={() => downloadCompartmentsFn(cv.compartmentVersionId)}
          />
        </div>
      )),
  },
  {
    Header: '',
    Cell: ({ original }) => (
      <TableIcon
        iconClassName="fa fa-pen-to-square"
        onClick={() => navigate(`edit-plan/${original.planId}`, { replace: true })}
      />
    ),
    width: 0,
  },
]

export const PlansTable = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { projectId } = useParams()
  const [plans] = useProjectPlans(projectId)

  const [{ loading }, downloadCompartmentsFn] = useAsyncFn(
    async compartmentVersionId => {
      try {
        const { url } = await createCompartmentsDownload(projectId, compartmentVersionId)
        const link = document.createElement('a')
        link.href = url
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (e) {
        // TODO
      }
    },
    [projectId],
  )

  if (!plans || loading) return <Spinner />

  if (!plans.length) return t('table.fallback.nothingToDisplay')

  return <HtmlTable data={plans} columns={getColumns(t, navigate, projectId, downloadCompartmentsFn)} />
}
