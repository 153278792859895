import React from 'react'
import classnames from 'classnames'
import style from './Head.module.scss'

export const Head = ({ columns, sortId, setSortId, sortOrder, setSortOrder, stickyHeader }) => (
  <thead
    className={classnames({
      [style['thead']]: true,
      [style['thead--sticky']]: stickyHeader,
    })}
  >
    <tr>
      {columns.map((column, index) => {
        const { id, Header, sortable, alignRight } = column

        return (
          <th
            key={index}
            className={classnames({
              [style['th']]: true,
              [style['th--right']]: alignRight,
            })}
          >
            <div className={style['th__border-rect']} />
            {Header && (
              <div
                className={style['cell-content']}
                onClick={() => {
                  if (!sortable) return
                  if (id === sortId) {
                    setSortOrder(!sortOrder)
                  } else {
                    setSortId(id)
                    setSortOrder(true)
                  }
                }}
              >
                {Header}
                {sortable && id && (
                  <i
                    className={classnames({
                      'fa fa-angle-down': true,
                      'fa-angle-up': id === sortId && sortOrder,
                      [style['sort-trigger']]: true,
                      [style['sort-trigger--active']]: id === sortId,
                    })}
                  />
                )}
              </div>
            )}
          </th>
        )
      })}
    </tr>
  </thead>
)
