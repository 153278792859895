import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import classnames from 'classnames'
import { Scrollbars } from 'react-custom-scrollbars'
import { TextInput } from '@project/components'
import style from './Options.module.scss'

const renderOptions = (options, values, activeValue, onChange, onClose) =>
  options.map(option => (
    <div
      key={option.value}
      className={classnames(style['options__item'], {
        [style['options__item--active']]: option.value === activeValue,
        [style['options__item--selected']]: values.includes(option.value),
      })}
      onClick={() => {
        if (!values.includes(option.value)) onChange([...values, option.value])
        onClose()
      }}
    >
      {option.label}
    </div>
  ))

export const Options = ({
  className,
  options,
  values,
  onChange,
  onClose,
  triggerContainer,
  filterable,
  filterFunction = (option, search) => option.label.startsWith(search),
  toggleOptionsLabel,
}) => {
  const [search, setSearch] = useState('')

  const suitable = options.filter(option => filterFunction(option, search))
  const unsuitable = options.filter(option => !filterFunction(option, search))

  const optionsContainerRef = useRef()
  useLayoutEffect(() => {
    const containerElement = optionsContainerRef.current
    if (containerElement && triggerContainer) {
      containerElement.style.minWidth = `${triggerContainer.getBoundingClientRect().width}px`
    }
  }, [optionsContainerRef, triggerContainer])

  const [activeValue, setActiveValue] = useState(values[0])
  useEffect(() => {
    const sortedOptions = [...suitable, ...unsuitable]

    const onKeyDown = event => {
      // arrow up
      if (event.keyCode === 38) {
        event.preventDefault()
        const index = sortedOptions.findIndex(o => o.value === activeValue) - 1
        if (index >= 0) setActiveValue(sortedOptions[index].value)
        return
      }

      // arrow down
      if (event.keyCode === 40) {
        event.preventDefault()
        const index = sortedOptions.findIndex(o => o.value === activeValue) + 1
        if (index < sortedOptions.length) setActiveValue(sortedOptions[index].value)
        return
      }

      // enter
      if (event.keyCode === 13) {
        event.preventDefault()
        if (!values.includes(activeValue)) onChange([...values, activeValue])
        onClose()
        return
      }

      // tab
      if (event.keyCode === 9) {
        onClose()
        return
      }

      // esc
      if (event.keyCode === 27) {
        event.stopPropagation()
        onClose()
      }
    }

    document.addEventListener('keydown', onKeyDown, false)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [suitable, unsuitable, values, activeValue, setActiveValue, onChange, onClose])

  return (
    <div className={classnames(style['options'], className)} ref={optionsContainerRef}>
      <Scrollbars autoHeight autoHeightMax="80vh">
        <div
          className={style['toggle-options']}
          onClick={() => {
            if (values.length === options.length) onChange([])
            else onChange(options.map(o => o.value))
            onClose()
          }}
        >
          {toggleOptionsLabel}
        </div>
        {filterable && (
          <div className={style['search']}>
            <TextInput onChange={e => setSearch(e.target.value)} icon={<i className="fa fa-search" />} autoFocus />
          </div>
        )}
        {renderOptions(suitable, values, activeValue, onChange, onClose)}
        <div className={style['unsuitable']}>{renderOptions(unsuitable, values, activeValue, onChange, onClose)}</div>
      </Scrollbars>
    </div>
  )
}
