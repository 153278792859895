import { uid } from 'react-uid'
import { useDataLoader } from '@project/api'
import { useProject } from './useProject'
import { getTreeCorrections } from '../../requests'

const hookId = uid({})

export const useTreeCorrections = projectId => {
  const [project] = useProject(projectId)
  const jobId = project?.jobId
  return useDataLoader(hookId, getTreeCorrections, [jobId])
}
