import React from 'react'
import { Dropdown } from '../Dropdown'
import { Trigger } from './partials/Trigger'
import { Options } from './partials/Options'

export const MultiSelect = ({
  label,
  options,
  values,
  onChange,
  tabIndex = 0,
  disabled = false,
  triggerClassName,
  optionsClassName,
  filterable,
  filterFunction,
  toggleOptionsLabel = 'Select all options',
}) => (
  <Dropdown
    renderTrigger={(opened, setOpened) => (
      <Trigger
        className={triggerClassName}
        label={label}
        options={options}
        values={values}
        onChange={onChange}
        disabled={disabled}
        tabIndex={disabled ? -1 : tabIndex}
        opened={opened}
        setOpened={setOpened}
      />
    )}
    renderContent={(triggerContainer, onClose) => {
      if (disabled) return null
      return (
        <Options
          className={optionsClassName}
          options={options}
          values={values}
          onChange={onChange}
          onClose={onClose}
          triggerContainer={triggerContainer}
          filterable={filterable}
          filterFunction={filterFunction}
          toggleOptionsLabel={toggleOptionsLabel}
        />
      )
    }}
    xAlignment="left"
    yAlignment="bottom"
  />
)
