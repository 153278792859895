import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import gh from 'latlon-geohash'
import * as turf from '@turf/turf'
import { useQueryParams } from '@project/routing'
import { GeoJSONSource, CircleLayer } from '@project/mapbox'
import { useTrunks, useTreeCorrections } from 'api'

const getTrunkCircleRadius = () => {
  const minR = 2 * Math.pow(1.575, 10 - 22)
  const maxR = 2 * Math.pow(1.575, 24 - 22)
  const heightExp = ['max', ['number', ['get', 'height']], 8]
  return ['interpolate', ['exponential', 1.575], ['zoom'], 5, ['*', minR, heightExp], 24, ['*', maxR, heightExp]]
}

export const RemovedTreesSubstrate = ({ zIndex }) => {
  const { projectId } = useParams()
  const [{ geohash }] = useQueryParams()
  const [trunks] = useTrunks(projectId)
  const [treeCorrections] = useTreeCorrections(projectId)
  const displayRemovedTrees = useSelector(state => state.editor.displayRemovedTrees)

  const commissions = treeCorrections?.commissions

  const geohashTrunks = useMemo(() => {
    if (!trunks || !geohash) return null
    const { sw, ne } = gh.bounds(geohash)
    return trunks.filter(({ lat, lng }) => lat >= sw.lat && lat <= ne.lat && lng >= sw.lon && lng <= ne.lon)
  }, [trunks, geohash])

  const geoJSON = useMemo(() => {
    if (!geohashTrunks || !commissions) return null
    const removedTreeIds = commissions.map(tree => tree.geohash)
    const removedTrunks = geohashTrunks.filter(({ lat, lng }) => removedTreeIds.includes(gh.encode(lat, lng, 12)))
    const features = removedTrunks.map(({ lat, lng, height }) => turf.point([lng, lat], { lat, lng, height }))
    return turf.featureCollection(features)
  }, [geohashTrunks, commissions])

  return (
    <>
      <GeoJSONSource data={geoJSON} dynamicData>
        <CircleLayer
          circleRadius={getTrunkCircleRadius()}
          circleColor="#000"
          visibility={displayRemovedTrees ? 'visible' : 'none'}
          dynamicLayout
          zIndex={zIndex}
        />
      </GeoJSONSource>
    </>
  )
}
