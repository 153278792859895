import React from 'react'
import classnames from 'classnames'
import { useMeasure } from 'react-use'
import { Scrollbars } from 'react-custom-scrollbars'
import ReactModal from 'react-modal'
import { Spinner } from '@project/components'
import styles from './Dialog.module.scss'

export const Dialog = ({ className, loading, children }) => {
  const [contentWrapperRef, { height }] = useMeasure()
  return (
    <ReactModal
      className={classnames(styles.dialog__content, className)}
      overlayClassName={styles.dialog__overlay}
      isOpen
    >
      <Scrollbars
        style={{ height, maxHeight: '95vh' }}
        // Force hide horizontal scrolling. We don't need it and sometimes it's visible when it's not needed.
        // See https://github.com/malte-wessel/react-custom-scrollbars/issues/246
        // https://github.com/SilvereGit/app-precision-forestry/issues/923
        renderTrackHorizontal={() => <div className={styles['dialog__hide-scroll']} />}
      >
        <div ref={contentWrapperRef}>
          <div className={styles['dialog__scrollbars-content']}>{children}</div>
        </div>
      </Scrollbars>
      {!!loading && <Spinner />}
    </ReactModal>
  )
}
