import React from 'react'
import classnames from 'classnames'
import styles from './ControlLine.module.scss'

export const ControlLine = ({ combination, description, active }) => (
  <div className={styles['control-line']}>
    <span
      className={classnames(styles['control-line__combination'], {
        [styles['control-line__combination--active']]: active,
        [styles['control-line__combination--single-key']]: combination.length === 1,
      })}
    >
      {combination}
    </span>
    <span className={styles['control-line__description']}>{description}</span>
  </div>
)
