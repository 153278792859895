import React, { useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as turf from '@turf/turf'
import gh from 'latlon-geohash'
import { useQueryParams } from '@project/routing'
import { CircleLayer, GeoJSONSource, useMap } from '@project/mapbox'
import { setCoordinate, reset } from '../../../../../../../../redux/ducks/tree'
import { setTreePointActive } from '../../../../../../../../redux/ducks/editor'
import { MODES } from '../../../../../../../../utils/modes'

export const TreePoint = ({ zIndex }) => {
  const map = useMap()
  const dispatch = useDispatch()
  const coordinate = useSelector(state => state.tree.coordinate)
  const treePointActive = useSelector(state => state.editor.treePointActive)
  const editorEnabled = useSelector(state => state.editor.editorEnabled)
  const mode = useSelector(state => state.editor.mode)
  const [queryParams, setQueryParams] = useQueryParams()
  const { geohash } = queryParams

  const treePoint = useMemo(() => coordinate && turf.point(coordinate), [coordinate])

  useEffect(() => {
    if (coordinate && geohash) {
      const [lng, lat] = coordinate
      const coordinateGeohash = gh.encode(lat, lng, geohash.length)
      if (coordinateGeohash !== geohash) setQueryParams({ ...queryParams, geohash: coordinateGeohash })
    }
  }, [coordinate, geohash, queryParams, setQueryParams])

  useEffect(() => {
    let downPoint = null

    const onMouseDown = event => {
      downPoint = event.point
    }

    const onMouseUp = event => {
      if (!editorEnabled || mode !== MODES.ADD) return
      if (coordinate || !geohash || !downPoint) return
      const upPoint = event.point
      if (Math.abs(upPoint.x - downPoint.x) > 5 || Math.abs(upPoint.y - downPoint.y) > 5) return

      const { lng, lat } = event.lngLat
      const coordinateGeohash = gh.encode(lat, lng, geohash.length)
      if (coordinateGeohash === geohash) dispatch(setCoordinate([lng, lat]))
    }

    if (!coordinate) {
      map.on('mousedown', onMouseDown)
      map.on('mouseup', onMouseUp)
    }

    return () => {
      map.off('mousedown', onMouseDown)
      map.off('mouseup', onMouseUp)
    }
  }, [map, coordinate, editorEnabled, mode, geohash, dispatch])

  useEffect(() => {
    const onMouseMove = event => {
      const { lng, lat } = event.lngLat
      if (editorEnabled) dispatch(setCoordinate([lng, lat]))
      else dispatch(setTreePointActive(false))
    }

    if (treePointActive) {
      map.dragPan.disable()
      map.on('mousemove', onMouseMove)
    }

    return () => {
      map.dragPan.enable()
      map.off('mousemove', onMouseMove)
    }
  }, [map, treePointActive, editorEnabled, dispatch])

  if (!treePoint) return null

  return (
    <>
      <GeoJSONSource data={treePoint} dynamicData>
        <CircleLayer
          circleColor="purple"
          circleRadius={5}
          visibility={treePointActive ? 'none' : 'visible'}
          zIndex={zIndex}
          dynamicLayout
        />
        <CircleLayer
          circleColor="transparent"
          circleRadius={10}
          onMouseDown={() => {
            if (mode === MODES.REMOVE) dispatch(reset())
            else dispatch(setTreePointActive(true))
          }}
          onMouseUp={() => dispatch(setTreePointActive(false))}
          dynamicCallbacks
          zIndex={zIndex + 0.1}
        />
      </GeoJSONSource>
    </>
  )
}
