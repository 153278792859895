import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'
import { HtmlTable, Spinner, Tooltip, CopyToClipboard } from '@project/components'
import { TableIcon } from 'components/common/TableIcon'
import { ProjectStatus } from './partials/ProjectStatus'
import style from './ProjectsTable.module.scss'

const getColumns = (detailed, navigate, t) => {
  const mainColumns = [
    {
      id: 'parcel',
      Header: t('common.parcel'),
      sortable: true,
      accessor: d => `${d.propertyId}:${d.parcelNumber}`,
      Cell: ({ value }) => value,
      noWrap: true,
    },
    {
      Header: t('common.projectId'),
      Cell: ({ original }) => (
        <CopyToClipboard textToCopy={original.projectId}>
          <NavLink to={`/pipelines/${original.projectId}`}>{original.projectId}</NavLink>
        </CopyToClipboard>
      ),
      link: true,
    },
    {
      Header: t('common.projectNumber'),
      Cell: ({ original }) => original.projectNumber,
    },
    {
      Header: t('common.ha'),
      Cell: ({ original }) => {
        const area = original.area / 10000
        return isNaN(area) ? '—' : area.toFixed(2)
      },
    },
  ]

  const detailsColumns = [
    {
      id: 'customer',
      Header: t('common.customer'),
      sortable: true,
      accessor: d => d.customer?.name || '—',
      Cell: ({ value }) => value,
    },
    {
      id: 'order-number',
      Header: t('common.orderNumber'),
      sortable: true,
      accessor: d => d.order?.orderNumber || 0,
      Cell: ({ value }) => value || '—',
    },
  ]

  const statusColumn = {
    id: 'status',
    sortable: true,
    accessor: d => d.status.sort,
    Header: t('common.projectStatus'),
    Cell: ({ original }) => <ProjectStatus project={original} />,
  }

  const commentColumn = {
    Header: '',
    Cell: ({ original }) => {
      const { notes } = original
      if (!notes) return ''
      return (
        <Tooltip
          trigger={
            <div className={style['trigger']}>
              <i className="fa-solid fa-comment-dots" />
            </div>
          }
          content={notes}
        />
      )
    },
  }

  const editColumn = {
    Header: '',
    Cell: ({ original }) => (
      <TableIcon iconClassName="fa fa-pen-to-square" onClick={() => navigate(`edit-project/${original.projectId}`)} />
    ),
  }

  return [...mainColumns, ...(detailed ? detailsColumns : []), statusColumn, commentColumn, editColumn]
}

const comparator = (a, b) => a.propertyId.localeCompare(b.propertyId)

export const ProjectsTable = ({ data, detailed = true }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  if (!data) return <Spinner />

  if (data.length === 0) return t('table.fallback.nothingToDisplay')

  return <HtmlTable data={data.sort(comparator)} columns={getColumns(detailed, navigate, t)} stickyHeader />
}
