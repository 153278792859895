import { Auth } from '@aws-amplify/auth'

export const getToken = async () => {
  const session = await Auth.currentSession()
  return session.getIdToken().getJwtToken()
}

class TokenHelper {
  super() {
    this.session = null
  }

  async preWarm() {
    await this.updateSession()
  }

  async updateSession() {
    const session = await Auth.currentSession()
    this.session = session
    setTimeout(this.updateSession.bind(this), session.getIdToken().getExpiration() * 1000 - Date.now() - 10000)
  }

  getToken() {
    const { session, updateSession } = this
    if (!session || !session.isValid()) {
      updateSession()
      return null
    }
    return session.getIdToken().getJwtToken()
  }
}

export const tokenHelper = new TokenHelper()
