export const DEVELOPMENT_CLASS = Object.freeze({
  CLASS_A0: 'A0',
  CLASS_S0: 'S0',
  CLASS_T1: 'T1',
  CLASS_T2: 'T2',
  CLASS_02: '02',
  CLASS_03: '03',
  CLASS_04: '04',
  CLASS_05: '05',
  CLASS_ER: 'ER',
  CLASS_Y1: 'Y1',
})

export const developmentClassColorSchemeIds = {
  [DEVELOPMENT_CLASS.CLASS_A0]: 'legend-ont-7',
  [DEVELOPMENT_CLASS.CLASS_S0]: 'legend-ont-9',
  [DEVELOPMENT_CLASS.CLASS_T1]: 'legend-ont-1',
  [DEVELOPMENT_CLASS.CLASS_T2]: 'legend-ont-2',
  [DEVELOPMENT_CLASS.CLASS_02]: 'legend-ont-3',
  [DEVELOPMENT_CLASS.CLASS_03]: 'legend-ont-4',
  [DEVELOPMENT_CLASS.CLASS_04]: 'legend-ont-5',
  [DEVELOPMENT_CLASS.CLASS_05]: 'legend-ont-6',
  [DEVELOPMENT_CLASS.CLASS_ER]: 'legend-ont-8',
  [DEVELOPMENT_CLASS.CLASS_Y1]: 'legend-ont-10',
}
