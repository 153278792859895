import { useRef, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateData } from '../redux/ducks/api'

export const useDataLoader = (hookId, getDataFunction, args) => {
  const dataId = `${hookId}/${JSON.stringify(args)}`
  const dispatch = useDispatch()
  const item = useSelector(state => state.api.items[dataId])
  const loading = useSelector(state => state.api.loading[dataId])
  const error = useSelector(state => state.api.error[dataId])
  const progress = useSelector(state => state.api.progress[dataId])

  const getDataFunctionRef = useRef(getDataFunction)
  const argsRef = useRef(args)

  useEffect(() => {
    getDataFunctionRef.current = getDataFunction
  }, [getDataFunction, getDataFunctionRef])

  useEffect(() => {
    argsRef.current = args
  }, [args, argsRef])

  useEffect(() => {
    const currentGetDataFunction = getDataFunctionRef.current
    const currentArgs = argsRef.current
    if (item === undefined && dataId && currentGetDataFunction && currentArgs) {
      dispatch(updateData(dataId, () => currentGetDataFunction(...currentArgs)))
    }
  }, [item, dataId, getDataFunctionRef, argsRef, dispatch])

  const refresh = useCallback(
    (force, silent) => {
      const currentGetDataFunction = getDataFunctionRef.current
      const currentArgs = argsRef.current
      if (dataId && currentGetDataFunction && currentArgs) {
        dispatch(updateData(dataId, () => currentGetDataFunction(...currentArgs), force, silent))
      }
    },
    [dataId, getDataFunctionRef, argsRef, dispatch],
  )

  return [item, loading, error, progress, refresh]
}
