import React from 'react'
import ReactDOM from 'react-dom'
import classnames from 'classnames'
import { usePaneUtils } from '../../../Pointcloud3D'
import style from './Bar.module.scss'

export const Bar = ({ className, children }) => {
  const paneUtils = usePaneUtils()

  return ReactDOM.createPortal(
    <div className={classnames(style['container'], className)}>
      <div className={style['bar']}>{children}</div>
    </div>,
    paneUtils.getPaneContainer(),
  )
}
