export default {
  caption: {
    // species
    SPECIES__PINUS_SYLVESTRIS: 'Mänty',
    SPECIES__PICEA_ABIES: 'Kuusi',
    SPECIES__POPULUS_TREMULA: 'Haapa',
    SPECIES__BETULA_SPP: 'Koivu',
    SPECIES__BETULA_PENDULA: 'Rauduskoivu',
    SPECIES__BETULA_PUBESCENS: 'Hieskoivu',
    SPECIES__ALNUS_INCANA: 'Harmaaleppä',
    SPECIES__ALNUS_GLUTINOSA: 'Tervaleppä',
    SPECIES__OTHER_CONIFEROUS: 'Muu havupuu',
    SPECIES__OTHER_DECIDUOUS: 'Muu lehtipuu',
    SPECIES__PSEUDOTSUGA_MENZIESII: 'Douglaskuusi',
    SPECIES__JUNIPERUS_COMMUNIS: 'Kataja',
    SPECIES__PINUS_CONTORTA: 'Kontortamänty',
    SPECIES__ULMUS_LAEVIS: 'Kynäjalava',
    SPECIES__LARIX_SPP: 'Lehtikuusi',
    SPECIES__TILIA_CORDATA: 'Metsälehmus',
    SPECIES__PICEA_MARIANA: 'Mustakuusi',
    SPECIES__SALIX_SPP: 'Paju (lajit)',
    SPECIES__SORBUS_AUCUPARIA: 'Pihlaja',
    SPECIES__ABIES_SPP: 'Pihta (lajit)',
    SPECIES__SALIX_CAPREA: 'Raita',
    SPECIES__FRAXINUS_EXCELSIOR: 'Saarni',
    SPECIES__PINUS_CEMBRA: 'Sembramänty',
    SPECIES__PICEA_OMORIKA: 'Serbiankuusi',
    SPECIES__QUERCUS_ROBUR: 'Tammi',
    SPECIES__PRUNUS_PADUS: 'Tuomi',
    SPECIES__ACER_PLATANOIDES: 'Vaahtera',
    SPECIES__BETULA_PENDULA_VAR_CARELICA: 'Visakoivu',
    SPECIES__ULMUS_GLABRA: 'Vuorijalava',
    SPECIES__DECIDUOUS: 'Lehtipuu',
    SPECIES__CONIFEROUS: 'Havupuu',
    SPECIES__PINUS: 'Mänty (lajit)',
    SPECIES__PICEA: 'Kuusi (lajit)',
    SPECIES__BETULA: 'Koivu (lajit)',
    SPECIES__PSEUDOTSUGA: 'Douglaskuusi (lajit)',
    SPECIES__JUNIPERUS: 'Kataja (lajit)',
    SPECIES__ULMUS: 'Jalava (lajit)',
    SPECIES__LARIX: 'Lehtikuusi (lajit)',
    SPECIES__TILIA: 'Lehmus (lajit)',
    SPECIES__SALIX: 'Paju (lajit)',
    SPECIES__SORBUS: 'Pihlaja (lajit)',
    SPECIES__FRAXINUS: 'Saarni (lajit).',
    SPECIES__QUERCUS: 'Tammi (lajit)',
    SPECIES__PRUNUS: 'Tuomi (lajit)',
    SPECIES__ACER: 'Vaahtera (lajit)',
    SPECIES__CARPINUS: 'Valkopyökki (lajit)',
    SPECIES__CORNUS: 'Kanukka (lajit)',
    SPECIES__CORYLUS: 'Pähkinäpensas (lajit)',
    SPECIES__CRATAEGUS: 'Orapihlaja (lajit)',
    SPECIES__MALUS: 'Omenapuu (lajit)',
    SPECIES__SAMBUCUS: 'Selja (lajit)',
    SPECIES__FAGUS: 'Pyökki (lajit)',
    SPECIES__POPULUS: 'Haapa (lajit)',
    SPECIES__ABIES: 'Pihta (lajit)',
    SPECIES__ALNUS: 'Leppä (lajit)',
    SPECIES__UNKNOWN: 'Tuntematon',
    // development classes
    DEVELOPMENT_CLASS__CLASS_02: '02',
    DEVELOPMENT_CLASS__CLASS_03: '03',
    DEVELOPMENT_CLASS__CLASS_04: '04',
    DEVELOPMENT_CLASS__CLASS_05: '05',
    DEVELOPMENT_CLASS__CLASS_A0: 'A0',
    DEVELOPMENT_CLASS__CLASS_ER: 'ER',
    DEVELOPMENT_CLASS__CLASS_S0: 'S0',
    DEVELOPMENT_CLASS__CLASS_T1: 'T1',
    DEVELOPMENT_CLASS__CLASS_T2: 'T2',
    DEVELOPMENT_CLASS__CLASS_Y1: 'Y1',
    // accessibility
    ACCESSIBILITY__ALL_YEAR: 'Ympärivuotinen',
    ACCESSIBILITY__NOT_DURING_FROST_HEAVE: 'Myös sulan maan, mutta ei kelirikon aikana',
    ACCESSIBILITY__DRY_SUMMER: 'Myös sulan maan aikana kuivana kautena',
    ACCESSIBILITY__WINTER: 'Vain kun maa on jäässä',
    ACCESSIBILITY__NOT_DEFINED: 'Ei määritelty',
    // cutting
    CUTTING__REST: 'Lepo',
    CUTTING__UPPER_TREE_REMOVAL: 'Ylispuiden poisto',
    CUTTING__FIRST_THINNING: 'Ensiharvennus',
    CUTTING__THINNING: 'Harvennus',
    CUTTING__STRIP_CUT: 'Kaistalehakkuu',
    CUTTING__CLEAR_CUT: 'Avohakkuu',
    CUTTING__SHELTERWOOD_CUT: 'Suojuspuuhakkuu',
    CUTTING__SEED_TREE_CUT: 'Siemenpuuhakkuu',
    CUTTING__UPPER_THINNING: 'Yläharvennus',
    CUTTING__PATCH_CUT: 'Pienaukkohakkuu',
    CUTTING__TENDING_OF_SAPLING_STAND: 'Taimikonhoito',
    CUTTING__THINNING_OF_SAPLING_STAND: 'Taimikonharvennus',
    CUTTING__UNDERSTOREY_CLEARING: 'Ennakkoraivaus',
    //
    CUTTING__REGENERATION_NATURAL_PINE: 'Männyn luontainen uudistaminen',
    CUTTING__REGENERATION_NATURAL_SPRUCE: 'Kuusen luontainen uudistaminen',
    CUTTING__REGENERATION_NATURAL_BENDULA: 'Rauduskoivun luontainen uudistaminen',
    CUTTING__REGENERATION_NATURAL_PUBESCENS: 'Hieskoivun luontainen uudistaminen',
    //
    CUTTING__REGENERATION_INSEMINATION_PINE: 'Männyn kylvö',
    CUTTING__REGENERATION_INSEMINATION_SPRUCE: 'Kuusen kylvö',
    CUTTING__REGENERATION_INSEMINATION_BENDULA: 'Rauduskoivun kylvö',
    CUTTING__REGENERATION_INSEMINATION_PUBESCENS: 'Hieskoivun kylvö',
    //
    CUTTING__REGENERATION_PLANTING_PINE: 'Männyn istutus',
    CUTTING__REGENERATION_PLANTING_SPRUCE: 'Kuusen istutus',
    CUTTING__REGENERATION_PLANTING_BENDULA: 'Rauduskoivun istutus',
    CUTTING__REGENERATION_PLANTING_PUBESCENS: 'Hieskoivun istutus',
    //
    CUTTING__SOIL_PREPARATION_NOT_NEEDED: 'Ei tarvetta maanmuokkaukselle',
    CUTTING__SOIL_PREPARATION_GENERAL: 'Maanmuokkaus',
    CUTTING__SOIL_PREPARATION_SCALPING: 'Laikutus',
    CUTTING__SOIL_PREPARATION_MOUNDING: 'Mätästys',
    CUTTING__SOIL_PREPARATION_HARROWING: 'Äestys',
    CUTTING__SOIL_PREPARATION_PLOUGHING: 'Auraus',
    // drainage state
    DRAINAGE_STATE__NOT_DRAINED_MINERAL_LAND: 'Ojittamaton kangas',
    DRAINAGE_STATE__PALUDIFIED_MINERAL_LAND: 'Soistunut kangas',
    DRAINAGE_STATE__DRAINED_MINERAL_LAND: 'Ojitettu kangas',
    DRAINAGE_STATE__PEATLAND: 'Luonnontilainen suo',
    DRAINAGE_STATE__DITCHED_PEATLAND: 'Ojikko',
    DRAINAGE_STATE__CHANGED_PEATLAND: 'Muuttuma',
    DRAINAGE_STATE__DRAINED_PEATLAND: 'Turvekangas',
    // fertility classes:
    FERTILITY_CLASS__GROVE: 'Lehto',
    FERTILITY_CLASS__GROVY: 'Lehtomainen kangas',
    FERTILITY_CLASS__MESIC: 'Tuore kangas',
    FERTILITY_CLASS__DRYISH: 'Kuivahko kangas',
    FERTILITY_CLASS__DRY: 'Kuiva kangas',
    FERTILITY_CLASS__BARREN: 'Karukkokangas',
    FERTILITY_CLASS__ROCKY: 'Kitumaa',
    FERTILITY_CLASS__FELL: 'Joutomaa',
    FERTILITY_CLASS__UNKNOWN: 'Tuntematon',
    // main group
    MAIN_GROUP__MINERAL: 'Kangas',
    MAIN_GROUP__SPRUCE_MIRE: 'Korpi',
    MAIN_GROUP__PINE_MIRE: 'Räme',
    MAIN_GROUP__OPEN_BOG: 'Neva',
    MAIN_GROUP__OPEN_FEN: 'Letto',
    // operation urgency
    OPERATION_URGENCY__URGENT: 'Kiireellinen',
    OPERATION_URGENCY__FIRST_FIVE_YEARS: '1-5 vuoden päästä',
    OPERATION_URGENCY__SECOND_FIVE_YEARS: '6-10 vuoden päästä',
    OPERATION_URGENCY__THIRD_FIVE_YEARS: '11-15 vuoden päästä',
    OPERATION_URGENCY__FOURTH_FIVE_YEARS: '16-20 vuoden päästä',
    OPERATION_URGENCY__REST: 'Jätetään lepoon, ei käsitellä talouskaudella',
    OPERATION_URGENCY__IN_FINANCE_SEASON: 'Voidaan käsitellä talouskaudella',
    OPERATION_URGENCY__IN_END_OF_FINANCE_SEASON: 'Voidaan käsitellä talouskauden lopulla',
    // soil type
    SOIL_TYPE__COARSE_GRAINED_MINERAL_SOIL: 'Keskikarkea tai karkea kangasmaa',
    SOIL_TYPE__COARSE_GRAINED_MORAINE: 'Karkea moreeni',
    SOIL_TYPE__COARSE_GRAINED_SORTED_SOIL: 'Karkea lajittunut maalaji',
    SOIL_TYPE__FINE_GRAINED_MINERAL_SOIL: 'Hienojakoinen kangasmaa',
    SOIL_TYPE__FINE_GRAINED_MORAINE: 'Hienoainesmoreeni',
    SOIL_TYPE__FINE_GRAINED_SORTED_SOIL: 'Hienojakoinen lajittunut maalaji',
    SOIL_TYPE__STONY_COARSE_GRAINED_MINERAL_SOIL: 'Kivinen keskikarkea tai karkea kangasmaa',
    SOIL_TYPE__STONY_COARSE_GRAINED_MORAINE: 'Kivinen karkea moreeni',
    SOIL_TYPE__STONY_COARSE_GRAINED_SORTED_SOIL: 'Kivinen karkea lajittunut maalaji',
    SOIL_TYPE__STONY_FINE_GRAINED_MINERAL_SOIL: 'Kivinen hienojakoinen kangasmaa',
    SOIL_TYPE__ROCK_OR_ROCK_FIELD: 'Kallio tai kivikko',
    SOIL_TYPE__PEAT: 'Turvemaa',
    SOIL_TYPE__CAREX_PEAT: 'Saraturve',
    SOIL_TYPE__SPHAGNUM_PEAT: 'Rahkaturve',
    SOIL_TYPE__WOODY_PEAT: 'Puuvaltainen turve',
    SOIL_TYPE__CAREX_PEAT_VON_POST_OVER5: 'Eroosioherkkä saraturve (von Post luokka yli 5)',
    SOIL_TYPE__SPHAGNUM_PEAT_VON_POST_OVER5: 'Eroosioherkkä rahkaturve (von Post luokka yli 5)',
    SOIL_TYPE__CAREX_PEAT_VON_POST_UNDER5: 'Maatumaton saraturve (von Post luokka enintään 5)',
    SOIL_TYPE__SPHAGNUM_PEAT_VON_POST_UNDER5: 'Maatumaton rahkaturve (von Post luokka enintään 5)',
    SOIL_TYPE__MOULD: 'Multamaa',
    SOIL_TYPE__SLUDGE: 'Liejumaa',
    // storey
    STOREY__DOMINANT: 'Vallitseva jakso',
    STOREY__LOWER: 'Alempi jakso',
    STOREY__UPPER: 'Ylempi jakso',
    // planted
    PLANTED__TRUE: 'Istutettu',
    PLANTED__FALSE: 'Ei istutettu',
    // uneven aged
    UNEVEN_AGED__TRUE: 'Eri-ikäisrakenteinen',
    UNEVEN_AGED__FALSE: 'Ei eri-ikäisrakenteinen',
  },

  description: {
    // development classes
    DEVELOPMENT_CLASS__CLASS_02: 'Nuori kasvatusmetsikkö',
    DEVELOPMENT_CLASS__CLASS_03: 'Varttunut kasvatusmetsikkö',
    DEVELOPMENT_CLASS__CLASS_04: 'Uudistuskypsä metsikkö',
    DEVELOPMENT_CLASS__CLASS_05: 'Suojuspuumetsikkö',
    DEVELOPMENT_CLASS__CLASS_A0: 'Aukea',
    DEVELOPMENT_CLASS__CLASS_ER: 'Eri-ikäisrakenteinen metsikkö',
    DEVELOPMENT_CLASS__CLASS_S0: 'Siemenpuumetsikkö',
    DEVELOPMENT_CLASS__CLASS_T1: 'Taimikko alle 1,3 m',
    DEVELOPMENT_CLASS__CLASS_T2: 'Taimikko yli 1,3 m',
    DEVELOPMENT_CLASS__CLASS_Y1: 'Ylispuustoinen taimikko',
  },
}
