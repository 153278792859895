import React, { useMemo } from 'react'
import * as turf from '@turf/turf'
import gh from 'latlon-geohash'
import { GeoJSONSource, CircleLayer } from '@project/mapbox'
import { useParams } from 'react-router-dom'
import { useQueryParams } from '@project/routing'
import { getEntityColor } from '@project/ontology'
import { useTrunks, useTreeCorrections } from 'api'

const getTrunkCircleColor = () => ['get', 'color']

const getTrunkCircleRadius = () => {
  const minR = 2 * Math.pow(1.575, 10 - 22)
  const maxR = 2 * Math.pow(1.575, 24 - 22)
  const heightExp = ['max', ['number', ['get', 'height']], 8]
  return ['interpolate', ['exponential', 1.575], ['zoom'], 5, ['*', minR, heightExp], 24, ['*', maxR, heightExp]]
}

export const TrunksSubstrate = ({ zIndex }) => {
  const { projectId } = useParams()
  const [{ geohash }] = useQueryParams()
  const [trunks] = useTrunks(projectId)
  const [treeCorrections] = useTreeCorrections(projectId)

  const geoJSON = useMemo(() => {
    if (!trunks || !trunks.length || !geohash || !treeCorrections) return null

    const neighbors = gh.neighbours(geohash)
    const { sw } = gh.bounds(neighbors.sw)
    const { ne } = gh.bounds(neighbors.ne)

    const removedTreeIds = treeCorrections.commissions.map(item => item.geohash)

    const features = trunks
      .filter(({ lat, lng }) => lat > sw.lat && lat < ne.lat && lng > sw.lon && lng < ne.lon)
      .map(trunk => {
        const { lat, lng, height, dbh, value, species } = trunk
        const color = getEntityColor(species)
        const id = gh.encode(lat, lng, 12)
        return turf.point([lng, lat], { color, height, dbh, value, species, id })
      })
      .filter(item => !removedTreeIds.includes(item.properties.id))
    return turf.featureCollection(features)
  }, [trunks, treeCorrections, geohash])

  if (!geoJSON) return null

  return (
    <GeoJSONSource data={geoJSON} dynamicData>
      <CircleLayer circleRadius={getTrunkCircleRadius()} circleColor={getTrunkCircleColor()} zIndex={zIndex} />
    </GeoJSONSource>
  )
}
