import React from 'react'
import classnames from 'classnames'
import './Tabs.scss'

export const Tabs = ({ className, secondary, replace, children }) => (
  <div className={classnames('tabs', className, { 'tabs--secondary': secondary })}>
    {React.Children.toArray(children)
      .filter(child => child)
      .map(child => React.cloneElement(child, { secondary, replace }))}
  </div>
)
