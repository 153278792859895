import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { Pointcloud3D, Points, Progress } from '@project/pointcloud3D'
import { Select } from '@project/components'
import { useScanSlicePointcloud, useScanSlices } from '../../../../../api'
import style from './PointcloudSliceReview.module.scss'
import { SSAOControl } from './partials/SSAOControl'
import { OrbitControls } from './partials/OrbitControls'

export const PointcloudSliceReview = () => {
  const { projectId } = useParams()
  const [slices] = useScanSlices(projectId)

  const [scanId, setScanId] = useState(null)
  const [key, setKey] = useState(null)
  const [tile] = useScanSlicePointcloud(projectId, scanId, encodeURIComponent(key))

  const scanIds = slices?.filter(item => item.slices.keys.length).map(item => item.scanId)
  const keys = slices?.find(item => item.scanId === scanId)?.slices.keys

  useEffect(() => {
    if (scanIds && !scanId) setScanId(scanIds[0])
    if (keys && !key) setKey(keys[0])
  }, [scanIds, keys, scanId, key])

  return (
    <div>
      <div className={style['inputs']}>
        <Select
          label="Scan ID"
          options={scanIds?.map(id => ({ label: id, value: id })) || []}
          value={scanId}
          onChange={value => setScanId(value)}
        />
        <Select
          label="Key"
          options={keys?.map(k => ({ label: k, value: k })) || []}
          value={key}
          onChange={value => setKey(value)}
        />
      </div>
      <div className={style['content']}>
        <Pointcloud3D tile={tile}>
          <Points pointSize={0.00065} gpsTimeVisualization />
          <SSAOControl />
          <OrbitControls />
          <Progress />
        </Pointcloud3D>
      </div>
    </div>
  )
}
