import React from 'react'
import { TreePoint } from './partials/TreePoint'
import { CrownRadiusCircle } from './partials/CrownRadiusCircle'
import { SpeciesShortcuts } from './partials/SpeciesShortcuts'
import { SaveTreeShortcuts } from './partials/SaveTreeShortcuts'

export const CreateTreeSubstrate = ({ zIndex }) => (
  <>
    <TreePoint zIndex={zIndex} />
    <CrownRadiusCircle zIndex={zIndex + 0.1} />
    <SpeciesShortcuts />
    <SaveTreeShortcuts />
  </>
)
