import React from 'react'
import classnames from 'classnames'
import './MessagePoster.scss'

export const MessagePoster = ({ className, imageSrc, title, description, action }) => (
  <div className={classnames('message-poster', className)}>
    {imageSrc ? <img className="message-poster__image" src={imageSrc} alt={title} /> : null}
    <div className="message-poster__title">{title}</div>
    <div className="message-poster__description">{description}</div>
    <div className="message-poster__action">{action}</div>
  </div>
)
