export const PLANTED = Object.freeze({
  TRUE: 'ONTOLOGY_PLANTED_TRUE',
  FALSE: 'ONTOLOGY_PLANTED_FALSE',
})

export const plantedColorSchemeIds = {
  [PLANTED.TRUE]: 'legend-ont-1',
  [PLANTED.FALSE]: 'legend-ont-3',
}

export const getPlantedEntityByValue = value => {
  if (value === true) return PLANTED.TRUE
  if (value === false) return PLANTED.FALSE
  return null
}

export const getPlantedValueByEntity = entity => {
  if (entity === PLANTED.TRUE) return true
  if (entity === PLANTED.FALSE) return false
  return null
}
