import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMap } from '../../map/MapGL'
import style from './CtrlScrollZoomControl.module.scss'

export const CtrlScrollZoomControl = () => {
  const { t } = useTranslation()
  const map = useMap()

  useEffect(() => {
    const div = document.createElement('div')
    div.className = style['control']
    const text = document.createTextNode(t('ctrl-scroll-zoom-control:message'))
    div.appendChild(text)

    let timeoutId = null

    const onWheel = () => {
      if (map.scrollZoom.isEnabled()) return
      div.classList.add(style['control--active'])
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => div.classList.remove(style['control--active']), 1000)
    }

    const onKeyDown = event => {
      if (event.keyCode === 17 || event.keyCode === 91) {
        map.scrollZoom.enable()
        div.classList.remove(style['control--active'])
      }
    }

    const onKeyUp = event => {
      if (event.keyCode === 17 || event.keyCode === 91) {
        map.setZoom(map.getZoom())
        map.scrollZoom.disable()
      }
    }

    if (map) {
      map.scrollZoom.disable()
      map.getContainer().appendChild(div)
      map.on('wheel', onWheel)
      document.addEventListener('keydown', onKeyDown)
      document.addEventListener('keyup', onKeyUp)
    }

    return () => {
      if (map) {
        map.getContainer().removeChild(div)
        map.off('wheel', onWheel)
        window.removeEventListener('keydown', onKeyDown)
        window.removeEventListener('keyup', onKeyUp)
      }
    }
  }, [map, t])

  return null
}
