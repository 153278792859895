export const STOREY = Object.freeze({
  DOMINANT: 1001,
  LOWER: 1002,
  UPPER: 1003,
})

export const storeyColorSchemeIds = {
  [STOREY.DOMINANT]: 'legend-ont-1',
  [STOREY.LOWER]: 'legend-ont-2',
  [STOREY.UPPER]: 'legend-ont-3',
}
