import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as THREE from 'three'
import gh from 'latlon-geohash'
import * as turf from '@turf/turf'
import { useQueryParams } from '@project/routing'
import { usePaneUtils, useTile } from '@project/pointcloud3D'
import { getPosition } from '../../utils/position'

export const getRadius = (crownRadius, geohash, bbox) => {
  const { minX, maxX } = bbox
  const { sw, ne } = gh.bounds(geohash)
  const p1 = turf.point([ne.lon, sw.lat])
  const p2 = turf.point([sw.lon, sw.lat])
  const dx = turf.distance(p1, p2, { units: 'kilometers' }) * 1000
  return (crownRadius * (maxX - minX)) / dx
}

export const TreeCrown = () => {
  const paneUtils = usePaneUtils()
  const [{ geohash }] = useQueryParams()
  const tile = useTile()
  const coordinate = useSelector(state => state.tree.coordinate)
  const crownRadius = useSelector(state => state.tree.crownRadius)
  const { normalizedBBox: bbox } = tile || {}

  const [cylinder, setCylinder] = useState(null)

  useEffect(() => {
    if (!cylinder) {
      const geometry = new THREE.CylinderGeometry(0, 0, 1, 50, 8, true)
      const materialOptions = { color: 0x800080, transparent: true, opacity: 0.3, side: THREE.DoubleSide }
      const material = new THREE.MeshBasicMaterial(materialOptions)
      const mesh = new THREE.Mesh(geometry, material)
      setCylinder(mesh)
    }
  }, [cylinder, paneUtils])

  useEffect(() => {
    if (cylinder && bbox) {
      cylinder.geometry.height = bbox.maxZ - bbox.minZ
      paneUtils.render()
    }
  }, [cylinder, bbox, paneUtils])

  useEffect(() => {
    if (coordinate && crownRadius && bbox && cylinder) {
      const [lng, lat] = coordinate
      const { x, y, z } = getPosition(lat, lng, geohash, bbox)
      cylinder.position.set(x, y, z)
      const outside = x < bbox.minX || x > bbox.maxX || y < bbox.minY || y > bbox.maxY
      if (outside) paneUtils.remove(cylinder)
      else paneUtils.add(cylinder)
      paneUtils.render()
    }

    return () => {
      paneUtils.remove(cylinder)
      paneUtils.render()
    }
  }, [coordinate, crownRadius, geohash, bbox, cylinder, paneUtils])

  useEffect(() => {
    if (crownRadius && bbox && cylinder) {
      const radius = getRadius(crownRadius, geohash, bbox)
      cylinder.geometry.dispose()
      cylinder.geometry = new THREE.CylinderGeometry(radius, radius, 1, 50, 8, true)
      cylinder.geometry.rotateX(Math.PI * 0.5)
      paneUtils.render()
    }
  }, [crownRadius, geohash, bbox, cylinder, paneUtils])

  return null
}
