import React from 'react'
import classnames from 'classnames'
import { PIPELINE_STATUS } from 'utils/pipelineStatus'
import style from './PipelineStatus.module.scss'

const { BLOCKED, UNPROCESSED, PROCESSING, FAIL, PROCESSED } = PIPELINE_STATUS

export const PipelineStatus = ({ status }) => (
  <div
    className={classnames({
      [style['status']]: true,
      [style['unprocessed']]: status === UNPROCESSED,
      [style['processing']]: status === PROCESSING,
      [style['processed']]: status === PROCESSED,
      [style['error']]: status === FAIL || status === BLOCKED,
    })}
  >
    {status}
  </div>
)
