import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import { PATHS } from '../../../../../../util/paths'
import { login } from '../../../../../../redux/ducks/auth'
import { validateEmail, validateRequired } from '../../util/validate'
import { AuthInput } from '../../elements/AuthInput'
import { AuthButton } from '../../elements/AuthButton'
import { AuthLink } from '../../elements/AuthLink'

export const Login = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  return (
    <>
      <Form
        onSubmit={({ username, password }) => dispatch(login(username, password))}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <AuthInput
              name="username"
              label={t('auth:label.email')}
              placeholder={t('auth:placeholder.email')}
              validate={validateEmail}
            />
            <AuthInput
              name="password"
              type="password"
              label={t('auth:label.password')}
              placeholder="********"
              validate={validateRequired}
            />
            <AuthButton type="submit">{t('auth:submit.login')}</AuthButton>
          </form>
        )}
      />
      <AuthLink to={PATHS.FORGOT_PASSWORD} label={t('auth:navigation.forgotPassword')} />
    </>
  )
}
