import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import { resetPassword } from '../../../../../../redux/ducks/auth'
import { validatePassword } from '../../util/validate'
import { AuthInput } from '../../elements/AuthInput'
import { AuthButton } from '../../elements/AuthButton'

export const ResetPassword = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return (
    <Form
      onSubmit={({ password }) => dispatch(resetPassword(password))}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <AuthInput
            name="password"
            type="password"
            label={t('auth:label.password')}
            placeholder="********"
            validate={validatePassword}
          />
          <AuthInput
            name="passwordRepeat"
            type="password"
            label={t('auth:label.repeatPassword')}
            placeholder="********"
            validate={(value, allValues) =>
              validatePassword(value) ||
              (value === allValues.password ? undefined : t('auth:validation.passwordsShouldMatch'))
            }
          />
          <AuthButton type="submit">{t('auth:submit.resetPassword')}</AuthButton>
        </form>
      )}
    />
  )
}
