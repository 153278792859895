const SET_EDITOR_ENABLED = '@@tree-editor/editor/SET_EDITOR_ENABLED'
const SET_RULER_ACTIVE = '@@tree-editor/editor/SET_RULER_ACTIVE'
const SET_TREE_POINT_ACTIVE = '@@tree-editor/editor/SET_TREE_POINT_ACTIVE'
const SET_DISPLAY_REMOVED_TREES = '@@tree-editor/editor/SET_DISPLAY_REMOVED_TREES'
const SET_MODE = '@@tree-editor/editor/SET_MODE'

const initialState = {
  editorEnabled: false,
  rulerActive: false,
  treePointActive: false,
  displayRemovedTrees: false,
  mode: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_EDITOR_ENABLED: {
      return { ...state, editorEnabled: action.editorEnabled }
    }
    case SET_RULER_ACTIVE: {
      return { ...state, rulerActive: action.rulerActive }
    }
    case SET_TREE_POINT_ACTIVE: {
      return { ...state, treePointActive: action.treePointActive }
    }
    case SET_DISPLAY_REMOVED_TREES: {
      return { ...state, displayRemovedTrees: action.displayRemovedTrees }
    }
    case SET_MODE: {
      return { ...state, mode: action.mode }
    }
    default:
      return state
  }
}

export const setEditorEnabled = editorEnabled => ({ type: SET_EDITOR_ENABLED, editorEnabled })

export const setRulerActive = rulerActive => ({ type: SET_RULER_ACTIVE, rulerActive })

export const setTreePointActive = treePointActive => ({ type: SET_TREE_POINT_ACTIVE, treePointActive })

export const setDisplayRemovedTrees = displayRemovedTrees => ({ type: SET_DISPLAY_REMOVED_TREES, displayRemovedTrees })

export const setMode = mode => ({ type: SET_MODE, mode })
