import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import { Form, Field } from 'react-final-form'
import * as wkt from 'wellknown'
import {
  Dialog,
  DialogClose,
  DialogHeader,
  DialogContent,
  DialogButtons,
  Button,
  FormControl,
  FormControlMessage,
  TextInput,
} from '@project/components'
import { requiredValidator, wktStringValidator } from '@project/validation'
import { updateParcel } from 'api/requests'
import { useParcels } from 'api'

export const EditParcelPopup = ({ onClose }) => {
  const { t } = useTranslation()
  const { propertyId, parcelNumber } = useParams()
  const [parcels, loadingParcels, , , refreshParcels] = useParcels(propertyId, parcelNumber)

  const parcel = parcels?.find(p => p.propertyId === propertyId && p.parcelNumber === parcelNumber)

  const [{ loading }, onSubmit] = useAsyncFn(
    async fields => {
      const { geometryWkt, source } = fields
      try {
        await updateParcel(propertyId, parcelNumber, geometryWkt, source)
        await new Promise(resolve => setTimeout(resolve, 2000))
        await refreshParcels(true, true)
        onClose()
      } catch (error) {
        // do nothing
      }
    },
    [propertyId, parcelNumber, refreshParcels],
  )

  return (
    <Dialog loading={loading || loadingParcels}>
      <DialogClose onClose={onClose} />
      <DialogHeader>{t('dialog.title.updateParcel')}</DialogHeader>
      <DialogContent>
        {parcel && (
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormControl>
                  <Field
                    name="propertyId"
                    initialValue={parcel.propertyId}
                    validate={requiredValidator}
                    render={({ input, meta }) => (
                      <>
                        <TextInput label={t('common.property')} {...input} disabled />
                        {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                      </>
                    )}
                  />
                </FormControl>
                <FormControl>
                  <Field
                    name="parcelNumber"
                    initialValue={parcel.parcelNumber}
                    validate={requiredValidator}
                    render={({ input, meta }) => (
                      <>
                        <TextInput label={t('common.parcelNumber')} {...input} disabled />
                        {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                      </>
                    )}
                  />
                </FormControl>
                <FormControl>
                  <Field
                    name="geometryWkt"
                    initialValue={wkt.stringify(parcel.geometry)}
                    validate={v => requiredValidator(v) || wktStringValidator(v)}
                    render={({ input, meta }) => (
                      <>
                        <TextInput label={t('common.geometryWKT')} {...input} />
                        {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                      </>
                    )}
                  />
                </FormControl>
                <FormControl>
                  <Field
                    name="source"
                    initialValue={parcel.source}
                    validate={value => requiredValidator(value)}
                    render={({ input, meta }) => (
                      <>
                        <TextInput label={t('common.source')} {...input} />
                        {meta.error && meta.touched && <FormControlMessage text={meta.error} error />}
                      </>
                    )}
                  />
                </FormControl>
                <DialogButtons>
                  <Button onClick={onClose} disabled={loading} transparent>
                    {t('common.cancel')}
                  </Button>
                  <Button type="submit" disabled={loading}>
                    {t('common.save')}
                  </Button>
                </DialogButtons>
              </form>
            )}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}
