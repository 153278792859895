import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { SPECIES } from '@project/ontology'
import { setSpecies } from '../../../../../../../../redux/ducks/tree'

export const SpeciesShortcuts = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    let previousKey = null
    let previousKeyTimeoutId = null

    const onKeyDown = event => {
      if (event.repeat) return
      const { key } = event

      clearTimeout(previousKeyTimeoutId)
      previousKeyTimeoutId = setTimeout(() => (previousKey = null), 300)

      if (key === '1') dispatch(setSpecies(SPECIES.PINUS_SYLVESTRIS))
      if (key === '2') dispatch(setSpecies(SPECIES.PICEA_ABIES))
      if (key === '3') dispatch(setSpecies(SPECIES.BETULA_PENDULA))
      if (key === '4') {
        if (previousKey === '3') dispatch(setSpecies(SPECIES.BETULA_SPP))
        else dispatch(setSpecies(SPECIES.BETULA_PUBESCENS))
      }
      if (key === '5') dispatch(setSpecies(SPECIES.POPULUS_TREMULA))

      previousKey = key
    }

    document.addEventListener('keydown', onKeyDown, false)

    return () => document.removeEventListener('keydown', onKeyDown)
  }, [dispatch])

  return null
}
