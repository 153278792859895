import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import gh from 'latlon-geohash'
import { useQueryParams } from '@project/routing'
import { TreeTops as ToolkitTreeTops } from '@project/pointcloud3D'
import { useTreeCorrections, useTrunks } from 'api'

const treesToTrunks = trees =>
  trees.map(tree => {
    const { lat, lon: lng } = gh.decode(tree.geohash)
    return { lat, lng }
  })

export const TreeTops = () => {
  const { projectId } = useParams()
  const [{ geohash }] = useQueryParams()
  const [detectedTrunks] = useTrunks(projectId)
  const [treeCorrections] = useTreeCorrections(projectId)

  const omissions = treeCorrections?.omissions
  const commissions = treeCorrections?.commissions

  const trunks = useMemo(() => {
    if (!detectedTrunks || !omissions || !commissions) return null

    const removedTreeIds = commissions.map(item => item.geohash)
    const filteredTrunks = detectedTrunks.filter(item => !removedTreeIds.includes(gh.encode(item.lat, item.lng, 12)))
    const addedTrunks = treesToTrunks(omissions)
    return [...filteredTrunks, ...addedTrunks]
  }, [detectedTrunks, omissions, commissions])

  if (!trunks || !geohash) return null

  return <ToolkitTreeTops geohash={geohash} trunks={trunks} />
}
