import { useMemo } from 'react'
import * as turf from '@turf/turf'
import gh from 'latlon-geohash'
import { usePointcloudGeohashes } from '../base/usePointcloudGeohashes'

export const usePointcloudGeohashesGeoJSON = projectId => {
  const [geohashes, loading] = usePointcloudGeohashes(projectId)

  const featureCollection = useMemo(() => {
    if (!geohashes) return undefined

    const features = geohashes.map((geohash, index) => {
      const { sw, ne } = gh.bounds(geohash)
      const coordinates = [
        [
          [sw.lon, sw.lat],
          [sw.lon, ne.lat],
          [ne.lon, ne.lat],
          [ne.lon, sw.lat],
          [sw.lon, sw.lat],
        ],
      ]
      return turf.polygon(coordinates, { geohash }, { id: index })
    })

    return turf.featureCollection(features)
  }, [geohashes])

  return [featureCollection, loading]
}
