import { useUnmount } from 'react-use'
import { useMap } from '../map/MapGL'

export const useUnmountSourceEffect = sourceId => {
  const map = useMap()

  useUnmount(() => {
    try {
      map
        .getStyle()
        .layers.filter(l => l.source === sourceId)
        .forEach(l => map.removeLayer(l.id))
      map.removeSource(sourceId)
    } catch (e) {
      // do nothing
    }
  })
}
