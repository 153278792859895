export default {
  hints: {
    title: 'Pikakomennot',
    hide: 'Piilota',
    show: 'Näytä',
    r: 'Kuvion sivun poisto',
    q: 'Kuvion sivun irroitus viereisestä',
    m: 'Kuvion sivun kiinnitys viereiseen',
    b: 'Kuvion sivun kiinnitys tilarajaan',
    c: 'Kuvioiden yhdistäminen',
    a: 'Luo uusi kuvio tyhjälle alueelle',
    d: 'Poista kuvio',
    s: 'Jaa',
    e: 'Laajenna kuvio',
    v: 'Vahvista geometria',
    ctrlZ: 'Kumoa',
    ctrlShiftZ: 'Tee uudelleen',
  },
  validation: {
    message: 'Geometriasta löytyi virheitä. Käytä <1>V + N</1> yhdistelmää siirtyäksesi seuraavaan virheeseen.',
  },
}
