import React from 'react'
import classnames from 'classnames'
import { Dropdown } from '../Dropdown'
import style from './Tooltip.module.scss'

export const Tooltip = ({ trigger, content, xAlignment = 'center', maxWidth = 300 }) => (
  <Dropdown
    renderTrigger={() => trigger || <i className={classnames('fa fa-info-circle', style['tooltip__default-icon'])} />}
    renderContent={() => (
      <div className={style['tooltip__content']} style={{ maxWidth }}>
        {content}
      </div>
    )}
    xAlignment={xAlignment}
    openOnMouseEnter
    closeOnMouseLeave
  />
)
