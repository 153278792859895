import React, { createContext, useContext, useState, useMemo, useEffect } from 'react'
import { uniqueId } from 'lodash'
import { useMap } from '../../map/MapGL'
import { useUnmountSourceEffect } from '../../hooks/useUnmountSourceEffect'

const RasterSourceContext = createContext({})

export const useRasterSource = () => useContext(RasterSourceContext)

export const RasterSource = ({ url, scheme = 'xyz', tileSize = 256, attribution = '', children }) => {
  const map = useMap()
  const sourceId = useState(uniqueId())[0]
  const [source, setSource] = useState(null)

  const contextValue = useMemo(() => [sourceId, source], [sourceId, source])

  useEffect(() => {
    if (!source && url) {
      const s = { type: 'raster', tiles: [url], scheme, tileSize, attribution }
      map.addSource(sourceId, s)
      setSource(s)
    }
  }, [map, sourceId, source, url, scheme, tileSize, attribution])

  useUnmountSourceEffect(sourceId)

  return <RasterSourceContext.Provider value={contextValue}>{source && children}</RasterSourceContext.Provider>
}
