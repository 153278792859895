const ADD_COORDINATE = '@@polygon-builder/ADD_COORDINATE'
const CLEAR = '@@polygon-builder/CLEAR'

const initialState = {
  coordinates: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_COORDINATE: {
      return { ...state, coordinates: [...state.coordinates, action.coordinate] }
    }
    case CLEAR: {
      return { ...state, coordinates: [] }
    }
    default:
      return state
  }
}

export const addCoordinate = coordinate => ({ type: ADD_COORDINATE, coordinate })

export const clear = () => ({ type: CLEAR })
