import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, NavLink } from 'react-router-dom'
import classnames from 'classnames'
import { LanguageSwitch } from '@project/components'
import './Header.scss'
import { PATHS } from '../../../../util/paths'

const registrationEnabled = process.env.REACT_APP_FEATURE_REGISTRATION === 'enabled'

const renderNavLink = (to, text) => (
  <NavLink
    to={to}
    className={({ isActive }) => classnames('header__nav-link', { 'header__nav-link--active': isActive })}
  >
    {text}
  </NavLink>
)

export const Header = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  return (
    <div className="header">
      <div className="header__language-switch">
        <LanguageSwitch />
      </div>
      {pathname !== PATHS.CHANGE_PASSWORD && registrationEnabled && (
        <div>
          {renderNavLink('/login', t('auth:navigation.login'))}
          {renderNavLink('/register', t('auth:navigation.register'))}
        </div>
      )}
    </div>
  )
}
