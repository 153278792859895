const SET_ITEM = '@api/set-item'
const SET_LOADING = '@api/set-loading'
const SET_ERROR = '@api/set-error'
const SET_PROGRESS = '@api/set-progress'
const CLEAR = '@api/clear'

const initialState = {
  items: {}, // [id] -> Object/primitive
  loading: {}, // [id] -> true or false
  error: {}, // [id] -> { ...error }
  progress: {}, // [id] -> float in range [0, 1]
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ITEM: {
      return { ...state, items: { ...state.items, [action.id]: action.item } }
    }
    case SET_LOADING: {
      return { ...state, loading: { ...state.loading, [action.id]: action.loading } }
    }
    case SET_ERROR: {
      return { ...state, error: { ...state.error, [action.id]: action.error } }
    }
    case SET_PROGRESS: {
      return { ...state, progress: { ...state.progress, [action.id]: action.progress } }
    }
    case CLEAR: {
      return initialState
    }
    default:
      return state
  }
}

export const setItem = (id, item) => ({ type: SET_ITEM, id, item })

export const setLoading = (id, loading) => ({ type: SET_LOADING, id, loading })

export const setError = (id, error) => ({ type: SET_ERROR, id, error })

export const setProgress = (id, progress) => ({ type: SET_PROGRESS, id, progress })

export const clear = () => ({ type: CLEAR })

export const updateData = (id, getData, force, silent) => async (dispatch, getState) => {
  const state = getState()
  const item = state.api.items[id]
  const loading = state.api.loading[id]
  const error = state.api.error[id]

  if (!force && (item !== undefined || loading || error)) return

  if (!silent) {
    dispatch(setLoading(id, true))
    dispatch(setError(id, null))
  }

  try {
    const data = await getData()
    dispatch(setItem(id, data))
  } catch (e) {
    dispatch(setError(id, e))
    throw e
  } finally {
    dispatch(setLoading(id, false))
  }
}
