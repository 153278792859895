import React from 'react'
import classnames from 'classnames'
import { Tooltip } from '@project/components'
import { JOB_STATUS } from 'utils/jobStatus'
import style from './JobStatus.module.scss'

const { CREATED, QUEUED, PROCESSING, SUCCESS, FAILURE } = JOB_STATUS

const renderStatus = (status, reason) => (
  <div
    className={classnames({
      [style['status']]: true,
      [style['success']]: status === SUCCESS,
      [style['processing']]: status === CREATED || status === QUEUED || status === PROCESSING,
      [style['error']]: status === FAILURE,
      [style['reason']]: reason,
    })}
  >
    {status}
  </div>
)

export const JobStatus = ({ status, reason }) => {
  if (!reason) return renderStatus(status, reason)

  return <Tooltip trigger={renderStatus(status, reason)} content={reason} maxWidth={450} />
}
