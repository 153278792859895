import React from 'react'
import { useParams } from 'react-router-dom'
import {
  MapGL,
  GeoJSONSource,
  ScaleControl,
  NavigationControl,
  CtrlScrollZoomControl,
  PropertyLineLayer,
  LAYERS,
} from '@project/mapbox'
import { TilesSubstrate } from 'components/map/substrate'
import { useProject, useParcel } from 'api'

export const ParcelMap = () => {
  const { projectId } = useParams()
  const [project] = useProject(projectId)
  const { propertyId, parcelNumber } = project || {}
  const [parcel] = useParcel(propertyId, parcelNumber)

  const geoJSON = parcel?.geometry

  if (!geoJSON) return null

  return (
    <div style={{ height: '400px' }}>
      <MapGL bounds={geoJSON.bbox}>
        <TilesSubstrate defaultLayer={LAYERS.BASE_GRAYSCALE} enableControl={false} zIndex={0} />
        <GeoJSONSource data={geoJSON} tolerance={0.05} dynamicData>
          <PropertyLineLayer lineWidth={5} lineOpacity={['step', ['zoom'], 0, 10, 1]} zIndex={10} />
        </GeoJSONSource>
        <ScaleControl />
        <NavigationControl />
        <CtrlScrollZoomControl />
      </MapGL>
    </div>
  )
}
