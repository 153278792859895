import { useState, useEffect, useRef } from 'react'
import { uniqueId, isEqual } from 'lodash'
import { useMap, useLayerOrderRegulator } from '../../map/MapGL'
import { useGeoJSONSource } from '../GeoJSONSource'
import { useUnmountLayerEffect } from '../../hooks/useUnmountLayerEffect'

const getPaint = (
  circleBlur,
  circleColor,
  circleOpacity,
  circlePitchAlignment,
  circlePitchScale,
  circleRadius,
  circleStrokeColor,
  circleStrokeOpacity,
  circleStrokeWidth,
  circleTranslate,
  circleTranslateAnchor,
) => {
  const paint = {
    'circle-blur': circleBlur,
    'circle-color': circleColor,
    'circle-opacity': circleOpacity,
    'circle-pitch-alignment': circlePitchAlignment,
    'circle-pitch-scale': circlePitchScale,
    'circle-radius': circleRadius,
    'circle-stroke-color': circleStrokeColor,
    'circle-stroke-opacity': circleStrokeOpacity,
    'circle-stroke-width': circleStrokeWidth,
    'circle-translate': circleTranslate,
    'circle-translate-anchor': circleTranslateAnchor,
  }
  Object.keys(paint).forEach(key => {
    if (paint[key] === undefined) delete paint[key]
  })
  return paint
}

const getLayout = (circleSortKey, visibility) => {
  const layout = {
    'circle-sort-key': circleSortKey,
    visibility,
  }
  Object.keys(layout).forEach(key => {
    if (layout[key] === undefined) delete layout[key]
  })
  return layout
}

const getCallbacks = (sourceId, layerId, onMouseMove, onMouseLeave, onMouseDown, onMouseUp, onClick) => ({
  mousemove: onMouseMove && (event => onMouseMove(event, sourceId, layerId)),
  mouseleave: onMouseLeave && (event => onMouseLeave(event, sourceId, layerId)),
  mousedown: onMouseDown && (event => onMouseDown(event, sourceId, layerId)),
  mouseup: onMouseUp && (event => onMouseUp(event, sourceId, layerId)),
  click: onClick && (event => onClick(event, sourceId, layerId)),
})

export const CircleLayer = ({
  circleBlur,
  circleColor,
  circleOpacity,
  circlePitchAlignment,
  circlePitchScale,
  circleRadius,
  circleStrokeColor,
  circleStrokeOpacity,
  circleStrokeWidth,
  circleTranslate,
  circleTranslateAnchor,
  dynamicPaint = false,
  //
  circleSortKey,
  visibility,
  dynamicLayout = false,
  //
  onMouseMove,
  onMouseLeave,
  onMouseDown,
  onMouseUp,
  onClick,
  dynamicCallbacks = false,
  //
  zIndex,
}) => {
  const map = useMap()
  const sourceId = useGeoJSONSource()[0]
  const layerId = useState(uniqueId())[0]
  const paintRef = useRef(
    getPaint(
      circleBlur,
      circleColor,
      circleOpacity,
      circlePitchAlignment,
      circlePitchScale,
      circleRadius,
      circleStrokeColor,
      circleStrokeOpacity,
      circleStrokeWidth,
      circleTranslate,
      circleTranslateAnchor,
    ),
  )
  const layoutRef = useRef(getLayout(circleSortKey, visibility))

  const regulator = useLayerOrderRegulator(layerId, zIndex || 0)

  useEffect(() => {
    const layer = {
      id: layerId,
      type: 'circle',
      source: sourceId,
      paint: paintRef.current,
      layout: layoutRef.current,
    }
    map.addLayer(layer)
    regulator.orderLayers()
  }, [map, sourceId, layerId, paintRef, layoutRef, regulator])

  useUnmountLayerEffect(layerId)

  /// //////////////////////////////
  /// //////////////////////////////

  useEffect(() => {
    if (dynamicPaint) {
      const paint = getPaint(
        circleBlur,
        circleColor,
        circleOpacity,
        circlePitchAlignment,
        circlePitchScale,
        circleRadius,
        circleStrokeColor,
        circleStrokeOpacity,
        circleStrokeWidth,
        circleTranslate,
        circleTranslateAnchor,
      )
      Object.entries(paint).forEach(([key, value]) => {
        if (!isEqual(value, paintRef.current[key])) {
          map.setPaintProperty(layerId, key, value)
        }
      })
      paintRef.current = paint
    }
  }, [
    map,
    layerId,
    circleBlur,
    circleColor,
    circleOpacity,
    circlePitchAlignment,
    circlePitchScale,
    circleRadius,
    circleStrokeColor,
    circleStrokeOpacity,
    circleStrokeWidth,
    circleTranslate,
    circleTranslateAnchor,
    dynamicPaint,
    paintRef,
  ])

  /// //////////////////////////////
  /// //////////////////////////////

  useEffect(() => {
    if (dynamicLayout) {
      const layout = getLayout(circleSortKey, visibility)
      Object.entries(layout).forEach(([key, value]) => {
        if (!isEqual(value, layoutRef.current[key])) {
          map.setLayoutProperty(layerId, key, value)
        }
      })
      layoutRef.current = layout
    }
  }, [map, layerId, circleSortKey, visibility, dynamicLayout, layoutRef])

  /// //////////////////////////////
  /// //////////////////////////////

  const [callbacks, setCallbacks] = useState(null)

  useEffect(() => {
    if (!callbacks) {
      setCallbacks(getCallbacks(sourceId, layerId, onMouseMove, onMouseLeave, onMouseDown, onMouseUp, onClick))
    }
  }, [onMouseMove, onMouseLeave, onMouseDown, onMouseUp, onClick, sourceId, layerId, callbacks])

  useEffect(() => {
    if (dynamicCallbacks) {
      setCallbacks(getCallbacks(sourceId, layerId, onMouseMove, onMouseLeave, onMouseDown, onMouseUp, onClick))
    }
  }, [onMouseMove, onMouseLeave, onMouseDown, onMouseUp, onClick, sourceId, layerId, dynamicCallbacks])

  useEffect(() => {
    Object.entries(callbacks || {}).forEach(([key, value]) => {
      if (value) map.on(key, layerId, value)
    })

    return () => {
      Object.entries(callbacks || {}).forEach(([key, value]) => {
        if (value) map.off(key, layerId, value)
      })
    }
  }, [map, layerId, callbacks])

  return null
}
