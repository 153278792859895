const SET_COORDINATE = '@@tree-editor/tree/SET_COORDINATE'
const SET_CROWN_RADIUS = '@@tree-editor/tree/SET_CROWN_RADIUS'
const SET_SPECIES = '@@tree-editor/tree/SET_SPECIES'
const SET_BOTTOM_LEVEL = '@@tree-editor/tree/SET_BOTTOM_LEVEL'
const SET_TOP_LEVEL = '@@tree-editor/tree/SET_TOP_LEVEL'
const SET_AUTO_HEIGHT = '@@tree-editor/tree/SET_AUTO_HEIGHT'

const initialState = {
  coordinate: null,
  crownRadius: 0,
  species: null,
  bottomLevel: 0,
  topLevel: 0,
  autoHeight: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COORDINATE: {
      return { ...state, coordinate: action.coordinate }
    }
    case SET_CROWN_RADIUS: {
      return { ...state, crownRadius: action.crownRadius }
    }
    case SET_SPECIES: {
      return { ...state, species: action.species }
    }
    case SET_BOTTOM_LEVEL: {
      return { ...state, bottomLevel: action.bottomLevel }
    }
    case SET_TOP_LEVEL: {
      return { ...state, topLevel: action.topLevel }
    }
    case SET_AUTO_HEIGHT: {
      return { ...state, autoHeight: action.autoHeight }
    }
    default:
      return state
  }
}

export const setCoordinate = coordinate => ({ type: SET_COORDINATE, coordinate })

export const setCrownRadius = crownRadius => ({ type: SET_CROWN_RADIUS, crownRadius })

export const setSpecies = species => ({ type: SET_SPECIES, species })

export const setBottomLevel = bottomLevel => ({ type: SET_BOTTOM_LEVEL, bottomLevel })

export const setTopLevel = topLevel => ({ type: SET_TOP_LEVEL, topLevel })

export const setAutoHeight = autoHeight => ({ type: SET_AUTO_HEIGHT, autoHeight })

export const reset = () => dispatch => {
  dispatch(setCoordinate(null))
  dispatch(setCrownRadius(0))
  dispatch(setSpecies(null))
  dispatch(setBottomLevel(0))
  dispatch(setTopLevel(0))
  dispatch(setAutoHeight(0))
}
