import { useState, useEffect } from 'react'
import { uniqueId } from 'lodash'
import { useMap, useLayerOrderRegulator } from '../../map/MapGL'
import { useRasterSource } from '../RasterSource'
import { TextureLayer } from './TextureLayer'
import { useUnmountLayerEffect } from '../../hooks/useUnmountLayerEffect'

export const CHMLayer = ({ zIndex }) => {
  const map = useMap()
  const sourceId = useRasterSource()[0]
  const layerId = useState(uniqueId())[0]

  const regulator = useLayerOrderRegulator(layerId, zIndex || 0)

  useEffect(() => {
    const layer = new TextureLayer(layerId, sourceId)
    map.addLayer(layer)
    regulator.orderLayers()
  }, [map, sourceId, layerId, regulator])

  useUnmountLayerEffect(layerId)

  return null
}
