import { batch } from 'react-redux'
import * as turf from '@turf/turf'
import { coordsAreEq, pushLastPoint, clearCoords } from '../../utils/common'

const SET_POLYGONS = '@@geometry/SET_POLYGONS'
const ADD_POLYGON = '@@geometry/ADD_POLYGON'
const UPDATE_COORDINATE_IN_POLYGON_RING = '@@geometry/UPDATE_COORDINATE_IN_POLYGON_RING'
const ADD_COORDINATE_TO_POLYGON = '@@geometry/ADD_COORDINATE_TO_POLYGON'
const DELETE_COORDINATE_IN_POLYGON_RING = '@@geometry/DELETE_COORDINATE_IN_POLYGON_RING'
const DELETE_POLYGON = '@@geometry/DELETE_POLYGON'
const SET_CONSTRAINTS = '@@geometry/SET_CONSTRAINTS'

const initialState = {
  polygons: [],
  constraints: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_POLYGONS: {
      return { ...state, polygons: action.polygons }
    }
    case ADD_POLYGON: {
      const polygons = [...state.polygons, action.polygon]
      return { ...state, polygons }
    }
    case UPDATE_COORDINATE_IN_POLYGON_RING: {
      const { id, ringIndex, coordinate, nextCoordinate } = action
      const polygon = state.polygons.find(item => item.properties.id === id)
      const rings = [...polygon.geometry.coordinates]
      rings[ringIndex] = rings[ringIndex].map(c => (coordsAreEq(coordinate, c) ? nextCoordinate : c))
      rings[ringIndex] = clearCoords(rings[ringIndex])
      if (rings[ringIndex].length < 4) return state
      const newPolygon = { ...polygon, geometry: { ...polygon.geometry, coordinates: rings } }
      newPolygon.bbox = turf.bbox(newPolygon)
      const filtered = state.polygons.filter(item => item.properties.id !== id)
      const polygons = [...filtered, newPolygon]
      return { ...state, polygons }
    }
    case DELETE_COORDINATE_IN_POLYGON_RING: {
      const { id, ringIndex, coordinate } = action
      const filtered = state.polygons.filter(item => item.properties.id !== id)
      const polygon = state.polygons.find(item => item.properties.id === id)
      const rings = [...polygon.geometry.coordinates]
      rings[ringIndex] = rings[ringIndex].filter(c => !coordsAreEq(coordinate, c))
      pushLastPoint(rings[ringIndex])
      if (rings[ringIndex].length < 4) rings.splice(ringIndex, 1)
      if (rings.length === 0) return filtered
      const newPolygon = { ...polygon, geometry: { ...polygon.geometry, coordinates: rings } }
      newPolygon.bbox = turf.bbox(newPolygon)
      const polygons = [...filtered, newPolygon]
      return { ...state, polygons }
    }
    case ADD_COORDINATE_TO_POLYGON: {
      const { id, startCoordinate, endCoordinate } = action
      const [sLat, sLng] = startCoordinate
      const [eLat, eLng] = endCoordinate
      const polygon = state.polygons.find(item => item.properties.id === id)

      const coordinates = polygon.geometry.coordinates.map(ring => {
        let startIndex = ring.findIndex(c => coordsAreEq(startCoordinate, c))
        let endIndex = ring.findIndex(c => coordsAreEq(endCoordinate, c))
        if (startIndex < endIndex && endIndex - startIndex > 1 && startIndex === 0) startIndex = ring.length - 1
        if (startIndex > endIndex && startIndex - endIndex > 1 && endIndex === 0) endIndex = ring.length - 1
        const distance = Math.abs(endIndex - startIndex)
        if (startIndex > -1 && endIndex > -1 && distance === 1) {
          const lat = (sLat + eLat) / 2
          const lng = (sLng + eLng) / 2
          const insertIndex = startIndex < endIndex ? startIndex + 1 : endIndex + 1
          const newRing = [...ring.slice(0, insertIndex), [lat, lng], ...ring.slice(insertIndex)]
          pushLastPoint(newRing) // we can safely mutate because it's just constructed
          return newRing
        }
        return ring
      })

      const newPolygon = { ...polygon, geometry: { ...polygon.geometry, coordinates } }
      newPolygon.bbox = turf.bbox(newPolygon)
      const filtered = state.polygons.filter(item => item.properties.id !== id)
      const polygons = [...filtered, newPolygon]
      return { ...state, polygons }
    }
    case DELETE_POLYGON: {
      const polygons = state.polygons.filter(item => item.properties.id !== action.id)
      return { ...state, polygons }
    }
    case SET_CONSTRAINTS: {
      return { ...state, constraints: action.constraints }
    }
    default: {
      return state
    }
  }
}

export const setPolygons = (polygons = []) => ({ type: SET_POLYGONS, polygons })

export const addPolygon = polygon => ({ type: ADD_POLYGON, polygon })

export const updateCoordinateInPolygonRing = (id, ringIndex, coordinate, nextCoordinate) => ({
  type: UPDATE_COORDINATE_IN_POLYGON_RING,
  id,
  ringIndex,
  coordinate,
  nextCoordinate,
})

export const updateCoordinateInPolygon = (id, coordinate, nextCoordinate) => (dispatch, getState) => {
  batch(() => {
    const { polygons } = getState().geometry
    const polygon = polygons.find(p => p.properties.id === id)
    const rings = polygon.geometry.coordinates
    rings.forEach((ring, index) => dispatch(updateCoordinateInPolygonRing(id, index, coordinate, nextCoordinate)))
  })
}

export const deleteCoordinateInPolygonRing = (id, ringIndex, coordinate) => ({
  type: DELETE_COORDINATE_IN_POLYGON_RING,
  id,
  ringIndex,
  coordinate,
})

export const deleteCoordinateInPolygon = (id, coordinate) => (dispatch, getState) => {
  batch(() => {
    const { polygons } = getState().geometry
    const polygon = polygons.find(p => p.properties.id === id)
    const rings = polygon.geometry.coordinates
    rings.forEach((ring, index) => dispatch(deleteCoordinateInPolygonRing(id, index, coordinate)))
  })
}

export const addCoordinateToPolygon = (id, startCoordinate, endCoordinate) => ({
  type: ADD_COORDINATE_TO_POLYGON,
  id,
  startCoordinate,
  endCoordinate,
})

export const mergeTwoPolygons = (id1, id2) => (dispatch, getState) => {
  if (id1 === id2) return
  const { polygons } = getState().geometry
  const polygon1 = polygons.find(item => item.properties.id === id1)
  const polygon2 = polygons.find(item => item.properties.id === id2)
  const merged = turf.union(polygon1, polygon2)
  if (turf.getType(merged) !== 'Polygon') return
  merged.properties = polygon1.properties
  merged.bbox = turf.bbox(merged)
  const filtered = polygons.filter(item => {
    const { id } = item.properties
    return id !== id1 && id !== id2
  })
  dispatch(setPolygons([...filtered, merged]))
}

export const deletePolygon = id => ({ type: DELETE_POLYGON, id })

export const curePolygon = id => (dispatch, getState) => {
  const { polygons } = getState().geometry
  const polygon = polygons.find(item => item.properties.id === id)
  const rings = polygon.geometry.coordinates
  const rewindRequired =
    !turf.booleanClockwise(turf.lineString(rings[0])) ||
    rings.slice(1).find(ring => turf.booleanClockwise(turf.lineString(ring)))
  if (!rewindRequired) return

  dispatch(deletePolygon(id))
  dispatch(addPolygon(turf.rewind(polygon)))
}

export const cureAllPolygons = () => (dispatch, getState) => {
  batch(() => {
    const { polygons } = getState().geometry
    polygons.forEach(({ properties: { id } }) => dispatch(curePolygon(id)))
  })
}

export const setConstraints = (constraints = []) => ({ type: SET_CONSTRAINTS, constraints })
