import React, { createContext, useContext, useState, useMemo, useEffect } from 'react'
import { uniqueId } from 'lodash'
import { useMap } from '../../map/MapGL'
import { useUnmountSourceEffect } from '../../hooks/useUnmountSourceEffect'

const GeoJSONSourceContext = createContext({})

export const useGeoJSONSource = () => useContext(GeoJSONSourceContext)

export const GeoJSONSource = ({
  data,
  dynamicData = false,
  tolerance = 0.375,
  buffer = 64,
  cluster = false,
  clusterRadius = 5,
  clusterMaxZoom = 16,
  clusterProperties,
  children,
}) => {
  const map = useMap()
  const sourceId = useState(uniqueId())[0]
  const [source, setSource] = useState(null)

  const contextValue = useMemo(() => [sourceId, source], [sourceId, source])

  useEffect(() => {
    if (!source && data) {
      const s = {
        type: 'geojson',
        data,
        buffer,
        tolerance,
        cluster,
        clusterRadius,
        clusterMaxZoom,
        clusterProperties,
      }
      map.addSource(sourceId, s)
      setSource(s)
    }
  }, [map, sourceId, source, data, buffer, tolerance, cluster, clusterRadius, clusterMaxZoom, clusterProperties])

  useEffect(() => {
    if (map && data && dynamicData) {
      const s = map.getSource(sourceId)
      if (s) s.setData(data)
    }
  }, [map, sourceId, data, dynamicData])

  useUnmountSourceEffect(sourceId)

  return <GeoJSONSourceContext.Provider value={contextValue}>{source && children}</GeoJSONSourceContext.Provider>
}
