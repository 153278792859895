import React, { useEffect, useState, useMemo } from 'react'
import * as THREE from 'three'
import gh from 'latlon-geohash'
import { TopControls } from '../TopControls'
import { ControlToggle } from '../ControlToggle'
import { usePaneUtils, useTile } from '../Pointcloud3D'

const useGeohashTrunks = (geohash, trunks) => {
  const geohashTrunksMap = useMemo(() => {
    const map = {}
    ;(trunks || []).forEach(trunk => {
      const { lat, lng } = trunk
      const key = gh.encode(lat, lng, 8)
      if (!map[key]) map[key] = []
      map[key].push(trunk)
      return null
    })
    return map
  }, [trunks])

  return geohashTrunksMap[geohash]
}

const getPosition = (lat, lng, geohash, bbox) => {
  const { minX, minY, maxX, maxY, minZ, maxZ } = bbox
  const { sw, ne } = gh.bounds(geohash)
  const x = minX + ((maxX - minX) * (lng - sw.lon)) / (ne.lon - sw.lon)
  const y = minY + ((maxY - minY) * (lat - sw.lat)) / (ne.lat - sw.lat)
  return { x, y, z: (maxZ + minZ) / 2 }
}

export const TreeTops = ({ geohash, trunks }) => {
  const paneUtils = usePaneUtils()
  const tile = useTile()
  const [active, setActive] = useState(false)
  const geohashTrunks = useGeohashTrunks(geohash, trunks)
  const { normalizedBBox: bbox } = tile || {}

  const [cylinders, setCylinders] = useState(null)

  useEffect(() => {
    if (geohashTrunks && bbox) {
      const material = new THREE.MeshBasicMaterial({ color: 0xa18172, transparent: true, opacity: 0.7 })
      const cs = geohashTrunks.map(trunk => {
        const { lat, lng } = trunk
        const geometry = new THREE.CylinderGeometry(0.004, 0.004, bbox.maxZ - bbox.minZ, 8)
        geometry.rotateX(Math.PI * 0.5)
        const cylinder = new THREE.Mesh(geometry, material)
        const { x, y, z } = getPosition(lat, lng, geohash, bbox)
        cylinder.position.set(x, y, z)
        return cylinder
      })
      setCylinders(cs)
    }
  }, [geohashTrunks, geohash, bbox])

  useEffect(() => {
    if (!cylinders) return () => {}
    if (active) {
      cylinders.forEach(c => paneUtils.add(c))
      paneUtils.render()
    }
    return () => {
      cylinders.forEach(c => paneUtils.remove(c))
      paneUtils.render()
    }
  }, [cylinders, active, paneUtils])

  return (
    <TopControls>
      <ControlToggle iconClassName="fa fa-tree" onClick={() => setActive(!active)} order={1} />
    </TopControls>
  )
}
