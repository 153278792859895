import React, { useRef, useLayoutEffect } from 'react'
import style from './SubComponentWrapper.module.scss'

export const SubComponentWrapper = ({ children }) => {
  const containerRef = useRef()

  useLayoutEffect(() => {
    const element = containerRef.current
    const setSize = () => {
      if (!element) return
      const child = element.children[0]
      if (!child) return
      const { height } = child.getBoundingClientRect()
      element.style.height = `${height}px`
    }
    setSize()
    document.addEventListener('resize', setSize, false)
    return document.removeEventListener('resize', setSize)
  }, [containerRef])

  return (
    <div ref={containerRef} className={style['container']}>
      <div className={style['content']}>{children}</div>
    </div>
  )
}
