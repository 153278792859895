export default {
  title: {
    login: 'Wir kennen Ihre Bäume',
    register: 'Registrieren',
    forgotPassword: 'Passwort vergessen?',
    verify: '???', // TODO: sync with FI and EN versions
    changePassword: '???', // TODO: sync with FI and EN versions
    resetPassword: '???',
  },

  navigation: {
    login: 'Einloggen',
    register: 'Registrieren',
    forgotPassword: 'Passwort vergessen?',
  },

  submit: {
    login: 'Einloggen',
    register: 'Abschicken',
    forgotPassword: 'Neues Passwort anfordern',
    verify: '???', // TODO: sync with FI and EN versions
    continue: '???', // TODO: sync with FI and EN versions
    changePassword: '???', // TODO: sync with FI and EN versions
    resetPassword: '???',
  },

  label: {
    email: 'E-Mail-Adresse',
    password: 'Passwort',
    repeatPassword: 'Passwort bestätigen',
    oldPassword: '???', // TODO: sync with FI and EN versions
    code: '???', // TODO: sync with FI and EN versions
  },

  placeholder: {
    email: 'vornahme.nachname@beispiel.com',
  },

  validation: {
    passwordsShouldMatch: 'Passwörter sollten übereinstimmen',
  },

  description: {
    default:
      'Sie pflegen Ihren Wald und die Bäume wachsen. Wie genau aber kennen Sie Ihren Waldbesitz? ' +
      'Wir von PreFor können Ihnen helfen Ihren Waldbestand exakt zu vermessen.',
    verify: '???', // TODO: sync with FI and EN versions
    forgotPassword: '???', // TODO: sync with FI and EN versions
    resetPassword: '???', // TODO: sync with FI and EN versions
  },

  error: {
    incorrectUsernameOrPassword: 'Please check username or password',
    userDoesNotExist: 'User is not found',
    userAlreadyExists: 'An account with the given email already exists',
    invalidVerificationCode: 'Invalid verification code provided',
    userIsNotConfirmed: 'User is not confirmed',
    temporaryPasswordHasExpired: 'Temporary password has expired and must be reset by an administrator',
    passwordCannotBeReset: 'User password cannot be reset in the current state',
  },
}
