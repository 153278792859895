import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as turf from '@turf/turf'
import { useQueryParams } from '@project/routing'
import { GeohashGridLayer, GeoJSONSource } from '@project/mapbox'
import { usePointcloudGeohashesGeoJSON, useTreeCorrectionTiles } from 'api'
import { MODES } from '../../../../../../utils/modes'
import { TILE_STATUSES } from '../../../../../../utils/tileStatuses'

export const SelectedGeohashControl = ({ zIndex }) => {
  const { projectId } = useParams()
  const [queryParams, setQueryParams] = useQueryParams()
  const [geohashesGeoJSON] = usePointcloudGeohashesGeoJSON(projectId)
  const [treeCorrectionTiles] = useTreeCorrectionTiles(projectId)
  const mode = useSelector(state => state.editor.mode)

  const data = useMemo(() => {
    if (!geohashesGeoJSON || !treeCorrectionTiles) return null
    const skippedGeohashes = treeCorrectionTiles
      .filter(item => item.status === TILE_STATUSES.SKIP)
      .map(item => item.geohash)
    const features = geohashesGeoJSON.features.filter(f => !skippedGeohashes.includes(f.properties.geohash))
    return turf.featureCollection(features)
  }, [geohashesGeoJSON, treeCorrectionTiles])

  if (mode === MODES.GEOHASH || !data) return null

  return (
    <GeoJSONSource data={data} dynamicData>
      <GeohashGridLayer
        onClick={event => {
          const feature = event.features[0]
          if (feature) setQueryParams({ ...queryParams, geohash: feature.properties.geohash })
        }}
        selectedGeohash={queryParams.geohash}
        selectedGeohashColor="purple"
        hoveredGeohashColor="purple"
        zIndex={zIndex}
      />
    </GeoJSONSource>
  )
}
