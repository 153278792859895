import * as turf from '@turf/turf'

const SET_ACTIVE_POLYGON_ID = '@@active-polygon/SET_ACTIVE_POLYGON_ID'
const SET_ACTIVE_RING_INDEX = '@@active-polygon/SET_ACTIVE_RING_INDEX'
const SET_ACTIVE_POLYGON_NEIGHBOR_IDS = '@@active-polygon/SET_ACTIVE_POLYGON_NEIGHBOR_IDS'
const SET_ACTIVE_VERTEX_COORDINATE = '@@active-polygon/SET_ACTIVE_VERTEX_COORDINATE'
const SET_ACTIVE_VERTEX_POINT = '@@active-polygon/SET_ACTIVE_VERTEX_POINT'

const initialState = {
  activePolygonId: '',
  activeRingIndex: -1,
  activeVertexCoordinate: null, // null or [lat, lng]
  activeVertexPoint: null, // null or [x, y]
  activePolygonNeighborIds: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_POLYGON_ID: {
      return {
        ...state,
        activePolygonId: action.id,
      }
    }
    case SET_ACTIVE_RING_INDEX: {
      return {
        ...state,
        activeRingIndex: action.ringIndex,
      }
    }
    case SET_ACTIVE_VERTEX_COORDINATE: {
      return {
        ...state,
        activeVertexCoordinate: action.coordinate,
      }
    }
    case SET_ACTIVE_VERTEX_POINT: {
      return {
        ...state,
        activeVertexPoint: action.point,
      }
    }
    case SET_ACTIVE_POLYGON_NEIGHBOR_IDS: {
      return {
        ...state,
        activePolygonNeighborIds: action.activePolygonNeighborIds,
      }
    }
    default: {
      return state
    }
  }
}

export const setActivePolygonId = id => ({ type: SET_ACTIVE_POLYGON_ID, id })

export const setActiveRingIndex = ringIndex => ({ type: SET_ACTIVE_RING_INDEX, ringIndex })

export const setActiveVertexCoordinate = coordinate => ({ type: SET_ACTIVE_VERTEX_COORDINATE, coordinate })

export const setActiveVertexPoint = point => ({ type: SET_ACTIVE_VERTEX_POINT, point })

export const setActivePolygonNeighborIds = ids => ({
  type: SET_ACTIVE_POLYGON_NEIGHBOR_IDS,
  activePolygonNeighborIds: ids,
})

export const updateActivePolygonNeighborIds = () => (dispatch, getState) => {
  const { polygons } = getState().geometry
  const { activePolygonId } = getState().editor
  const polygon = polygons.find(p => p.properties.id === activePolygonId)
  const bboxPolygon = turf.bboxPolygon(polygon.bbox)
  const neighbourIds = polygons
    .filter(p => p.properties.id !== activePolygonId)
    .filter(p => turf.booleanIntersects(turf.bboxPolygon(p.bbox), bboxPolygon))
    .filter(p => turf.booleanIntersects(p, polygon))
    .map(p => p.properties.id)
  dispatch(setActivePolygonNeighborIds(neighbourIds))
}
