export default {
  controls: {
    naturalColorScheme: 'Natural color scheme',
    altitudeColorScheme: 'Height ramped color scheme',
    pointSizeSmall: 'Point size small',
    pointSizeMedium: 'Point size medium',
    pointSizeLarge: 'Point size large',
  },
  placeholder: 'Choose a map tile to see 3D view.',
}
