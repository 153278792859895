import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { HtmlTable, Spinner } from '@project/components'
import { useCustomers } from 'api'
import { TableIcon } from 'components/common/TableIcon'

const getColumns = (t, navigate) => [
  {
    Header: t('common.name'),
    Cell: ({ original }) => original.name,
  },
  {
    Header: t('common.phone'),
    Cell: ({ original }) => original.phone,
  },
  {
    Header: t('common.email'),
    Cell: ({ original }) => original.email,
  },
  {
    Header: '',
    Cell: ({ original }) => (
      <TableIcon
        iconClassName="fa fa-pen-to-square"
        onClick={() => navigate(`edit-customer/${original.customerId}`, { replace: true })}
      />
    ),
    width: 0,
  },
]

export const CustomersTable = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [customers] = useCustomers()

  if (!customers) return <Spinner />

  if (customers.length === 0) return t('table.fallback.nothingToDisplay')

  return <HtmlTable data={customers} columns={getColumns(t, navigate)} stickyHeader />
}
