import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { clearError } from '../../../../redux/ducks/auth'
import { PATHS } from '../../../../util/paths'
import { Login } from './partials/Login'
import { Register } from './partials/Register'
import { Verify } from './partials/Verify'
import { ForgotPassword } from './partials/ForgotPassword'
import { ChangePassword } from './partials/ChangePassword'
import { ResetPassword } from './partials/ResetPassword'
import './Forms.scss'
import { AuthError } from './elements/AuthError'

const brandingEnabled = process.env.REACT_APP_FEATURE_PREFOR_BRANDING === 'enabled'
const registrationEnabled = process.env.REACT_APP_FEATURE_REGISTRATION === 'enabled'

export const Forms = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const challengeName = useSelector(state => state.auth.user?.challengeName)

  useEffect(() => {
    if (challengeName === 'NEW_PASSWORD_REQUIRED') navigate(PATHS.RESET_PASSWORD, { replace: true })
  }, [challengeName, navigate])

  useEffect(() => {
    dispatch(clearError())
  }, [location, dispatch])

  return (
    <div className="forms">
      <div className="forms__title">
        <Routes>
          <Route path={PATHS.LOGIN} element={t('auth:title.login')} />
          <Route path={PATHS.REGISTER} element={t('auth:title.register')} />
          <Route path={PATHS.VERIFY} element={t('auth:title.verify')} />
          <Route path={PATHS.FORGOT_PASSWORD} element={t('auth:title.forgotPassword')} />
          <Route path={PATHS.CHANGE_PASSWORD} element={t('auth:title.changePassword')} />
          <Route path={PATHS.RESET_PASSWORD} element={t('auth:title.resetPassword')} />
        </Routes>
      </div>
      <div className="forms__description">
        <Routes>
          <Route path={PATHS.VERIFY} element={<p>{t('auth:description.verify')}</p>} />
          <Route path={PATHS.FORGOT_PASSWORD} element={<p>{t('auth:description.forgotPassword')}</p>} />
          <Route path={PATHS.CHANGE_PASSWORD} element={null} />
          <Route path={PATHS.RESET_PASSWORD} element={<p>{t('auth:description.resetPassword')}</p>} />
          {brandingEnabled && <Route path="*" element={<p>{t('auth:description.default')}</p>} />}
        </Routes>
      </div>
      <AuthError />
      <Routes>
        <Route path={PATHS.LOGIN} element={<Login />} />
        {registrationEnabled && <Route path={PATHS.REGISTER} element={<Register />} />}
        <Route path={PATHS.VERIFY} element={<Verify />} />
        <Route path={PATHS.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={PATHS.CHANGE_PASSWORD} element={<ChangePassword />} />
        <Route path={PATHS.RESET_PASSWORD} element={<ResetPassword />} />
        <Route path="*" element={<Navigate to={PATHS.LOGIN} />} />
      </Routes>
    </div>
  )
}
