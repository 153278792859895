import React from 'react'
import { useParams } from 'react-router-dom'
import { ProgressIndicator } from '@project/components'
import { usePointcloudGeohashesGeoJSON, useTreeCorrectionTiles } from 'api'
import { TILE_STATUSES } from '../../../../utils/tileStatuses'

export const Progress = () => {
  const { projectId } = useParams()
  const [geoJSON] = usePointcloudGeohashesGeoJSON(projectId)
  const [treeCorrectionTiles] = useTreeCorrectionTiles(projectId)

  if (!geoJSON || !treeCorrectionTiles) return null

  const processedCount = treeCorrectionTiles.filter(item => item.status === TILE_STATUSES.DONE).length
  const skippedCount = treeCorrectionTiles.filter(item => item.status === TILE_STATUSES.SKIP).length
  const total = geoJSON.features.length - skippedCount

  return <ProgressIndicator count={processedCount} total={total} />
}
