import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { HtmlTable, Spinner, CopyToClipboard } from '@project/components'
import { useProjects, useParcels, useOrders, useCustomers } from 'api'
import { TableIcon } from 'components/common/TableIcon'
import { OrderProjectsTable } from './partials/OrderProjectsTable'
import { OrderProgress } from './partials/OrderProgress'

const getColumns = (t, navigate) => [
  {
    Header: t('common.orderId'),
    Cell: ({ original }) => <CopyToClipboard textToCopy={original.orderId}>{original.orderId}</CopyToClipboard>,
  },
  {
    Header: t('common.orderNumber'),
    Cell: ({ original }) => original.orderNumber,
  },
  {
    Header: t('common.customer'),
    Cell: ({ original }) => original.customer?.name || '—',
  },
  {
    Header: t('common.description'),
    Cell: ({ original }) => original.description,
  },
  {
    Header: t('common.progress'),
    Cell: ({ original }) => <OrderProgress orderProjects={original.projects} />,
  },
  {
    Header: t('common.status'),
    Cell: ({ original }) => original.status,
  },
  {
    Header: t('common.billed'),
    Cell: ({ original }) => (original.wasBilled ? t('common.yes') : t('common.no')),
  },
  {
    Header: '',
    Cell: ({ original }) => (
      <TableIcon iconClassName="fa fa-pen-to-square" onClick={() => navigate(`edit-order/${original.orderId}`)} />
    ),
  },
  {
    expander: true,
    width: 0,
  },
]

export const OrdersTable = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [projects] = useProjects()
  const [parcels] = useParcels()
  const [orders] = useOrders()
  const [customers] = useCustomers()

  const data = useMemo(() => {
    if (!orders || !customers || !projects) return null
    return orders.map(order => ({
      ...order,
      customer: customers.find(c => c.customerId === order.customerId),
      projects: projects.filter(p => p.order?.orderId === order.orderId),
    }))
  }, [orders, customers, projects])

  if (!orders || !projects || !parcels) return <Spinner />

  if (orders.length === 0) return t('table.fallback.nothingToDisplay')

  return (
    <HtmlTable
      data={data}
      columns={getColumns(t, navigate)}
      SubComponent={({ original }) => <OrderProjectsTable orderId={original.orderId} />}
      stickyHeader
    />
  )
}
