import React from 'react'
import classnames from 'classnames'
import style from './TextArea.module.scss'

export const TextArea = ({ className, label, ...rest }) => (
  <div className={style['container']}>
    <textarea
      className={classnames({
        [style['textarea']]: true,
        [style['with-label']]: label,
        [className]: className,
      })}
      {...rest}
    />
    {label && <div className={style['label']}>{label}</div>}
  </div>
)
