import React, { useState } from 'react'
import classnames from 'classnames'
import { SubComponentWrapper } from './partials/SubComponentWrapper/SubComponentWrapper'
import style from './Row.module.scss'

export const Row = ({ columns, dataItem, SubComponent, getRowStyle }) => {
  const [opened, setOpened] = useState(false)
  return (
    <>
      <tr className={style['tr']} style={getRowStyle && getRowStyle(dataItem)}>
        {columns.map((column, index) => {
          const { Cell, accessor, expander, alignRight, noWrap, link, width, getVisible } = column
          const visible = getVisible ? getVisible({ original: dataItem }) : true
          return (
            <td
              key={index}
              className={classnames({
                [style['td']]: true,
                [style['td--right']]: alignRight,
                [style['td--no-wrap']]: noWrap,
                [style['td--link']]: link,
              })}
              style={{ width }}
            >
              {(() => {
                if (!visible) return null
                if (expander)
                  return (
                    <div className={style['expander-wrapper']}>
                      <div className={style['expander']} onClick={() => setOpened(!opened)}>
                        <i className={classnames('fa fa-angle-down', { 'fa-rotate-180': opened })} />
                      </div>
                    </div>
                  )
                return Cell({ original: dataItem, value: accessor && accessor(dataItem) })
              })()}
            </td>
          )
        })}
      </tr>
      {opened && SubComponent && (
        <tr>
          <td className={style['sub-component']} colSpan={columns.length}>
            <SubComponentWrapper>{SubComponent({ original: dataItem })}</SubComponentWrapper>
          </td>
        </tr>
      )}
    </>
  )
}
