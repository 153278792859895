import { useState, useEffect, useRef } from 'react'
import { uniqueId, isEqual } from 'lodash'
import { useMap, useLayerOrderRegulator } from '../../map/MapGL'
import { useRasterSource } from '../RasterSource'
import { useUnmountLayerEffect } from '../../hooks/useUnmountLayerEffect'

const getPaint = (
  rasterBrightnessMax,
  rasterBrightnessMin,
  rasterContrast,
  rasterFadeDuration,
  rasterHueRotate,
  rasterResampling,
  rasterSaturation,
) => {
  const paint = {
    'raster-brightness-max': rasterBrightnessMax,
    'raster-brightness-min': rasterBrightnessMin,
    'raster-contrast': rasterContrast,
    'raster-fade-duration': rasterFadeDuration,
    'raster-hue-rotate': rasterHueRotate,
    'raster-resampling': rasterResampling,
    'raster-saturation': rasterSaturation,
  }
  Object.keys(paint).forEach(key => {
    if (paint[key] === undefined) delete paint[key]
  })
  return paint
}

const getLayout = visibility => {
  const layout = {
    visibility,
  }
  Object.keys(layout).forEach(key => {
    if (layout[key] === undefined) delete layout[key]
  })
  return layout
}

export const RasterLayer = ({
  rasterBrightnessMax,
  rasterBrightnessMin,
  rasterContrast,
  rasterFadeDuration,
  rasterHueRotate,
  rasterResampling,
  rasterSaturation,
  dynamicPaint = false,
  //
  visibility,
  dynamicLayout = false,
  //
  zIndex,
}) => {
  const map = useMap()
  const sourceId = useRasterSource()[0]
  const layerId = useState(uniqueId())[0]
  const paintRef = useRef(
    getPaint(
      rasterBrightnessMax,
      rasterBrightnessMin,
      rasterContrast,
      rasterFadeDuration,
      rasterHueRotate,
      rasterResampling,
      rasterSaturation,
    ),
  )
  const layoutRef = useRef(getLayout(visibility))

  const regulator = useLayerOrderRegulator(layerId, zIndex || 0)

  useEffect(() => {
    const layer = { id: layerId, type: 'raster', source: sourceId, paint: paintRef.current, layout: layoutRef.current }
    map.addLayer(layer)
    regulator.orderLayers()
  }, [map, sourceId, layerId, paintRef, layoutRef, regulator])

  useUnmountLayerEffect(layerId)

  /// //////////////////////////////
  /// //////////////////////////////

  useEffect(() => {
    if (dynamicPaint) {
      const paint = getPaint(
        rasterBrightnessMax,
        rasterBrightnessMin,
        rasterContrast,
        rasterFadeDuration,
        rasterHueRotate,
        rasterResampling,
        rasterSaturation,
      )
      Object.entries(paint).forEach(([key, value]) => {
        if (!isEqual(value, paintRef.current[key])) {
          map.setPaintProperty(layerId, key, value)
        }
      })
      paintRef.current = paint
    }
  }, [
    map,
    layerId,
    rasterBrightnessMax,
    rasterBrightnessMin,
    rasterContrast,
    rasterFadeDuration,
    rasterHueRotate,
    rasterResampling,
    rasterSaturation,
    dynamicPaint,
    paintRef,
  ])

  /// //////////////////////////////
  /// //////////////////////////////

  useEffect(() => {
    if (dynamicLayout) {
      const layout = getLayout(visibility)
      Object.entries(layout).forEach(([key, value]) => {
        if (!isEqual(value, layoutRef.current[key])) {
          map.setLayoutProperty(layerId, key, value)
        }
      })
      layoutRef.current = layout
    }
  }, [map, layerId, visibility, dynamicLayout, layoutRef])

  return null
}
