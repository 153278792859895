import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import { useTranslation } from 'react-i18next'
import { HtmlTable, Spinner, Toggle } from '@project/components'
import { usePlannerUsers } from 'api'
import { setPlannerUserDeveloper, setPlannerUserOrderbook } from 'api/requests'
import { TableIcon } from 'components/common/TableIcon'
import { UserProperties } from './partials/UserProperties'
import style from './UsersTable.module.scss'

const getColumns = (updateDeveloperFlag, updateOrderbookFlag, t, navigate) => [
  {
    Header: t('common.email'),
    Cell: ({ original }) => original.email,
  },
  {
    Header: t('common.status'),
    Cell: ({ original }) => original.status,
  },
  {
    Header: t('common.enabled'),
    Cell: ({ original }) => t(original.enabled ? 'common.yes' : 'common.no'),
  },
  {
    Header: t('common.developer'),
    Cell: ({ original }) => (
      <Toggle value={original.developer} onChange={value => updateDeveloperFlag(original.email, value)} />
    ),
  },
  {
    Header: t('common.orderbook'),
    Cell: ({ original }) => (
      <Toggle value={original.orderbook} onChange={value => updateOrderbookFlag(original.email, value)} />
    ),
  },
  {
    Header: t('common.properties'),
    Cell: ({ original }) => <UserProperties propertyIds={original.properties.map(p => p.propertyId)} />,
  },
  {
    Header: t('common.plans'),
    Cell: ({ original }) => {
      const { email, plans } = original
      return <NavLink to={`edit/${email}/plans`}>{`${plans.length} plans`}</NavLink>
    },
    link: true,
  },
  {
    Header: '',
    Cell: ({ original }) => (
      <TableIcon iconClassName="fa fa-pen-to-square" onClick={() => navigate(`edit/${original.email}`)} />
    ),
    width: 0,
  },
]

export const UsersTable = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [users, loadingUsers, , , refreshUsers] = usePlannerUsers()

  const [{ loading: updatingDeveloperFlag }, updateDeveloperFlag] = useAsyncFn(async (email, value) => {
    await setPlannerUserDeveloper(email, value)
    await new Promise(resolve => setTimeout(resolve, 500))
    await refreshUsers(true, false)
  })

  const [{ loading: updatingOrderbookFlag }, updateOrderbookFlag] = useAsyncFn(async (email, value) => {
    await setPlannerUserOrderbook(email, value)
    await new Promise(resolve => setTimeout(resolve, 500))
    await refreshUsers(true, false)
  })

  if (!users) return <Spinner />

  if (users.length === 0) return t('table.fallback.nothingToDisplay')

  return (
    <div className={style['container']}>
      <HtmlTable
        data={users}
        columns={getColumns(updateDeveloperFlag, updateOrderbookFlag, t, navigate)}
        stickyHeader
      />
      {(loadingUsers || updatingDeveloperFlag || updatingOrderbookFlag) && (
        <div className={style['spinner-container']}>
          <Spinner />
        </div>
      )}
    </div>
  )
}
