import React from 'react'
import style from './Scale.module.scss'

export const Scale = ({ height }) => {
  const tickStep = height > 5 ? 1 : 0.1
  const tickCount = Math.ceil(height / tickStep)

  return (
    <div className={style['ticks']}>
      {[...Array(tickCount)].map((item, index) => (
        <div key={index} style={{ bottom: `${100 * ((tickStep * index) / height)}%` }} data-label={index * tickStep} />
      ))}
    </div>
  )
}
