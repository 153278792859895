import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

const lsPreferredLngKey = 'PREFOR_PREFERRED_LNG'
const getPreferredLanguage = () => window.localStorage.getItem(lsPreferredLngKey)
const setPreferredLanguage = lng => window.localStorage.setItem(lsPreferredLngKey, lng)
const getBrowserLanguage = () => (navigator.language || navigator.userLanguage).substring(0, 2)

export const initI18N = resources => {
  if (!resources) return

  i18next.use(initReactI18next).init({
    resources,
    supportedLngs: Object.keys(resources),
    fallbackLng: Object.keys(resources)[0],
    lng: getPreferredLanguage() || getBrowserLanguage(),
    interpolation: {
      escapeValue: false,
      format: (value, format, options = {}, lng = i18next.languages[0]) => {
        switch (format) {
          case 'number':
            return new Intl.NumberFormat(lng, options).format(value)
          case 'percent':
            return new Intl.NumberFormat(lng, { style: 'percent', ...options }).format(value)
          case 'currency':
            return new Intl.NumberFormat(lng, { style: 'currency', currency: 'EUR', ...options }).format(value)
          case 'capitalize':
            return value[0].toUpperCase() + value.slice(1)
          case 'lowercase':
            return value.toLowerCase()
          case 'area': {
            const areaOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2, ...options }
            return `${new Intl.NumberFormat(lng, areaOptions).format(value / 10000)} ha`
          }
          case 'volume': {
            const volumeOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2, ...options }
            return `${new Intl.NumberFormat(lng, volumeOptions).format(value)} m³`
          }
          default:
            return value
        }
      },
    },
  })

  i18next.on('languageChanged', lng => setPreferredLanguage(lng))
}
