import { uid } from 'react-uid'
import { useDataLoader } from '@project/api'
import { useProject } from './useProject'
import { getTrunks } from '../../requests'

const hookId = uid({})

export const useTrunks = projectId => {
  const [project] = useProject(projectId)
  const { propertyId, jobId } = project || {}
  return useDataLoader(hookId, getTrunks, [propertyId, jobId])
}
