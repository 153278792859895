export default {
  controls: {
    naturalColorScheme: 'Luonnonvärit',
    altitudeColorScheme: 'Korkeusvärit',
    pointSizeSmall: 'Pistekoko pieni',
    pointSizeMedium: 'Pistekoko keskikoko',
    pointSizeLarge: 'Pistekoko suuri',
  },
  placeholder: 'Valitse kartasta ruutu nähdäksesi 3D näkymän.',
}
