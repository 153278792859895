import React, { useEffect, useMemo, useRef } from 'react'
import { uid } from 'react-uid'
import * as turf from '@turf/turf'
import {
  MapGL,
  GeoJSONSource,
  ScaleControl,
  NavigationControl,
  CtrlScrollZoomControl,
  PropertyLineLayer,
  RasterLayer,
  RasterSource,
  CircleLayer,
  GeoJSONLabelOverlay,
  FillLayer,
} from '@project/mapbox'
import { Spinner } from '@project/components'
import { useParcels, useProjects } from 'api'
import { BoundsController } from './partials/BoundsController'
import { COLOR } from '../utils/color'

const pointMaxZoom = 10

const getColor = (parcel, projects) => {
  const { propertyId, parcelNumber } = parcel
  const project = projects.find(p => p.propertyId === propertyId && p.parcelNumber === parcelNumber)
  if (!project) return COLOR.BLACK

  const { sort } = project.status
  if (sort < 2) return COLOR.BLACK
  if (sort === 2) return COLOR.GRAY
  if (sort === 3) return COLOR.ORANGE
  if (sort === 4) return COLOR.YELLOW
  if (sort >= 5 && sort < 8) return COLOR.LIGHT_GREEN
  return COLOR.GREEN
}

const getFailed = (parcel, projects) => {
  const { propertyId, parcelNumber } = parcel
  const project = projects.find(p => p.propertyId === propertyId && p.parcelNumber === parcelNumber)
  return !!project?.status.failed
}

export const ParcelsMap = ({ parcelIds, setPropertyFilterValue, height = 450 }) => {
  const defaultBBoxRef = useRef()
  const hoverRef = useRef()

  const [parcels] = useParcels()
  const [projects] = useProjects()

  const geoJSON = useMemo(() => {
    if (!parcels || !parcelIds || !projects) return null

    const features = parcels
      .filter(p => parcelIds.includes(`${p.propertyId}:${p.parcelNumber}`))
      .map((p, index) => {
        const { propertyId, parcelNumber } = p
        const name = `${propertyId}:${parcelNumber}`
        const properties = { propertyId, name, color: getColor(p, projects), error: getFailed(p, projects) }
        return turf.feature(p.geometry, properties, { id: index + 1 })
      })

    const featureCollection = turf.featureCollection(features)
    if (features.length) featureCollection.bbox = turf.bbox(featureCollection)

    return featureCollection
  }, [parcels, projects, parcelIds])

  useEffect(() => {
    if (parcels && !defaultBBoxRef.current) {
      const features = parcels.map(p => turf.feature(p.geometry))
      const featureCollection = turf.featureCollection(features)
      defaultBBoxRef.current = turf.bbox(featureCollection)
    }
  }, [parcels, defaultBBoxRef])

  const pointsGeoJSON = useMemo(() => {
    if (!geoJSON) return null
    const points = geoJSON.features.map(feature => {
      const point = turf.pointOnFeature(feature)
      point.properties = feature.properties
      point.id = feature.id
      return point
    })
    return turf.featureCollection(points)
  }, [geoJSON])

  if (!geoJSON)
    return (
      <div style={{ height }}>
        <Spinner />
      </div>
    )

  return (
    <div style={{ height }}>
      <MapGL bounds={geoJSON.bbox}>
        <RasterSource url="https://tile.openstreetmap.org/{z}/{x}/{y}.png">
          <RasterLayer />
        </RasterSource>
        <GeoJSONSource data={geoJSON} tolerance={0.05} dynamicData>
          <FillLayer
            fillColor={['case', ['get', 'error'], 'red', 'transparent']}
            fillOpacity={['step', ['zoom'], 0, pointMaxZoom, 0.5]}
            onMouseMove={event => {
              if (event.target.getZoom() > pointMaxZoom) event.target.getCanvas().style.cursor = 'pointer'
            }}
            onMouseLeave={event => {
              if (event.target.getZoom() > pointMaxZoom) event.target.getCanvas().style.cursor = ''
            }}
            onClick={event => {
              const { features } = event
              if (features.length > 0) {
                const { propertyId } = features[0].properties
                setPropertyFilterValue?.(propertyId)
              }
            }}
            zIndex={10}
          />
          <PropertyLineLayer
            lineColor={['get', 'color']}
            lineWidth={7}
            lineOpacity={['step', ['zoom'], 0, pointMaxZoom, 1]}
            zIndex={20}
          />
        </GeoJSONSource>
        <GeoJSONSource data={pointsGeoJSON} dynamicData>
          <CircleLayer
            circleColor={['get', 'color']}
            circleRadius={['interpolate', ['linear'], ['zoom'], 0, 5, 12, 10]}
            circleOpacity={['step', ['zoom'], 1, pointMaxZoom, 0]}
            circleStrokeColor={['case', ['get', 'error'], 'red', 'transparent']}
            circleStrokeWidth={1}
            circleStrokeOpacity={['step', ['zoom'], 1, pointMaxZoom, 0]}
            onMouseMove={(event, sourceId) => {
              const map = event.target
              if (hoverRef.current) {
                map.setFeatureState({ source: sourceId, id: hoverRef.current }, { hover: false })
              }
              hoverRef.current = event.features[0].id
              map.setFeatureState({ source: sourceId, id: hoverRef.current }, { hover: true })
              if (map.getZoom() <= pointMaxZoom) map.getCanvas().style.cursor = 'pointer'
            }}
            onMouseLeave={(event, sourceId) => {
              const map = event.target
              if (hoverRef.current) {
                map.setFeatureState({ source: sourceId, id: hoverRef.current }, { hover: false })
              }
              hoverRef.current = null
              if (map.getZoom() <= pointMaxZoom) map.getCanvas().style.cursor = ''
            }}
            onClick={event => {
              const { target: map, features } = event
              if (map.getZoom() <= pointMaxZoom && features.length > 0) {
                const { propertyId } = features[0].properties
                setPropertyFilterValue?.(propertyId)
              }
            }}
            zIndex={30}
          />
          <CircleLayer
            circleColor={['case', ['boolean', ['feature-state', 'hover'], false], '#000', 'transparent']}
            circleRadius={['interpolate', ['linear'], ['zoom'], 0, 5, 12, 10]}
            circleOpacity={['step', ['zoom'], 0.3, pointMaxZoom, 0]}
            zIndex={35}
          />
        </GeoJSONSource>
        <GeoJSONLabelOverlay key={uid(geoJSON)} geoJSON={geoJSON} minZoom={12} />
        <ScaleControl />
        <NavigationControl />
        <CtrlScrollZoomControl />
        <BoundsController bounds={geoJSON.bbox || defaultBBoxRef.current} />
      </MapGL>
    </div>
  )
}
