import React from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes, Navigate, useParams } from 'react-router-dom'
import { Tabs, Tab, Dialog, DialogClose, DialogHeader } from '@project/components'
import { EditProperties } from './partials/EditProperties'
import { EditPlans } from './partials/EditPlans'
import style from './EditPopup.module.scss'

export const EditPopup = ({ onClose }) => {
  const { t } = useTranslation()
  const { email } = useParams()

  return (
    <Dialog className={style['dialog']}>
      <DialogClose onClose={onClose} />
      <Tabs secondary>
        <Tab to="properties" label={t('common.properties')} />
        <Tab to="plans" label={t('common.plans')} />
      </Tabs>
      <DialogHeader>{email}</DialogHeader>

      <Routes>
        <Route path="properties" element={<EditProperties onClose={onClose} />} />
        <Route path="plans" element={<EditPlans onClose={onClose} />} />
        <Route path="*" element={<Navigate to="properties" replace />} />
      </Routes>
    </Dialog>
  )
}
