import { useEffect } from 'react'
import mapboxgl from 'mapbox-gl'
import { useMap } from '../../map/MapGL'
import style from './ScaleControl.module.scss'

/* eslint-disable no-underscore-dangle */
export const ScaleControl = () => {
  const map = useMap()

  useEffect(() => {
    if (!map) return () => {}

    const control = new mapboxgl.ScaleControl({ maxWidth: 80, unit: 'metric' })
    map.addControl(control, 'bottom-right')
    control._container.classList.add(style['scale-control'])

    return () => {
      map.removeControl(control)
    }
  }, [map])

  return null
}
