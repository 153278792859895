import React from 'react'
import { useParams } from 'react-router'
import { TilesSubstrate as ToolkitTilesSubstrate, LAYERS } from '@project/mapbox'
import { useProject, useParcel } from 'api'

export const TilesSubstrate = ({ defaultLayer = LAYERS.PREFOR_CHM, enableControl = true }) => {
  const { projectId } = useParams()
  const [project] = useProject(projectId)
  const { propertyId, parcelNumber } = project || {}
  const [parcel] = useParcel(propertyId, parcelNumber)
  const path = `/projects/${projectId}/tiles`
  const chmTilePath = `${path}/canopyHeightModel/{z}/{x}/{y}`
  const aerialTilePath = `${path}/aerialPhoto/{z}/{x}/{y}`

  if (!parcel) return null

  return (
    <ToolkitTilesSubstrate
      defaultLayer={defaultLayer}
      chmTilePath={chmTilePath}
      aerialTilePath={aerialTilePath}
      geoJSON={parcel.geometry}
      enableControl={enableControl}
    />
  )
}
