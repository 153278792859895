export default {
  tileLayer: {
    baseMap: 'Base map',
    aerialMap: 'NLS aerial map',
    preforAerialMap: 'PreFor aerial map',
    preforCHMMap: 'PreFor CHM',
  },
  control: {
    showBaseMap: 'Show base map',
  },
}
