import React, { useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useAsyncFn } from 'react-use'
import * as turf from '@turf/turf'
import { FillLayer, GeohashGridLayer, GeoJSONSource, SpinnerOverlay } from '@project/mapbox'
import { useQueryParams } from '@project/routing'
import { useProject, usePointcloudGeohashesGeoJSON, useTreeCorrectionTiles } from 'api'
import { updateTreeCorrectionTile } from 'api/requests'
import { MODES } from '../../../../../../utils/modes'
import { skipGeohash, setGeohashesToBeSkipped } from '../../../../../../redux/ducks/data'
import { TILE_STATUSES } from '../../../../../../utils/tileStatuses'

export const SkippedGeohashesControl = ({ zIndex }) => {
  const { projectId } = useParams()
  const [queryParams] = useQueryParams()
  const [project] = useProject(projectId)
  const [geohashesGeoJSON] = usePointcloudGeohashesGeoJSON(projectId)
  const [treeCorrectionTiles, , , , refreshTreeCorrectionTiles] = useTreeCorrectionTiles(projectId)
  const mode = useSelector(state => state.editor.mode)
  const geohashesToBeSkipped = useSelector(state => state.data.geohashesToBeSkipped)
  const dispatch = useDispatch()

  const skippedGeohashes = useMemo(() => {
    if (!treeCorrectionTiles) return null
    return treeCorrectionTiles.filter(item => item.status === TILE_STATUSES.SKIP).map(item => item.geohash)
  }, [treeCorrectionTiles])

  const processedGeohashes = useMemo(() => {
    if (!treeCorrectionTiles) return null
    return treeCorrectionTiles.filter(item => item.status === TILE_STATUSES.DONE).map(item => item.geohash)
  }, [treeCorrectionTiles])

  const [{ loading }, save] = useAsyncFn(async () => {
    try {
      const { SKIP, VOID } = TILE_STATUSES
      const promises = geohashesToBeSkipped.map(geohash => {
        const status = skippedGeohashes.includes(geohash) || processedGeohashes.includes(geohash) ? VOID : SKIP
        return updateTreeCorrectionTile(project.jobId, geohash, status)
      })
      await Promise.all(promises)
      await new Promise(resolve => setTimeout(resolve, 1000))
      dispatch(setGeohashesToBeSkipped([]))
      await refreshTreeCorrectionTiles(true, true)
      await new Promise(resolve => setTimeout(resolve, 1000))
    } catch (error) {
      // do nothing
    }
  }, [
    project,
    skippedGeohashes,
    processedGeohashes,
    geohashesToBeSkipped,
    updateTreeCorrectionTile,
    refreshTreeCorrectionTiles,
  ])

  const toBeSkippedGeoJSON = useMemo(() => {
    if (!skippedGeohashes || !geohashesGeoJSON || mode !== MODES.GEOHASH) return null

    const features = geohashesGeoJSON.features
      .filter(f => geohashesToBeSkipped.includes(f.properties.geohash))
      .filter(f => !skippedGeohashes.includes(f.properties.geohash))
      .filter(f => !processedGeohashes.includes(f.properties.geohash))

    return turf.featureCollection(features)
  }, [skippedGeohashes, processedGeohashes, geohashesGeoJSON, geohashesToBeSkipped, mode])

  useEffect(() => {
    if (geohashesToBeSkipped.length && mode !== MODES.GEOHASH) save()
  }, [geohashesToBeSkipped, mode, save])

  if (loading) return <SpinnerOverlay />

  if (!geohashesGeoJSON || !toBeSkippedGeoJSON) return null

  return (
    <>
      <GeoJSONSource data={geohashesGeoJSON} dynamicData>
        <GeohashGridLayer
          onClick={event => {
            const { features } = event
            if (features.length === 0) return
            if (features[0].properties.geohash === queryParams.geohash) return
            dispatch(skipGeohash(features[0].properties.geohash))
          }}
          selectedGeohash={queryParams.geohash}
          selectedGeohashColor="purple"
          hoveredGeohashColor="#777"
          zIndex={zIndex}
        />
      </GeoJSONSource>
      <GeoJSONSource data={toBeSkippedGeoJSON} dynamicData>
        <FillLayer fillColor="#000" fillOpacity={0.75} zIndex={zIndex + 0.1} />
      </GeoJSONSource>
    </>
  )
}
