import React from 'react'
import { useParams } from 'react-router-dom'
import { Spinner } from '@project/components'
import { useEvaluationImages } from 'api'

export const Evaluation = () => {
  const { projectId } = useParams()
  const [images] = useEvaluationImages(projectId)

  if (!images) return <Spinner />

  return images.map((job, index) => (
    <div key={index}>
      <div style={{ fontWeight: 'bold', marginBottom: '20px' }}>Algorithm pipeline job: {job.jobId}</div>
      <div>
        {job.urls.map((k, i) => (
          <div key={i} style={{ width: '600px', maxWidth: '80%', marginBottom: '20px' }}>
            <img src={k} alt={i} style={{ width: '100%' }} />
          </div>
        ))}
      </div>
    </div>
  ))
}
