import React, { useLayoutEffect, useState } from 'react'
import classnames from 'classnames'
import { useWindowSize } from 'react-use'
import { Spinner } from '@project/components'
import style from './MapView.module.scss'

export const MapView = ({ className, fullscreenClassName, map, aside, loading }) => {
  const { width } = useWindowSize()
  const isMobile = width <= 800
  const [fullscreen, setFullscreen] = useState(false)
  const [showFullscreenAside, setShowFullscreenAside] = useState(false)
  const [showAside, setShowAside] = useState(!isMobile)
  const [allowNavigation, setAllowNavigation] = useState(!isMobile)

  useLayoutEffect(() => {
    const event = document.createEvent('HTMLEvents')
    event.initEvent('resize', true, false)
    document.dispatchEvent(event)
  }, [fullscreen, showFullscreenAside, showAside])

  if (loading)
    return (
      <div className={style['map-view__spinner']}>
        <Spinner />
      </div>
    )

  return (
    <div
      className={classnames({
        [style['map-view']]: true,
        [className]: className,
        [style['map-view--fullscreen']]: fullscreen,
        [fullscreenClassName]: fullscreen && fullscreenClassName,
      })}
    >
      <div className={style['map-view__map']}>
        {map}
        {isMobile && !allowNavigation && <div className={style['map-view__navigation-lock']} />}
        <div className={style['map-view__fullscreen-toggle']} onClick={() => setFullscreen(!fullscreen)}>
          <i className={classnames('fa', fullscreen ? 'fa-window-minimize' : 'fa-expand-arrows-alt')} />
        </div>
        {!fullscreen && (
          <div className={style['map-view__legend-toggle']} onClick={() => setShowAside(!showAside)}>
            <i className={classnames('fa', 'fa-info-circle')} />
          </div>
        )}
        {isMobile && (
          <div className={style['map-view__navigation-toggle']} onClick={() => setAllowNavigation(!allowNavigation)}>
            <i className={classnames('fa', allowNavigation ? 'fa-lock-open' : 'fa-lock')} />
          </div>
        )}
      </div>
      <div className={style['map-view__aside']}>
        {fullscreen && (
          <div className={style['map-view__aside-toggle']} onClick={() => setShowFullscreenAside(!showFullscreenAside)}>
            <span className={style['map-view__info-button']}>
              <i className="fa fa-info" />
            </span>
            {showFullscreenAside ? <i className="fa fa-angle-right" /> : <i className="fa fa-angle-left" />}
          </div>
        )}
        {((!fullscreen && showAside) || (fullscreen && showFullscreenAside)) && (
          <div
            className={classnames(style['map-view__aside-content'], {
              [style['map-view__aside-content--fullscreen']]: fullscreen,
            })}
          >
            {aside}
          </div>
        )}
      </div>
    </div>
  )
}
