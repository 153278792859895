import React from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useKeyPressEvent, useAsyncFn } from 'react-use'
import gh from 'latlon-geohash'
import { SpinnerOverlay } from '@project/mapbox'
import { pushNotification } from '@project/notifications'
import { createOmission } from 'api/requests'
import { useProject, useTreeCorrections } from 'api'
import { reset } from '../../../../../../../../redux/ducks/tree'

export const SaveTreeShortcuts = () => {
  const { projectId } = useParams()
  const [project] = useProject(projectId)
  const refreshTreeCorrections = useTreeCorrections(projectId)[4]

  const dispatch = useDispatch()
  const { coordinate, crownRadius, species, autoHeight, bottomLevel, topLevel } = useSelector(state => state.tree)
  const height = autoHeight || Math.abs(topLevel - bottomLevel)

  const [{ loading }, save] = useAsyncFn(async () => {
    try {
      if (!project || !coordinate || !height || !crownRadius || species === null) return

      const [lng, lat] = coordinate
      const geohash = gh.encode(lat, lng, 12)
      await createOmission(project.jobId, geohash, species, height, crownRadius * 2)
      await new Promise(resolve => setTimeout(resolve, 500))
      await refreshTreeCorrections(true, true)
      dispatch(reset())
    } catch (error) {
      // do nothing
    }
  }, [project, coordinate, crownRadius, species, height, refreshTreeCorrections])

  useKeyPressEvent('s', () => {
    if (loading || !coordinate) return
    if (crownRadius && height && species !== null) {
      save()
    } else {
      pushNotification({
        message: 'Failed to save tree, specify all tree parameters first.',
        type: 'error',
        timeout: 2500,
      })
    }
  })

  if (loading) return <SpinnerOverlay />
  return null
}
