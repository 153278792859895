export const defaultColorSchemes = [
  { id: 'legend-default-1', hex: '#dadada' },
  { id: 'legend-default-2', hex: '#643c3c' },
]

export const ontologyColorSchemes = [
  { id: 'legend-ont-1', hex: '#667f5a' },
  { id: 'legend-ont-2', hex: '#beebd2' },
  { id: 'legend-ont-3', hex: '#f0a53c' },
  { id: 'legend-ont-4', hex: '#f7e7aa' },
  { id: 'legend-ont-5', hex: '#be6464' },
  { id: 'legend-ont-6', hex: '#ffcabd' },
  { id: 'legend-ont-7', hex: '#643c3c' },
  { id: 'legend-ont-8', hex: '#296f80' },
  { id: 'legend-ont-9', hex: '#ca2c47' },
  { id: 'legend-ont-10', hex: '#ff8b71' },
  { id: 'legend-ont-11', hex: '#f9bfa4' },
  { id: 'legend-ont-12', hex: '#7dbca9' },
  { id: 'legend-ont-13', hex: '#bbe7da' },
  { id: 'legend-ont-14', hex: '#cba049' },
  { id: 'legend-ont-15', hex: '#ffd37c' },
  { id: 'legend-ont-16', hex: '#bed061' },
  { id: 'legend-ont-17', hex: '#eafd88' },
  { id: 'legend-ont-18', hex: '#8263b1' },
  { id: 'legend-ont-19', hex: '#c75e0c' },
  { id: 'legend-ont-20', hex: '#ee9dcc' },
  { id: 'legend-ont-21', hex: '#6296bc' },
  { id: 'legend-ont-22', hex: '#6f001e' },
  { id: 'legend-ont-23', hex: '#e06c4e' },
  { id: 'legend-ont-24', hex: '#98a683' },
  { id: 'legend-ont-25', hex: '#ef629e' },
  { id: 'legend-ont-26', hex: '#541743' },
  { id: 'legend-ont-27', hex: '#2e446c' },
  { id: 'legend-ont-28', hex: '#609c8b' },
  { id: 'legend-ont-29', hex: '#cb8b31' },
  { id: 'legend-ont-30', hex: '#afdbf2' },
]

export const heatmapColorSchemes = [
  { id: 'legend-heat-1', hex: '#fff7ec', rgb: [255, 247, 236] },
  { id: 'legend-heat-2', hex: '#fee8c8', rgb: [254, 232, 200] },
  { id: 'legend-heat-3', hex: '#fdd49e', rgb: [253, 212, 158] },
  { id: 'legend-heat-4', hex: '#fdbb84', rgb: [253, 187, 132] },
  { id: 'legend-heat-5', hex: '#fc8d59', rgb: [252, 141, 89] },
  { id: 'legend-heat-6', hex: '#ef6548', rgb: [239, 101, 72] },
  { id: 'legend-heat-7', hex: '#d7301f', rgb: [215, 48, 31] },
  { id: 'legend-heat-8', hex: '#b30000', rgb: [179, 0, 0] },
  { id: 'legend-heat-9', hex: '#7f0000', rgb: [127, 0, 0] },
]

export const grayscaleColorScheme = [
  { id: 'legend-gray-1', hex: '#eee' },
  { id: 'legend-gray-2', hex: '#ddd' },
  { id: 'legend-gray-3', hex: '#ccc' },
  { id: 'legend-gray-4', hex: '#bbb' },
  { id: 'legend-gray-5', hex: '#aaa' },
  { id: 'legend-gray-6', hex: '#999' },
  { id: 'legend-gray-7', hex: '#888' },
  { id: 'legend-gray-8', hex: '#777' },
  { id: 'legend-gray-9', hex: '#666' },
  { id: 'legend-gray-10', hex: '#555' },
  { id: 'legend-gray-11', hex: '#444' },
  { id: 'legend-gray-12', hex: '#333' },
  { id: 'legend-gray-13', hex: '#222' },
]

export const actionColorScheme = [
  { id: 'legend-action-1', hex: '#fef495' },
  { id: 'legend-action-2', hex: '#ffe05d' },
  { id: 'legend-action-3', hex: '#ffbc03' },
  { id: 'legend-action-4', hex: '#ff9801' },
  { id: 'legend-action-5', hex: '#7bd152' },
  { id: 'legend-action-6', hex: '#45be71' },
  { id: 'legend-action-7', hex: '#056c36' },
  { id: 'legend-action-8', hex: '#956f00' },
  { id: 'legend-action-9', hex: '#725601' },
  { id: 'legend-action-10', hex: '#4a3800' },
  { id: 'legend-action-11', hex: '#0078d3' },
  { id: 'legend-action-12', hex: '#2a7fc0' },
  { id: 'legend-action-13', hex: '#10588f' },
]

export const colorSchemes = [
  ...defaultColorSchemes,
  ...ontologyColorSchemes,
  ...heatmapColorSchemes,
  ...grayscaleColorScheme,
  ...actionColorScheme,
]
